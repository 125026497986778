import { useCallback, useEffect, useState } from 'react';
import { ArrayElement, runParallelTasksOnQueue } from '@magicbrief/common';
import { UserAssetEntityConnection } from '@magicbrief/server/src/api/models/shapes/trpcshapes';
import { UserAssetResponse } from '@magicbrief/server/src/storage/storage.trpc';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { captureException } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { inferProcedureOutput } from '@trpc/server';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGlobalDispatchContext } from 'src/contexts/GlobalContext/useGlobalContext';
import { segment } from 'src/lib/segment';
import { trpc, trpcProxyClient } from 'src/lib/trpc';

import { useUserAndOrganisation } from './useUserAndOrganisation';

type FileAlias = {
  id: string;
  fileName: string;
  mimeType: string;
};

export type SingleUploadState =
  | { status: 'queued'; file: FileAlias }
  | {
      file: FileAlias;
      status: 'uploading';
      progress: number;
      abort: () => void;
    }
  | {
      file: FileAlias;
      status: 'done';
      asset: UserAssetResponse;
    }
  | {
      file: FileAlias;
      status: 'failed';
      error: string;
    }
  | { status: 'cancelled'; file: FileAlias };

type State = 'idle' | 'uploadsInProgress' | 'allDone';

type Dimensions = {
  width: number;
  height: number;
};

export type UseUploadUserAssetOptions = {
  /**
   * Default: true. The user asset will be visible in the user's clip/asset library.
   * Set to false when this is unwanted, for example, a user's avatar.
   * */
  showInAssetLibrary?: boolean;
  associateTo?: UserAssetEntityConnection[];
};

export async function determineMediaFileDimensions(
  file: File
): Promise<Dimensions | undefined> {
  const objectURL = URL.createObjectURL(file);
  try {
    if (file.type.includes('image')) {
      const image = new Image();
      image.src = objectURL;
      await image.decode();
      return { height: image.height, width: image.width };
    } else if (file.type.includes('video')) {
      const videoEl = document.createElement('video');

      const raceAbortController = new AbortController();

      const dimensions = await Promise.race([
        new Promise<Dimensions | undefined>((resolve) => {
          const listener = () => {
            if (videoEl.videoWidth && videoEl.videoHeight) {
              resolve({
                width: videoEl.videoWidth,
                height: videoEl.videoHeight,
              });
            }
            resolve(undefined);
          };
          raceAbortController.signal.addEventListener('abort', () => {
            videoEl.removeEventListener('loadedmetadata', listener);
          });
          videoEl.addEventListener('loadedmetadata', listener);
          videoEl.src = objectURL;
        }),
        new Promise<never>((_, reject) => {
          if (!raceAbortController.signal.aborted) {
            const timeout = setTimeout(
              () => reject('Timed out determining dimensions for video'),
              3000
            );
            raceAbortController.signal.addEventListener('abort', () => {
              clearTimeout(timeout);
            });
          }
        }),
      ]);

      raceAbortController.abort();

      return dimensions;
    }
  } catch (e) {
    return undefined;
  } finally {
    URL.revokeObjectURL(objectURL);
  }
}

async function uploadFile(
  file: File,
  onAssetUpdate: (state: SingleUploadState) => void,
  userUUID: string,
  organisationUUID: string,
  options?: UseUploadUserAssetOptions
) {
  const abortController = new AbortController();

  try {
    const fileExt = file.name.split('.').pop();
    if (!fileExt) {
      onAssetUpdate({
        file: {
          fileName: file.name,
          mimeType: file.type,
          id: `${file.webkitRelativePath}/${file.name}`,
        },
        status: 'failed',
        error: 'File is a missing a file extension.',
      });
      throw new Error('File is missing a file extension.');
    }

    const dimensions = await determineMediaFileDimensions(file);

    const temporaryAsset =
      await trpcProxyClient.storage.allocateNewUserAsset.mutate({
        fileName: file.name,
        fileExt,
        showInAssetLibrary: options?.showInAssetLibrary ?? true,
        mimeType: file.type,
        metadata: {
          ...dimensions,
          size: file.size,
        },
      });

    const abort = () => abortController.abort();

    onAssetUpdate({
      file: {
        fileName: file.name,
        mimeType: file.type,
        id: `${file.webkitRelativePath}/${file.name}`,
      },
      status: 'uploading',
      progress: 0,
      abort,
    });

    const uploadToCloudflare = async () => {
      await axios.put(temporaryAsset.url, file, {
        headers: {
          'Content-Type': file.type,
          'Content-Length': file.size,
        },
        onUploadProgress: (progressEvent) => {
          const percentProgress = Math.ceil(
            (progressEvent.loaded / file.size) * 100
          );
          onAssetUpdate({
            file: {
              fileName: file.name,
              mimeType: file.type,
              id: `${file.webkitRelativePath}/${file.name}`,
            },
            status: 'uploading',
            progress: percentProgress,
            abort,
          });
        },
      });

      const asset = await trpcProxyClient.storage.updateUserAssetStatus.mutate({
        uuid: temporaryAsset.uuid,
        associateTo: options?.associateTo,
      });

      onAssetUpdate({
        file: {
          fileName: file.name,
          mimeType: file.type,
          id: `${file.webkitRelativePath}/${file.name}`,
        },
        status: 'done',
        asset: {
          ...asset,
          url: `${temporaryAsset.url}`,
        },
      });

      return asset;
    };

    return uploadToCloudflare();
  } catch (e) {
    if (abortController.signal.aborted) {
      onAssetUpdate({
        file: {
          fileName: file.name,
          mimeType: file.type,
          id: `${file.webkitRelativePath}/${file.name}`,
        },

        status: 'cancelled',
      });
    } else {
      onAssetUpdate({
        file: {
          fileName: file.name,
          mimeType: file.type,
          id: `${file.webkitRelativePath}/${file.name}`,
        },
        status: 'failed',
        error: e instanceof Error ? e.message : 'Unknown error.',
      });
    }
    throw e;
  }
}

export function useUploadUserAsset(options?: UseUploadUserAssetOptions) {
  const [state, setState] = useState<State>('idle');
  const [uploads, setUploads] = useState<SingleUploadState[]>([]);
  const user = useUserAndOrganisation();
  const queryClient = useQueryClient();

  const uploadRunner = async (file?: File) => {
    if (!file) {
      throw new Error('Tried to upload an undefined file.');
    }

    const result = await uploadFile(
      file,
      (uploadState) =>
        setUploads((s) => {
          const idx = s.findIndex(
            (x) => x.file.id === `${file.webkitRelativePath}/${file.name}`
          );
          return idx !== -1
            ? [...s.slice(0, idx), uploadState, ...s.slice(idx + 1)]
            : s;
        }),
      user.data?.user.uuid ?? '',
      user.data?.organisation.uuid ?? '',
      options
    );

    queryClient.setQueryData<
      inferProcedureOutput<AppRouter['storage']['getUserAssets']>
    >(getQueryKey(trpc.storage.getUserAssets, undefined, 'infinite'), (old) => {
      return old ? [result, ...old] : old;
    });
    return result;
  };

  const uploadFiles = async (files: File[]) => {
    setState('uploadsInProgress');
    setUploads(
      files.map((curr) => {
        const fileExt = curr.name.split('.').pop();
        if (!fileExt) {
          toast.error('File is a missing a file extension.');
          return {
            file: {
              fileName: curr.name,
              mimeType: curr.type,
              id: `${curr.webkitRelativePath}/${curr.name}`,
            },
            status: 'failed',
            error: 'File is a missing a file extension.',
          };
        }
        return {
          file: {
            fileName: curr.name,
            mimeType: curr.type,
            id: `${curr.webkitRelativePath}/${curr.name}`,
          },
          status: 'queued',
          progress: 0,
          estimatedSecondsRemaining: null,
          lastUpdate: new Date().valueOf(),
        };
      })
    );

    const results = await runParallelTasksOnQueue(files, uploadRunner, 3); // This shouldn't throw, so no need to catch
    setState('allDone');

    void queryClient.invalidateQueries(
      getQueryKey(trpc.storage.getUserAssets, undefined, 'query')
    );

    return results;
  };

  const reset = () => {
    if (state !== 'uploadsInProgress') {
      setState('idle');
      setUploads([]);
    }
  };

  return { state, uploadFiles, uploads, setUploads, reset };
}

export type UploadResult = ArrayElement<
  Awaited<ReturnType<ReturnType<typeof useUploadUserAsset>['uploadFiles']>>
>;

export function useUploadUserAssetBackground() {
  const { uploadFiles, uploads, reset } = useUploadUserAsset();
  const globalDispatchContext = useGlobalDispatchContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const warnUserBeforeLeave = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  }, []);

  const { getRootProps, isDragActive, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles: File[]) => {
      /* In case user is in asset collection or brief assets when dropping */
      navigate('/assets', { replace: true });

      try {
        window.addEventListener('beforeunload', warnUserBeforeLeave);
        await uploadFiles(acceptedFiles);
      } catch (e) {
        captureException(e, (scope) => {
          scope.setTransactionName('useUploadUserAsset->uploadFiles');
          return scope;
        });
      } finally {
        window.removeEventListener('beforeunload', warnUserBeforeLeave);
        void afterUploadFiles();
      }
    },
  });

  const afterUploadFiles = async () => {
    await queryClient.invalidateQueries(
      getQueryKey(trpc.storage.getUserAssets, undefined, 'infinite')
    );
    void segment?.track('uploaded_assets');

    globalDispatchContext({
      type: 'setAssetsUploads',
      payload: [],
    });
    reset();
  };

  useEffect(() => {
    if (uploads.length > 0) {
      globalDispatchContext({
        type: 'setAssetsUploads',
        payload: uploads,
      });
    }
  }, [uploads, globalDispatchContext]);

  return { getRootProps, getInputProps, isDragActive };
}
