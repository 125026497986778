import {
  Button,
  Dialog,
  Heading,
  Modal,
  ModalOverlay,
} from 'react-aria-components';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import { AriaButton } from 'src/components/Button/Button';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import Input from 'src/components/Input';
import { Alert } from 'src/components/Alert';
import AlertOctagon from 'src/assets/svgicons/duocolor/alert-octagon.svg';
import { useCreateDirectory } from 'src/utils/useDirectories';

type Props = {
  parentDirectoryUuid: string | null;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

const validator = z.object({
  name: z.string().min(1, { message: 'A name is required.' }),
});

type FormData = z.infer<typeof validator>;

export const NewDirectoryModal: React.FC<Props> = ({
  isOpen,
  parentDirectoryUuid,
  onOpenChange,
}): JSX.Element => {
  return (
    <ModalOverlay
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className="fixed inset-0 z-500 flex min-h-full items-center justify-center overflow-y-auto bg-gray-900/60 p-4 text-center"
    >
      <Modal className="w-full max-w-md overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl">
        <Dialog className="relative outline-none">
          {({ close }) => (
            <>
              {isOpen && (
                <NewDirectoryModalBody
                  close={close}
                  parentDirectoryUuid={parentDirectoryUuid}
                />
              )}
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

const NewDirectoryModalBody: React.FC<
  Pick<Props, 'parentDirectoryUuid'> & { close: () => void }
> = ({ close, parentDirectoryUuid }) => {
  const { LL } = useI18nContext();

  const { register, formState, handleSubmit, setError } = useForm<FormData>({
    resolver: zodResolver(validator),
  });

  const mutate = useCreateDirectory();

  const create = handleSubmit(async ({ name }) => {
    try {
      await mutate.mutateAsync({
        name,
        targetDirectoryNodeUuid: parentDirectoryUuid ?? undefined,
      });
      close();
      toast('Board created', { className: 'toast-success' });
    } catch (e) {
      setError('root', {
        message: LL.errors.genericWithDetail({
          detail: e instanceof Error ? e.message : 'Unknown error',
        }),
      });
    }
  });

  return (
    <form className="flex flex-auto flex-col gap-4" onSubmit={create}>
      <div className="flex flex-auto flex-row items-center justify-between gap-4 text-purple-800">
        <Heading className="truncate text-xl font-bold">New Board</Heading>
        <Button onPress={close}>
          <Icon>
            <XClose />
          </Icon>
        </Button>
      </div>

      {formState.errors.root && (
        <Alert
          icon={
            <Icon>
              <AlertOctagon />
            </Icon>
          }
          className="w-full"
          type="error"
        >
          <p className="text-xs font-normal">{formState.errors.root.message}</p>
        </Alert>
      )}

      <Input
        className="w-full flex-auto"
        label="Name"
        {...register('name', {
          required: {
            value: true,
            message: LL.errors.fieldRequired(),
          },
        })}
        error={formState.errors.name?.message}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />

      <AriaButton loading={mutate.isLoading} htmlType="submit">
        Create
      </AriaButton>
    </form>
  );
};
