import { HTMLAttributes, ReactNode } from 'react';
import {
  ACADEMY_INSIGHTS_WIZARD_SCORES_LINK,
  INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_REFERENCE,
  InsightsFacebookWizardMetric,
} from '@magicbrief/common';
import classNames from 'classnames';
import { Button, Meter } from 'react-aria-components';
import { Link } from 'react-router-dom';
import InfoCircle from 'src/assets/svgicons/duocolor/info-circle.svg';
import { Icon } from 'src/components/Icon';
import { Tooltip } from 'src/components/Tooltip/Tooltip';
import MagicBriefBolt from 'src/assets/svgicons/magicbrief/MagicBriefBolt.svg';
import { cn } from 'src/lib/cn';
import { lerpColor } from 'src/utils/interpolateColour';
import {
  INSIGHTS_WIZARD_TACOMETER_GREEN_COLOURS,
  INSIGHTS_WIZARD_TACOMETER_RED_COLOURS,
  INSIGHTS_WIZARD_TACOMETER_YELLOW_COLOURS,
} from '../../../const';

export const WizardScoreMeter = ({
  score,
  withBackground = true,
}: {
  score: number;
  withBackground?: boolean;
}) => {
  const wizardScore = Math.round(score);

  const getProgressBarColour = () => {
    switch (true) {
      case score < 50:
        return 'bg-red-500';
      case score >= 50 && score < 70:
        return 'bg-yellow-500';
      case score >= 70:
        return 'bg-[#33A970]';
      default:
        return;
    }
  };

  return (
    <Button
      className={cn(
        'flex items-center gap-3 rounded-[4px] px-2 py-1.5',
        withBackground ? 'bg-[#F8F6FF]' : 'bg-transparent'
      )}
    >
      <Meter value={wizardScore} aria-label="wizard score">
        {({ percentage }) => (
          <div className="flex items-center gap-3">
            <div className="h-1.5 w-16 rounded-full bg-[#E0DAF5]">
              <div
                className={classNames(
                  'h-full rounded-full',
                  getProgressBarColour()
                )}
                style={{ width: percentage + '%' }}
              />
            </div>
            <span className="w-[3ch] text-right text-xs font-semibold text-gray-800">
              {wizardScore === 0 ? '-' : wizardScore}
            </span>
          </div>
        )}
      </Meter>
    </Button>
  );
};

interface Props {
  score: number;
  type: InsightsFacebookWizardMetric;
  children?: ReactNode;
}

export const WizardScoreTooltip = ({ score, type, children }: Props) => {
  if (!children) return null;

  return (
    <Tooltip delay={0}>
      <>{children}</>
      <WizardScoreTacometer score={score} type={type} />
    </Tooltip>
  );
};

export const WizardScoreTacometer = ({
  score,
  type,
}: {
  score: number;
  type: InsightsFacebookWizardMetric;
}) => {
  let colourRange;

  const wizardScore = Math.round(score);

  const countOfTotalBars = 50;
  const barIncrements = 180 / countOfTotalBars;
  const countOfColouredBars = Math.ceil(countOfTotalBars * (score / 100)) + 1; // +1 for the 0 deg line

  switch (true) {
    case wizardScore < 50:
      colourRange = INSIGHTS_WIZARD_TACOMETER_RED_COLOURS;
      break;
    case wizardScore >= 50 && wizardScore < 70:
      colourRange = INSIGHTS_WIZARD_TACOMETER_YELLOW_COLOURS;
      break;
    case wizardScore >= 70:
      colourRange = INSIGHTS_WIZARD_TACOMETER_GREEN_COLOURS;
      break;
    default:
      break;
  }

  const label = INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_REFERENCE[type]?.label;
  const description =
    INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_REFERENCE[type]?.definition;

  return (
    <div className="relative flex w-52 flex-col gap-3 pt-2">
      <WizardScoreInfoLink className="absolute right-0 top-0" />
      <div className="relative flex h-[100px] w-full flex-col items-center justify-end rounded-md">
        {Array.from({ length: countOfTotalBars + 1 }).map((_, index) => {
          return (
            <i
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="absolute bottom-1 h-full w-0.5 origin-bottom"
              style={{
                transform: `rotate(${270 + index * barIncrements}deg)`,
                backgroundColor:
                  index + 1 < countOfColouredBars
                    ? lerpColor(colourRange[0], colourRange[1], index * 0.02)
                    : '#453466',
              }}
            />
          );
        })}
        <div className="absolute bottom-0 flex h-[90%] w-[85%] flex-col items-center justify-end gap-1 rounded-t-full bg-[#110636]">
          <span className="text-3xl font-semibold">
            {wizardScore === 0 ? '-' : wizardScore}
          </span>
          <div className="flex items-center gap-1">
            <Icon className="size-3.5 text-[#B4A2EF]">
              <MagicBriefBolt />
            </Icon>
            <span className="text-xs font-medium">{label}</span>
          </div>
        </div>
      </div>
      <span className="text-wrap rounded-[4px] bg-[#2B1B4A] p-2.5 text-xs text-white">
        {description}
      </span>
    </div>
  );
};

interface WizardScoreInfoLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  iconStyles?: string;
}

export function WizardScoreInfoLink({
  className,
  iconStyles,
}: WizardScoreInfoLinkProps) {
  return (
    <Link
      className={cn('z-[1] size-5 hover:cursor-pointer', className)}
      to={ACADEMY_INSIGHTS_WIZARD_SCORES_LINK}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon className={cn('size-5', iconStyles)}>
        <InfoCircle />
      </Icon>
    </Link>
  );
}
