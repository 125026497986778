import {
  getMetricFormat,
  INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_REFERENCE,
  INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE,
  InsightsFilterV2,
  isInsightsFacebookFilterDate,
  isInsightsFacebookFilterEnumerable,
  isInsightsFacebookFilterNumeric,
  isInsightsFacebookFilterText,
  parseInsightsFacebookActionMetric,
} from '@magicbrief/common';
import { GetFacebookAdAccountResponse } from '../../../types/insights';
import type { InsightsAdMetrics } from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';

export function getValueForMetric(metric: string, metrics: InsightsAdMetrics) {
  const value = metrics[metric];

  if (typeof value === 'object' && !(value instanceof Date)) {
    return null;
  }

  return metrics[metric];
}

export function parseValueForMetric(
  metric: string,
  value: string | number | Date,
  currency: GetFacebookAdAccountResponse['currency']
): string | null {
  const format = getMetricFormat(metric);
  const locale = window.navigator.languages
    ? window.navigator.languages[0]
    : window.navigator.language;

  if (metric === 'effectiveStatus') {
    return value === 'ACTIVE' ? 'Active' : 'Inactive';
  }

  switch (format) {
    case 'text':
      return `${value}`;
    case 'date':
      return Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format(typeof value === 'string' ? new Date(value) : value);
    case 'numeric':
      return typeof value === 'number'
        ? Intl.NumberFormat(locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }).format(value)
        : '';
    case 'currency':
      return typeof value === 'number'
        ? Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
          }).format(value)
        : '';
    case 'percentage':
      return typeof value === 'number'
        ? Intl.NumberFormat(locale, {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(value > 0 ? value / 100 : value)
        : '';
    default:
      return null;
  }
}

export function getMetricValue(
  metric: string,
  metrics: InsightsAdMetrics,
  currency: GetFacebookAdAccountResponse['currency']
) {
  const value = getValueForMetric(metric, metrics);

  if (value == null || Array.isArray(value)) {
    return null;
  }

  const parsedValue = parseValueForMetric(metric, value, currency);

  return parsedValue;
}

function computeNameForCustomEvent(event: string, customEvents: string[]) {
  return (
    customEvents
      .find((x) => x === event)
      ?.replace('app_custom_event.', '')
      .replace('offsite_conversion.fb_pixel_custom.', '') || null
  );
}

function computeNameForCustomConversion(
  event: string,
  customConversions: Array<{ facebookId: string; name: string }>
) {
  return (
    customConversions.find(
      (x) => `offsite_conversion.custom.${x.facebookId}` === event
    )?.name ?? null
  );
}

function computeActionMetricNameWithSubvalue(name: string, subvalue: string) {
  if (subvalue === 'cost_per') {
    return `Cost Per ${name}`;
  }

  if (subvalue === 'value') {
    return `${name} (Value)`;
  }

  return name;
}

export function getMetricSynonyms(metric: string) {
  return (
    INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE[
      metric as keyof typeof INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE
    ]?.synonyms ?? ''
  );
}

export function getMetricDefinitions(metric: string) {
  return INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE[
    metric as keyof typeof INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE
  ]?.definition;
}

export function getMetricLabelAsText(
  metric: string,
  customEvents: string[] | null | undefined,
  customConversions:
    | Array<{ facebookId: string; name: string }>
    | null
    | undefined
): string | null {
  if (metric.startsWith('action:')) {
    const builtinLabel =
      INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_REFERENCE[
        metric as keyof typeof INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_REFERENCE
      ]?.label ?? null;

    if (builtinLabel) return builtinLabel;

    const match = parseInsightsFacebookActionMetric(metric);
    if (!match) {
      return null;
    }

    const customConversionLabel = computeNameForCustomConversion(
      match.metric,
      customConversions ?? []
    );

    if (customConversionLabel)
      return computeActionMetricNameWithSubvalue(
        customConversionLabel,
        match.subvalue
      );

    const customEventLabel = computeNameForCustomEvent(
      match.metric,
      customEvents ?? []
    );

    if (customEventLabel)
      return computeActionMetricNameWithSubvalue(
        customEventLabel,
        match.subvalue
      );

    return null;
  }

  if (metric.startsWith('custom:')) {
    return metric.split('custom:')?.[1];
  }

  return (
    INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE[
      metric as keyof typeof INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE
    ]?.label ?? null
  );
}

function getMetricHeader(
  metric: string,
  customEvents: string[] | null | undefined,
  customConversions:
    | Array<{ facebookId: string; name: string }>
    | null
    | undefined
): string | null {
  if (metric.startsWith('action:')) {
    const builtinLabel =
      INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_REFERENCE[
        metric as keyof typeof INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_REFERENCE
      ]?.header ?? null;

    if (builtinLabel) return builtinLabel;

    const match = parseInsightsFacebookActionMetric(metric);
    if (!match) {
      return null;
    }

    if (customConversions) {
      const customConversionLabel = computeNameForCustomConversion(
        match.metric,
        customConversions
      );

      if (customConversionLabel)
        return computeActionMetricNameWithSubvalue(
          customConversionLabel,
          match.subvalue
        );
    }

    if (customEvents) {
      const customEventLabel = computeNameForCustomEvent(
        match.metric,
        customEvents
      );

      if (customEventLabel)
        return computeActionMetricNameWithSubvalue(
          customEventLabel,
          match.subvalue
        );
    }

    return null;
  }

  if (metric.startsWith('custom:')) {
    return metric.split('custom:')?.[1];
  }

  return (
    INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE[
      metric as keyof typeof INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE
    ]?.header ?? null
  );
}

export function useParseMetric() {
  return {
    getMetricLabelAsText,
    getMetricHeader,
    getMetricDefinitions,
    getMetricSynonyms,
  };
}

export function getMetricFilterValuesAsText(filter: InsightsFilterV2) {
  if ('field' in filter) {
    if (isInsightsFacebookFilterText(filter)) {
      if ('value' in filter) {
        return filter.value;
      }
      return '';
    }
    if (isInsightsFacebookFilterNumeric(filter)) {
      if ('value' in filter) {
        return filter.value;
      } else if (
        filter.operation === 'between' ||
        filter.operation === 'notBetween'
      ) {
        return `${filter.min} and ${filter.max}`;
      }
    }
    if (isInsightsFacebookFilterEnumerable(filter)) {
      return filter.values.reduce(
        (acc, value, index) =>
          `${acc}${
            index === 0
              ? `${value}`
              : index < filter.values.length - 1
              ? `, ${value}`
              : ` and ${value}`
          }`,
        ''
      );
    }
    if (isInsightsFacebookFilterDate(filter)) {
      return null;
    }
  }
  return null;
}
