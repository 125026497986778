export function getZIndexByClassName(className: string) {
  if (!className || /[^a-zA-Z0-9-_]/.test(className)) {
    return null;
  }

  const element = document.querySelector(`.${className}`);

  if (element) {
    const style = window.getComputedStyle(element);
    return style.zIndex;
  }

  return null;
}
