import { useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import { toast } from 'react-toastify';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import { localStorage } from 'src/utils/storage';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { useTypesafeSearchParams } from 'src/utils/useTypesafeSearchParams';

function addInviteCodeToLocalStorage(inviteCode: string) {
  const cachedCodes = localStorage.getItem('inviteCodes');
  let parsedCodes: string[] | null = null;

  try {
    parsedCodes = cachedCodes ? JSON.parse(cachedCodes) : null;
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('localStorage -> inviteCodes');
      return scope;
    });
    parsedCodes = null;
  }

  if (
    parsedCodes != null &&
    Array.isArray(parsedCodes) &&
    !parsedCodes.includes(inviteCode)
  ) {
    parsedCodes.push(inviteCode);
    localStorage.setItem('inviteCodes', JSON.stringify(parsedCodes));
  } else if (!parsedCodes) {
    parsedCodes = [inviteCode];
    localStorage.setItem('inviteCodes', JSON.stringify(parsedCodes));
  }

  return parsedCodes;
}

export function removeInviteCodeFromLocalStorage(
  code: string
): string[] | null {
  const cachedCodes = localStorage.getItem('inviteCodes');
  let parsedCodes: string[] | null = null;

  try {
    parsedCodes = cachedCodes ? JSON.parse(cachedCodes) : null;
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('localStorage -> inviteCodes');
      return scope;
    });
    parsedCodes = null;
  }

  if (parsedCodes != null && Array.isArray(parsedCodes)) {
    const idx = parsedCodes.findIndex((x) => x === code);
    const newCodes = [
      ...parsedCodes.slice(0, idx),
      ...parsedCodes.slice(idx + 1),
    ];

    if (newCodes.length) {
      localStorage.setItem('inviteCodes', JSON.stringify(newCodes));
      return newCodes;
    } else {
      localStorage.removeItem('inviteCodes');
      return null;
    }
  }

  return null;
}

export function getInviteCodesFromLocalStorage(): string[] | null {
  const cachedCodes = localStorage.getItem('inviteCodes');
  let parsedCodes: string[] | null = null;

  try {
    parsedCodes = cachedCodes ? JSON.parse(cachedCodes) : null;
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('localStorage -> inviteCodes');
      return scope;
    });
    parsedCodes = null;
  }

  return parsedCodes;
}

export const useOrganisationInvitation = () => {
  const { LL } = useI18nContext();
  const { recordEvent } = useNewAnalyticsEvent();

  const params = useTypesafeSearchParams<{ 'invite-code': string[] }>({
    'invite-code': [],
  });
  const inviteCodes = params.get('invite-code');

  const [cachedInviteCodes, setCachedInviteCodes] = useState<string[] | null>(
    getInviteCodesFromLocalStorage()
  );

  useEffect(() => {
    if (
      inviteCodes != null &&
      inviteCodes.length > 0 &&
      cachedInviteCodes?.[0] !== inviteCodes[0]
    ) {
      setCachedInviteCodes(addInviteCodeToLocalStorage(inviteCodes[0]));
    }
  }, [cachedInviteCodes, inviteCodes, setCachedInviteCodes]);

  const organisationMetadata =
    trpc.organisation.organisationInviteMetadata.useQuery(
      {
        invite_code: cachedInviteCodes?.[0] ?? '',
      },
      {
        enabled: cachedInviteCodes?.[0] != null,
      }
    );

  const joinWithInviteCode = trpc.organisation.joinWithInviteCode.useMutation({
    onSuccess: () => {
      if (cachedInviteCodes?.[0]) {
        setCachedInviteCodes(
          removeInviteCodeFromLocalStorage(cachedInviteCodes[0])
        );
      }
      void recordEvent({
        action: 'Accept Invite',
        target: 'Organisation',
      });
      window.location.pathname = '';
    },
    onError: (e) => {
      toast.error(
        LL.organisations.invitation.error({
          organisationName: organisationMetadata.data?.name ?? 'Unknown',
          message: e.message,
        }),
        { className: 'toast-danger' }
      );
    },
  });

  return {
    inviteCode: cachedInviteCodes?.[0],
    joinWithInviteCode,
    setInviteCodes: setCachedInviteCodes,
    organisationMetadata,
  };
};
