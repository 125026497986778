import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  isInsightsFacebookDateMetric,
  isInsightsFacebookTextMetric,
  isInsightsFacebookEnumerableMetric,
  InsightsFacebookTimeSeriesMetricName,
  InsightsFilterV2,
  insightsFilterV2,
  InsightsTextFieldOperationV2,
  isInsightsFacebookMetricNumeric,
  INSIGHTS_TIME_SERIES_NUMERIC_OPERATIONS_V2,
  InsightsNumericMetricOperationV2,
  InsightsMultiOptionFieldOperationV2,
  INSIGHTS_TIME_SERIES_MULTI_OPTION_FIELD_OPERATIONS_V2,
  isInsightsFacebookFilterNumeric,
  InsightsDateFieldOperationV2,
  InsightsFilterNumeric,
  isInsightsFacebookFilterText,
  isInsightsFacebookFilterEnumerable,
  isInsightsFacebookFilterDate,
  InsightsFacebookMultiOptionField,
  InsightsMetricOperationV2,
  isInsightsFacebookTagMetric,
  InsightsFilterTag,
  INSIGHTS_TIME_SERIES_TAG_FIELD_OPERATIONS_V2,
  InsightsTagFieldOperationV2,
  isInsightsFacebookFilterTag,
} from '@magicbrief/common';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';

const SCHEMA = z.record(
  z.string(),
  z.object({
    values: z.array(insightsFilterV2),
  })
);

export type InsightsFilterForm = z.infer<typeof SCHEMA>;

export function useFilterFormV2({
  defaultValues,
}: {
  defaultValues?: Record<string, { values: Array<InsightsFilterV2> }>;
}) {
  const { control, handleSubmit, setValue, getValues, ...rest } =
    useForm<InsightsFilterForm>({
      resolver: zodResolver(SCHEMA),
      mode: 'all',
      reValidateMode: 'onBlur',
      defaultValues,
    });

  const htmlType = (metric?: string) => {
    if (!metric) {
      return 'text';
    }
    if (
      isInsightsFacebookDateMetric(
        metric as InsightsFacebookTimeSeriesMetricName
      ) ||
      isInsightsFacebookTextMetric(
        metric as InsightsFacebookTimeSeriesMetricName
      ) ||
      isInsightsFacebookEnumerableMetric(
        metric as InsightsFacebookTimeSeriesMetricName
      )
    ) {
      return 'text';
    }
    return 'number';
  };

  return {
    control,
    handleSubmit,
    htmlType,
    getValues,
    setValue,
    ...rest,
  };
}

export function transformInsightsFilterMetric(
  nextMetric: string,
  currentOperation: InsightsMetricOperationV2
) {
  if (isInsightsFacebookTextMetric(nextMetric)) {
    if (currentOperation === 'isSet' || currentOperation === 'isNotSet') {
      return {
        field: nextMetric,
        operation: currentOperation,
      };
    } else {
      return {
        field: nextMetric,
        operation: 'contains' as const,
        value: '',
      };
    }
  } else if (isInsightsFacebookMetricNumeric(nextMetric)) {
    if (currentOperation === 'between' || currentOperation === 'notBetween') {
      return {
        field: nextMetric,
        operation: currentOperation,
        min: 0,
        max: 0,
      };
    } else {
      return {
        field: nextMetric,
        operation:
          currentOperation &&
          INSIGHTS_TIME_SERIES_NUMERIC_OPERATIONS_V2.includes(
            currentOperation as InsightsNumericMetricOperationV2
          )
            ? (currentOperation as Exclude<
                InsightsNumericMetricOperationV2,
                'between' | 'notBetween'
              >)
            : '=',
        value: 0,
      };
    }
  } else if (isInsightsFacebookEnumerableMetric(nextMetric)) {
    return {
      field: nextMetric as InsightsFacebookMultiOptionField,
      operation:
        currentOperation &&
        INSIGHTS_TIME_SERIES_MULTI_OPTION_FIELD_OPERATIONS_V2.includes(
          currentOperation as InsightsMultiOptionFieldOperationV2
        )
          ? (currentOperation as InsightsMultiOptionFieldOperationV2)
          : 'in',
      values: [],
    };
  } else if (isInsightsFacebookTagMetric(nextMetric)) {
    return {
      field: nextMetric as InsightsFilterTag['field'],
      operation:
        currentOperation &&
        INSIGHTS_TIME_SERIES_TAG_FIELD_OPERATIONS_V2.includes(
          currentOperation as InsightsTagFieldOperationV2
        )
          ? (currentOperation as InsightsTagFieldOperationV2)
          : 'in',
      values: [],
    };
  }
}

export function transformInsightsFilterOperation(
  nextOperation: InsightsMetricOperationV2,
  currentFilter: InsightsFilterV2
): InsightsFilterV2 | undefined {
  if (isInsightsFacebookFilterNumeric(currentFilter)) {
    if (nextOperation === 'between' || nextOperation === 'notBetween') {
      if ('value' in currentFilter) {
        return {
          ...currentFilter,
          operation: nextOperation,
          min: currentFilter.value,
          max: undefined as unknown as number,
        };
      } else {
        // No need to drop any values, just change the operation
        return {
          ...currentFilter,
          operation: nextOperation,
        };
      }
    } else {
      if ('value' in currentFilter) {
        return {
          field: currentFilter.field,
          operation: nextOperation as Exclude<
            InsightsFilterNumeric['operation'],
            'between' | 'notBetween'
          >,
          value: currentFilter.value,
        };
      } else {
        // No need to drop any values, just change the operation
        return {
          field: currentFilter.field,
          operation: nextOperation as Exclude<
            InsightsFilterNumeric['operation'],
            'between' | 'notBetween'
          >,
          value: currentFilter.min,
        };
      }
    }
  } else if (isInsightsFacebookFilterText(currentFilter)) {
    if (nextOperation === 'isSet' || nextOperation === 'isNotSet') {
      return {
        ...currentFilter,
        operation: nextOperation,
      };
    } else {
      if ('value' in currentFilter) {
        return {
          ...currentFilter,
          operation: nextOperation as Exclude<
            InsightsTextFieldOperationV2,
            'isSet' | 'isNotSet'
          >,
          value: currentFilter.value,
        };
      } else {
        return {
          ...currentFilter,
          operation: nextOperation as Exclude<
            InsightsTextFieldOperationV2,
            'isSet' | 'isNotSet'
          >,
          value: '',
        };
      }
    }
  } else if (isInsightsFacebookFilterEnumerable(currentFilter)) {
    return {
      ...currentFilter,
      operation: nextOperation as InsightsMultiOptionFieldOperationV2,
    };
  } else if (isInsightsFacebookFilterDate(currentFilter)) {
    if (nextOperation === 'between' || nextOperation === 'notBetween') {
      if (
        currentFilter.operation === 'between' ||
        currentFilter.operation === 'notBetween'
      ) {
        return {
          field: currentFilter.field,
          operation: nextOperation,
          start: currentFilter.start,
          end: currentFilter.end,
        };
      } else {
        return {
          field: currentFilter.field,
          start: new Date(),
          end: undefined as unknown as Date,
          operation: nextOperation,
        };
      }
    } else if (nextOperation === 'withinLast') {
      return {
        field: currentFilter.field,
        value: undefined as unknown as number,
        operation: nextOperation,
        interval: 'day',
      };
    } else if (
      nextOperation === '=' ||
      nextOperation === '!=' ||
      nextOperation === '<' ||
      nextOperation === '>'
    ) {
      return {
        field: currentFilter.field,
        value:
          'value' in currentFilter && currentFilter.value instanceof Date
            ? currentFilter.value
            : (undefined as unknown as Date),
        operation: nextOperation,
      };
    }
  } else if (isInsightsFacebookFilterTag(currentFilter)) {
    if (nextOperation === 'in' || nextOperation == 'notIn') {
      return {
        ...currentFilter,
        operation: nextOperation,
      };
    }
  }
}

export function useFilterFormFieldV2(prefix: string) {
  const { control, getValues } = useFormContext<InsightsFilterForm>();
  const arrayProps = useFieldArray<InsightsFilterForm>({
    control,
    name: `${prefix}values` as `${string}.values`,
  });

  const onMetricChange = (metric: string, index: number) => {
    const value = getValues(
      `${prefix}values.${index}` as `${string}.values.${number}`
    );
    const operation = value.operation;

    const newValue = transformInsightsFilterMetric(metric, operation);
    if (newValue) {
      arrayProps.update(index, newValue);
    }
  };

  const onOperationChange = (
    operation:
      | InsightsTextFieldOperationV2
      | InsightsNumericMetricOperationV2
      | InsightsMultiOptionFieldOperationV2
      | InsightsDateFieldOperationV2,
    index: number
  ) => {
    const value = getValues(
      `${prefix}values.${index}` as `${string}.values.${number}`
    );

    const metric = value;

    if (!metric) {
      return;
    }

    const nextFilter = transformInsightsFilterOperation(operation, value);
    if (nextFilter) {
      arrayProps.update(index, nextFilter);
    }
  };

  return {
    onMetricChange,
    onOperationChange,
    ...arrayProps,
  };
}
