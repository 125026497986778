import { forwardRef } from 'react';
import { cn } from 'src/lib/cn';
import Checkbox, { type CheckboxProps } from '../Checkbox/Checkbox';
import { Icon } from '../Icon';

type Props = {
  icon: React.ReactNode;
  label: string;
} & Omit<CheckboxProps, 'children'>;

const SelectionChip = forwardRef<HTMLInputElement, Props>(
  ({ icon, label, checked, className, ...props }, ref) => {
    return (
      <Checkbox
        ref={ref}
        checkboxClassName="hidden"
        className={cn(
          'flex flex-col items-center gap-2 rounded-lg border border-solid border-purple-100 p-3 shadow-sm transition-colors',
          {
            '-m-px border-2 border-purple-300 bg-purple-100': checked,
          },
          className
        )}
        {...props}
      >
        <div
          className={cn(
            'rounded-lg border border-solid border-purple-200/50 p-2 transition-colors',
            {
              'border-transparent bg-purple-200': checked,
            }
          )}
        >
          <Icon
            className={cn({
              'opacity-50': !checked,
            })}
          >
            {icon}
          </Icon>
        </div>
        <span className="select-none text-center text-sm font-semibold">
          {label}
        </span>
      </Checkbox>
    );
  }
);

SelectionChip.displayName = 'SelectionChip';

export default SelectionChip;
