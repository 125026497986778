import { useEffect, useRef } from 'react';
import { captureException } from '@sentry/react';
import { useSearchParams } from 'react-router-dom';
import { AppWithNavLoader } from 'src/components/SuspenseLoaders/SuspenseLoaders';

import { getAttributionData } from 'src/lib/attribution';
import { trpc } from 'src/lib/trpc';
import { localStorage } from 'src/utils/storage';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import {
  clearLocalUTMData,
  retrieveUTMMetadataFromLocalStorage,
} from 'src/utils/utm';
import { useUserAndOrganisation } from '../../../utils/useUserAndOrganisation';
import { useFirebaseContext } from '../FirebaseOutlet/useFirebaseContext';
import { useOrganisationInvitation } from '../../../utils/useOrganisationInvitation';
import JoinOrg from '../RequireAuthenticatedOutlet/components/JoinOrg';
import UniversalModals from '../RequireAuthenticatedOutlet/components/UniversalModals';

const InitialiseUserOutlet: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const firebaseUser = useFirebaseContext();
  const user = useUserAndOrganisation();
  const trpcUtils = trpc.useUtils();
  const { recordEvent } = useNewAnalyticsEvent();

  const [searchParams, setSearchParams] = useSearchParams();

  const { inviteCode } = useOrganisationInvitation();
  const referralID = localStorage.getItem('referralID');
  const utm = retrieveUTMMetadataFromLocalStorage();
  const initLock = useRef<boolean>(false);

  const refreshUser = async () => {
    await trpcUtils.user.getUserAndOrganisation.invalidate();
    await trpcUtils.organisation.getOrganisation.invalidate();
  };

  const initialiseMutation = trpc.user.initialise.useMutation({
    onSuccess: async (result) => {
      if (result) {
        if (result.newUser) {
          const updatedSearchParams = new URLSearchParams(
            searchParams.toString()
          );
          setSearchParams(updatedSearchParams);
          void recordEvent({
            action: 'Completed signup',
            target: 'User',
          });
        }
      }

      clearLocalUTMData();
      localStorage.removeItem('referralID');
      await refreshUser();
    },
    onError: (err) => {
      captureException(err, (scope) => {
        scope.setLevel('error');
        scope.setTransactionName('First Time User Init');
        if (firebaseUser) {
          scope.setUser({
            id: firebaseUser.uid,
            email: firebaseUser.email ?? undefined,
          });
        }
        return scope;
      });
    },
  });

  const onJoinOrgSuccess = async () => {
    await refreshUser();
  };

  useEffect(() => {
    if (!initLock.current) {
      /** @todo this is temporary logic until we split up the steps */
      const shouldInitialiseUser =
        firebaseUser &&
        firebaseUser.emailVerified &&
        !user.isFetching &&
        !user.isLoading &&
        user.data === null;

      if (shouldInitialiseUser) {
        initLock.current = true;
        const data = getAttributionData();
        initialiseMutation.mutate({
          inviteCode: inviteCode ?? undefined,
          referral: data?.documentReferrer ?? undefined,
          utm: data ?? {},
        });
      }
    }
  }, [firebaseUser, inviteCode, initialiseMutation, referralID, utm, user]);

  if (firebaseUser === null) {
    return <>{children}</>;
  }

  const isLoading = initialiseMutation.isLoading || user.isFetching;

  const orgIdToJoin = initialiseMutation.data?.orgIdToJoin ?? null;

  const userCanJoinOrg =
    !isLoading && user.data === null && orgIdToJoin != null;

  if (userCanJoinOrg) {
    return (
      <JoinOrg
        firebaseUser={{ email: firebaseUser.email, uid: firebaseUser.uid }}
        organisationId={orgIdToJoin}
        onSuccess={onJoinOrgSuccess}
      />
    );
  }

  if (isLoading) {
    return <AppWithNavLoader />;
  }

  return (
    <>
      <UniversalModals />
      {children}
    </>
  );
};

export default InitialiseUserOutlet;
