import {
  Button,
  Dialog,
  Heading,
  Modal,
  ModalOverlay,
} from 'react-aria-components';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { trpc } from 'src/lib/trpc';
import { Alert } from 'src/components/Alert';
import AlertOctagon from 'src/assets/svgicons/duocolor/alert-octagon.svg';
import { PermissionManager } from 'src/components/PermissionManager';
import ShareSettings from 'src/components/ShareSettings';
import useDirectoryLink from 'src/utils/useDirectoryLink';
import { segment } from 'src/lib/segment';
import Spinner from 'src/components/Loaders/Spinner';

type Props = {
  onOpenChange: (isOpen: boolean) => void;
  entity: {
    directoryNodeUuid: string;
    entityName: string;
  } | null;
};

export const ShareDirectoryModal: React.FC<Props> = ({
  onOpenChange,
  entity,
  ...props
}): JSX.Element => {
  return (
    <ModalOverlay
      isOpen={!!entity}
      onOpenChange={onOpenChange}
      className="fixed inset-0 z-500 flex min-h-full items-center justify-center overflow-y-auto bg-gray-900/60 p-4 text-center"
    >
      <Modal className="flex max-h-[90%] w-full max-w-md rounded-xl bg-white p-6 text-left align-middle shadow-xl">
        <Dialog className="flex min-h-0 min-w-0 flex-auto outline-none">
          {({ close }) => (
            <>
              {entity && (
                <ShareDirectoryModalBody
                  close={close}
                  entity={entity}
                  {...props}
                />
              )}
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

type ShareDirectoryModalBodyProps = {
  close: () => void;
  entity: Exclude<Props['entity'], null>;
};

const ShareDirectoryModalBody: React.FC<ShareDirectoryModalBodyProps> = ({
  close,
  entity,
}) => {
  const { LL } = useI18nContext();
  const trpcUtils = trpc.useUtils();
  const directory = trpc.directories.getDirectoryNodeDetail.useQuery({
    uuid: entity.directoryNodeUuid,
  });
  const { link, copyToClipboard } = useDirectoryLink(
    entity.directoryNodeUuid,
    directory.data?.uuid ?? null
  );
  const mutate = trpc.directories.updateDirectoryNode.useMutation({
    onSuccess(data, opts) {
      trpcUtils.directories.getDirectoryNodeDetail.setData(
        { uuid: opts.uuid },
        (old) => (old ? { ...old, ...data } : old)
      );
    },
  });

  return (
    <div className="flex min-w-0 flex-auto flex-col gap-4">
      <div className="flex flex-row items-center justify-between gap-4 text-purple-800">
        <Heading className="truncate text-xl font-bold">
          {`Share ${entity.entityName}`}
        </Heading>
        <Button onPress={close}>
          <Icon>
            <XClose />
          </Icon>
        </Button>
      </div>

      {mutate.error && (
        <Alert
          icon={
            <Icon>
              <AlertOctagon />
            </Icon>
          }
          className="w-full"
          type="error"
        >
          <p className="text-xs font-normal">
            {LL.errors.genericWithDetail({
              detail: mutate.error.message,
            })}
          </p>
        </Alert>
      )}

      {directory.isLoading ? (
        <Spinner className="self-center justify-self-center text-purple-800" />
      ) : (
        <>
          {directory.data && (
            <>
              <p className="text-sm text-purple-800">
                Sharing a board grants access to all items pinned to it,
                including sub-boards.
              </p>
              <ShareSettings
                link={mutate.isLoading ? LL.regeneratingLink() : link}
                isLoading={mutate.isLoading}
                isPublic={directory.data.realm === 'public'}
                onUpdate={(isPublic) => {
                  mutate.mutate({
                    uuid: entity.directoryNodeUuid,
                    realm: isPublic ? 'public' : 'private',
                  });
                }}
                onCopyToClipboard={() => {
                  if (directory.data?.realm === 'public') {
                    void segment?.track('shared_board_public');
                  } else {
                    void segment?.track('shared_board_private');
                  }
                  copyToClipboard();
                }}
                screenReaderLabel={LL.briefs.shareScreenReader({
                  name: directory.data.name,
                })}
                publicDescription="Anyone with the link can view this board and it's sub-boards."
                privateDescription="Only team members or users with access can view or edit this board and it's sub-boards."
              />
            </>
          )}
          <PermissionManager
            entityType="DirectoryNode"
            entityUuid={entity.directoryNodeUuid}
          />
        </>
      )}
    </div>
  );
};
