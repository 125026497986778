import { cn } from 'src/lib/cn';
import ProgressBar, { type ProgressBarProps } from '../ProgressBar';

type Props = {
  stepsPercentages: number[];
  progressBarProps?: ProgressBarProps;
} & React.ComponentProps<'div'>;

const SegmentedProgressBar = ({
  className,
  stepsPercentages,
  progressBarProps,
  ...props
}: Props) => {
  if (stepsPercentages.length === 0) {
    console.warn('No steps percentages provided to SegmentedProgressBar');
    return null;
  }

  return (
    <div className={cn('flex gap-1.5', className)} {...props}>
      {stepsPercentages.map((percentage, index) => (
        <ProgressBar
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          value={percentage}
          {...progressBarProps}
        />
      ))}
    </div>
  );
};

export default SegmentedProgressBar;
