import { useState } from 'react';
import { captureException } from '@sentry/react';
import { toast } from 'react-toastify';
import { useI18nContext } from 'src/i18n/i18n-react';
import {
  getAdAccountUsers,
  getAllAdAccounts,
  getAllBusinessUsers,
  getUser,
  GetUserResponse,
  GetUsersForAdAccountResponse,
  login,
} from 'src/lib/facebook';

export type AdAccountResult = {
  id: string;
  accountId: string;
  name: string;
  profilePicURL?: string;
  role: string;
  users: GetUsersForAdAccountResponse['users']['data'];
  pageAccessToken?: string;
};

async function getAdAccountsForUser(userId = 'me'): Promise<AdAccountResult[]> {
  let user: GetUserResponse | undefined;

  try {
    user = await getUser({ userId });
  } catch (e) {
    throw new Error('Error getting Facebook user');
  }

  if (user == null) {
    throw new Error('Error getting Facebook user');
  }

  let adAccounts: Awaited<ReturnType<typeof getAllAdAccounts>> = [];
  try {
    adAccounts = await getAllAdAccounts({ userId });
  } catch (e) {
    // do nothing, captured up the chain
  }

  let businessUsers: Awaited<ReturnType<typeof getAllBusinessUsers>> = [];
  try {
    businessUsers = await getAllBusinessUsers({ userId });
  } catch (e) {
    // do nothing, captured up the chain
  }

  const getUsersForAdAccountResponses =
    adAccounts != null && adAccounts.length > 0
      ? await Promise.allSettled(
          adAccounts.map((adAccount) =>
            getAdAccountUsers({ adAccountId: adAccount.id })
          )
        )
      : [];

  const result =
    adAccounts
      ?.map((adAccount) => {
        const usersForAdAccount = getUsersForAdAccountResponses.find(
          (
            result
          ): result is PromiseFulfilledResult<GetUsersForAdAccountResponse> =>
            result.status === 'fulfilled' && result.value.id === adAccount.id
        )?.value;

        const businessUser =
          usersForAdAccount != null &&
          businessUsers != null &&
          businessUsers.length > 0
            ? businessUsers.find(
                (businessUser) =>
                  businessUser.business.id === usersForAdAccount.owner
              )
            : null;

        const role =
          usersForAdAccount?.owner === user.id
            ? 'ADMIN'
            : businessUser?.role ?? 'unknown';

        return {
          id: adAccount.id,
          accountId: adAccount.id.replace('act_', ''),
          name: adAccount.name,
          profilePicURL: businessUser?.business.profile_picture_uri,
          role,
          users: usersForAdAccount?.users?.data ?? [],
        };
      })
      ?.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }) || [];

  return result;
}

export type FacebookAuthState =
  | { status: 'init' }
  | { status: 'pending' }
  | {
      status: 'success';
      adAccounts: AdAccountResult[];
      fbUserID: string;
      accessToken: string;
    }
  | {
      status: 'error';
      error: Error;
    };

export const useFacebook = () => {
  const { LL } = useI18nContext();

  const [authState, setAuthState] = useState<FacebookAuthState>({
    status: 'init',
  });

  const authenticate = async () => {
    try {
      const { fbUserID, accessToken } = await login();

      setAuthState({
        status: 'pending',
      });

      const adAccounts = await getAdAccountsForUser();

      setAuthState({
        status: 'success',
        fbUserID,
        accessToken,
        adAccounts,
      });
    } catch (e) {
      // if the user has closed the Facebook connect window prematurely,
      // we can reset the state to the initial state
      if (
        e instanceof Error &&
        e.message === 'Error logging in, unknown status received'
      ) {
        setAuthState({ status: 'init' });
      } else {
        setAuthState({ status: 'error', error: e as Error });
        captureException(e);
        toast.error(
          LL.integrations.facebook.authenticateFacebook.error({
            error:
              e instanceof Error && 'message' in e
                ? e.message
                : 'Unknown error',
          }),
          { className: 'toast-danger' }
        );
      }
    }
  };

  return {
    authenticate,
    authState,
    setAuthState,
  };
};
