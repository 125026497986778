import { FC } from 'react';
import Copy01 from 'src/assets/svgicons/duocolor/copy-01.svg';
import Link01 from 'src/assets/svgicons/duocolor/link-01.svg';
import { AriaButton } from 'src/components/Button/Button';
import { Icon } from 'src/components/Icon';
import Input from 'src/components/Input';
import { useI18nContext } from 'src/i18n/i18n-react';
import { UserStateInput } from 'src/types/analytics';
import { useCopyToClipboard } from 'src/utils/useCopyToClipboard';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';

type Props = {
  url: string;
  analyticsMetadata: UserStateInput;
};

const CopyInviteLink: FC<Props> = ({ url, analyticsMetadata }) => {
  const { LL } = useI18nContext();
  const copyToClipboard = useCopyToClipboard();
  const { recordEvent } = useNewAnalyticsEvent();

  return (
    <div>
      <label
        htmlFor="invite-email-input"
        className="block pb-1 text-xs text-primary sm:text-sm"
      >
        {LL.labels.inviteLink()}
      </label>
      <div className="flex w-full flex-col gap-4 pb-4 sm:flex-row">
        <div className="grow">
          <Input label="" name="companyInviteLink" value={url} readOnly>
            <span className="text-primary">
              <Icon>
                <Link01 />
              </Icon>
            </span>
          </Input>
        </div>
        <div className="col-span-2 grow sm:grow-0">
          <AriaButton
            className="sm:self-end"
            variant="secondary"
            onPress={() => {
              copyToClipboard(url);
              void recordEvent(analyticsMetadata);
            }}
            icon={
              <Icon>
                <Copy01 />
              </Icon>
            }
          >
            <span>{LL.copy()}</span>
          </AriaButton>
        </div>
      </div>
    </div>
  );
};

export default CopyInviteLink;
