import { createContext, useContext } from 'react';
import {
  groupSchema,
  InsightsFacebookGroup,
  metricFilterSchema,
  migrateFormerColumnMetricsToActionMetric,
  stringifyInsightsFacebookActionMetric,
  InsightsFilterV2,
  convertFiltersV1ToV2,
  insightsFilterV2,
  type ActiveMetric,
  type InsightsTableColorSchema,
  insightsFilterExpressionSchema,
  uuidv4,
} from '@magicbrief/common';
import { atom, Atom, useSetAtom, WritableAtom, useAtomValue } from 'jotai';
import { isEqual } from 'lodash';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage';
import { inferProcedureOutput } from '@trpc/server';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { z } from 'zod';
import { InsightsFilterSet } from '../routes/InsightsCompare/types';

export type InsightsSort = {
  id: string;
  desc: boolean;
};

interface InsightsStatePersisted {
  display?: Array<string>;
  sort?: Array<InsightsSort>;
  filter?: Array<InsightsFilterV2> | Array<ActiveMetric>;
  group?: InsightsFacebookGroup | null;
  columnSizing?: Record<string, number>;
  graphMetrics?: Array<string>;
  selected?: Array<string | null> | 'default';
  layout?: 'table' | 'grid';
  tableColorScheme?: InsightsTableColorSchema | null;
  comparisons?: Array<InsightsFilterSet>;
}

export interface InsightsState {
  comparisons: Array<InsightsFilterSet> | undefined;
  display: Array<string>;
  sort: Array<InsightsSort>;
  filter: Array<InsightsFilterV2>;
  group: InsightsFacebookGroup | null;
  columnSizing: Record<string, number>;
  graphMetrics: Array<string>;
  selected: Array<string | null> | 'default';
  layout: 'table' | 'grid';
  tableColorScheme: InsightsTableColorSchema | null;
}

export type InsightsView = 'overview' | 'analysis' | 'compare';

type InsightsStateAction =
  | {
      type: 'setDisplay';
      value: Array<string>;
    }
  | {
      type: 'setColumnSizing';
      value: Record<string, number>;
    }
  | { type: 'toggleDisplay'; value: string }
  | {
      type: 'setFilter';
      value: Array<InsightsFilterV2>;
    }
  | { type: 'addFilter'; value: InsightsFilterV2 }
  | { type: 'removeFilter'; value: InsightsFilterV2 }
  | { type: 'setComparisons'; value: Array<InsightsFilterSet> }
  | { type: 'setSort'; value: Array<InsightsSort> }
  | { type: 'addSort'; value: InsightsSort }
  | { type: 'removeSort'; value: InsightsSort }
  | { type: 'toggleSort'; value: InsightsSort }
  | {
      type: 'setGroup';
      value: InsightsFacebookGroup | null;
    }
  | { type: 'addGraphMetric'; value: string }
  | { type: 'removeGraphMetric'; value: string }
  | { type: 'setGraphMetrics'; value: Array<string> }
  | { type: 'setSelected'; value: Array<string | null> | 'default' }
  | { type: 'setLayout'; value: 'table' | 'grid' }
  | {
      type: 'setTableColorScheme';
      value: InsightsTableColorSchema;
    };

const DEFAULT_INSIGHTS_FILTER: Array<InsightsFilterV2> = [
  { field: 'effectiveStatus', operation: 'in', values: ['Active'] },
];

const DEFAULT_INSIGHTS_SORT: Array<InsightsSort> = [
  { id: 'spend', desc: true },
];

function getDefaultInsightsDisplay(platform: 'facebook' | 'tiktok') {
  switch (platform) {
    case 'facebook':
      return [
        'spend',
        'impressions',
        'clicks',
        'cpm',
        'cpc',
        'clickThroughRateLinks', // Click through rate (links)
      ];
    case 'tiktok':
      return ['spend', 'impressions', 'clicks', 'cpm', 'cpc'];
    default:
      platform satisfies never;
      return [];
  }
}

const DEFAULT_INSIGHTS_GRAPH_METRICS: Array<string> = [
  'spend',
  'impressions',
  'clicks',
];

const DEFAULT_INSIGHTS_COMPARISON_GROUPS: Array<InsightsFilterSet> = [
  {
    name: 'Video Ads',
    filter: [
      {
        operand: {
          expressions: [
            {
              operand: {
                field: 'creativeType',
                operation: 'in',
                values: ['video'],
              },
              operator: 'or',
            },
          ],
        },
        operator: 'or',
      },
    ],
    id: uuidv4(),
  },
  {
    name: 'Image Ads',
    filter: [
      {
        operand: {
          expressions: [
            {
              operand: {
                field: 'creativeType',
                operation: 'in',
                values: ['image'],
              },
              operator: 'or',
            },
          ],
        },
        operator: 'or',
      },
    ],
    id: uuidv4(),
  },
  {
    name: 'Dynamic Ads',
    filter: [
      {
        operand: {
          expressions: [
            {
              operand: {
                field: 'creativeType',
                operation: 'in',
                values: ['dynamic'],
              },
              operator: 'or',
            },
          ],
        },
        operator: 'or',
      },
    ],
    id: uuidv4(),
  },
];

type InsightsPersistentStateContextValue = {
  all: WritableAtom<InsightsState, [InsightsStateAction], void>;
  comparisons: Atom<Array<InsightsFilterSet> | undefined>;
  filter: Atom<Array<InsightsFilterV2>>;
  display: Atom<Array<string>>;
  columnSizing: Atom<Record<string, number>>;
  sort: Atom<Array<InsightsSort>>;
  group: Atom<InsightsFacebookGroup | null>;
  graphMetrics: Atom<Array<string>>;
  selected: Atom<Array<string | null> | 'default'>;
  layout: Atom<'table' | 'grid'>;
  tableColorScheme: Atom<InsightsTableColorSchema | null>;
};

export const InsightsPersistentStateContext =
  createContext<InsightsPersistentStateContextValue | null>(null);

export function createInsightsDefaultPersistentState(
  platform: 'facebook' | 'tiktok',
  view?: InsightsView | null
): InsightsState {
  return {
    // Do not apply to Comparison groups
    filter: view === 'compare' ? [] : DEFAULT_INSIGHTS_FILTER,
    display: getDefaultInsightsDisplay(platform),
    sort: DEFAULT_INSIGHTS_SORT,
    group: null,
    columnSizing: {},
    graphMetrics: DEFAULT_INSIGHTS_GRAPH_METRICS,
    selected: 'default',
    layout: 'table',
    // We only provide a fallback for reports, others rely on local storage
    tableColorScheme: null,
    comparisons: DEFAULT_INSIGHTS_COMPARISON_GROUPS,
  };
}

function hydrateInsightsPersistentState(
  platform: 'facebook' | 'tiktok',
  value: Partial<InsightsStatePersisted>
): InsightsState {
  const display = value.display
    ? [
        ...new Set(
          value.display
            .map((x) => {
              // Capture bugged metric used in some cases
              if (x === 'link_clicks') {
                return null;
              }
              const converted = migrateFormerColumnMetricsToActionMetric(x);
              return converted
                ? stringifyInsightsFacebookActionMetric(converted)
                : x;
            })
            .filter((x): x is Exclude<typeof x, null> => !!x)
        ),
      ]
    : [];

  const selected = Array.isArray(value.selected)
    ? value.selected.reduce<Array<string | null>>((acc, x) => {
        if (!x) {
          acc.push(x);
          return acc;
        }
        if (value.group === 'adName') {
          if (x.startsWith('adName:')) {
            acc.push(x.replace(/^adName:/, ''));
            return acc;
          } else if (x.startsWith('postId:')) {
            return acc;
          }
        }

        acc.push(x);
        return acc;
      }, [])
    : value.selected ?? 'default';

  const graphMetrics = value.graphMetrics ?? value.sort?.map((x) => x.id) ?? [];

  const layout = value.layout ?? 'table';

  const sort =
    value.sort?.reduce<InsightsSort[]>((acc, x) => {
      const converted = migrateFormerColumnMetricsToActionMetric(x.id);

      const value = {
        ...x,
        id: converted ? stringifyInsightsFacebookActionMetric(converted) : x.id,
      };

      if (!acc.some((y) => y.id === value.id)) {
        acc.push(value);
      }

      return acc;
    }, []) ?? [];

  const filter = value.filter ? convertFiltersV1ToV2(value.filter) : [];

  const migrated: InsightsState = {
    ...createInsightsDefaultPersistentState(platform),
    ...value,
    selected,
    display,
    sort,
    graphMetrics,
    filter,
    layout,
    columnSizing: Object.entries(value.columnSizing ?? {}).reduce<
      Record<string, number>
    >((acc, curr) => {
      const converted = migrateFormerColumnMetricsToActionMetric(curr[0]);
      if (converted) {
        acc[stringifyInsightsFacebookActionMetric(converted)] = curr[1];
      } else {
        acc[curr[0]] = curr[1];
      }
      return acc;
    }, {}),
  };
  return migrated;
}

function reducer(
  state: InsightsState,
  { value, type }: InsightsStateAction
): InsightsState {
  switch (type) {
    case 'setDisplay':
      return {
        ...state,
        display: value,
      };
    case 'setSort':
      return {
        ...state,
        sort: value,
      };
    case 'setFilter':
      return {
        ...state,
        filter: value,
      };
    case 'setGroup':
      return {
        ...state,
        group: value,
        selected: value !== state.group ? 'default' : state.selected,
      };
    case 'toggleDisplay': {
      const oldValue = state.display;
      const idx = oldValue?.findIndex((x) => x === value);
      return {
        ...state,
        display:
          idx === -1
            ? [...oldValue, value]
            : [...oldValue.slice(0, idx), ...oldValue.slice(idx + 1)],
      };
    }
    case 'addFilter': {
      const currentFilters = convertFiltersV1ToV2(state.filter);

      // Find the index of the filter that deeply equals the incoming filter
      const matchingDeeplyEqualIndex = currentFilters.findIndex((filter) =>
        isEqual(filter, value)
      );

      // Do not mutate any filter state if the user adds the exact same filter
      if (matchingDeeplyEqualIndex !== -1) {
        return state;
      }

      return {
        ...state,
        filter: [...currentFilters, value],
      };
    }
    case 'removeFilter': {
      const currentFilters = convertFiltersV1ToV2(state.filter);

      const newFilters = currentFilters.filter(
        (filter) => !isEqual(filter, value)
      );

      return {
        ...state,
        filter: newFilters,
      };
    }
    case 'addSort': {
      return {
        ...state,
        sort: [...state.sort, value],
      };
    }
    case 'removeSort': {
      const currentSort = state.sort;
      const newSort = currentSort.filter((sort) => sort.id !== value.id);

      return {
        ...state,
        sort: newSort,
      };
    }
    case 'toggleSort': {
      const oldValue = state.sort;
      const idx = oldValue?.findIndex((x) => x.id === value.id);
      return {
        ...state,
        sort:
          idx === -1
            ? [...oldValue, value]
            : [...oldValue.slice(0, idx), ...oldValue.slice(idx + 1)],
      };
    }
    case 'setColumnSizing': {
      return {
        ...state,
        columnSizing: value,
      };
    }

    case 'addGraphMetric': {
      return {
        ...state,
        graphMetrics: [...state.graphMetrics, value],
      };
    }
    case 'removeGraphMetric': {
      const updatedGraphMetrics = state.graphMetrics.filter(
        (metric) => metric !== value
      );

      return {
        ...state,
        graphMetrics: updatedGraphMetrics,
      };
    }
    case 'setGraphMetrics': {
      return {
        ...state,
        graphMetrics: value,
      };
    }
    case 'setSelected': {
      return {
        ...state,
        selected: value,
      };
    }

    case 'setLayout': {
      return {
        ...state,
        layout: value,
      };
    }

    case 'setTableColorScheme':
      return {
        ...state,
        tableColorScheme: value,
      };

    case 'setComparisons':
      return {
        ...state,
        comparisons: value,
      };
  }
}

/**
 * Insights Hydration Validation Schemas
 */
const storedInsightsReportSchema = z.object({
  columnSizing: z.record(z.string(), z.number()).optional(),
  selected: z.array(z.string().nullable()).or(z.literal('default')).optional(),
  layout: z.enum(['table', 'grid']).optional(),
}) satisfies z.ZodType<
  Partial<Pick<InsightsStatePersisted, 'columnSizing' | 'selected' | 'layout'>>
>;

const storedInsightsSchema: z.ZodType<
  Partial<
    Pick<
      InsightsStatePersisted,
      | 'columnSizing'
      | 'selected'
      | 'layout'
      | 'filter'
      | 'group'
      | 'comparisons'
    >
  >
> = storedInsightsReportSchema.extend({
  filter: metricFilterSchema.or(z.array(insightsFilterV2)).optional(),
  group: groupSchema,
  comparisons: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        filter: insightsFilterExpressionSchema,
      })
    )
    .optional(),
});

export function createInsightsReportStorage(
  platform: 'facebook' | 'tiktok',
  mergeValue: InsightsState
): SyncStorage<InsightsState> {
  const storage = createJSONStorage<InsightsState>(() => localStorage);
  const getItem = (key: string, initialValue: InsightsState) => {
    try {
      const value = storage.getItem(
        key,
        initialValue
      ) as Partial<InsightsStatePersisted>;

      if (!storedInsightsReportSchema.safeParse(value).success) {
        return hydrateInsightsPersistentState(platform, mergeValue);
      }

      return hydrateInsightsPersistentState(platform, {
        ...value,
        ...mergeValue, // Incoming server report state takes priority
      });
    } catch (e) {
      // Error occurred, reset the UI state to report default
      const defaultState = mergeValue;
      storage.setItem(key, mergeValue);
      return defaultState;
    }
  };
  const setItem = (key: string, value: InsightsState) => {
    const { columnSizing, selected, layout } = value;
    return storage.setItem(key, {
      columnSizing,
      selected,
      layout,
    } as InsightsState);
  };
  return { ...storage, getItem, setItem };
}

export function createInsightsStandardStorage(
  platform: 'facebook' | 'tiktok'
): SyncStorage<InsightsState> {
  const storage = createJSONStorage<InsightsState>(() => localStorage);
  const getItem = (key: string, initialValue: InsightsState) => {
    try {
      const value = storage.getItem(
        key,
        initialValue
      ) as Partial<InsightsStatePersisted>;

      if (!storedInsightsSchema.safeParse(value).success) {
        return createInsightsDefaultPersistentState(platform);
      }

      return hydrateInsightsPersistentState(platform, value);
    } catch (e) {
      // Error occurred, reset the UI state to default
      const defaultState = createInsightsDefaultPersistentState(platform);
      storage.setItem(key, defaultState);
      return defaultState;
    }
  };
  const setItem = (key: string, value: InsightsState) => {
    const {
      columnSizing,
      display,
      sort,
      filter,
      graphMetrics,
      group,
      selected,
      layout,
      comparisons,
    } = value;
    return storage.setItem(key, {
      display,
      sort,
      filter,
      columnSizing,
      graphMetrics,
      group,
      selected,
      layout,
      comparisons,
    } as InsightsState);
  };
  return { ...storage, getItem, setItem };
}

type CreateInsightsAtomParams =
  | {
      type: 'standard';
      accountUuid: string;
      view: InsightsView;
    }
  | {
      type: 'report';
      reportUuid: string;
      initialState: inferProcedureOutput<
        AppRouter['insightsReports']['getInsightsReport']
      >['config'];
    }
  | {
      type: 'comparison-report';
      reportUuid: string;
      initialState: inferProcedureOutput<
        AppRouter['insightsComparisonReports']['getOne']
      >;
    };

export function createInsightsAtom(
  platform: 'tiktok' | 'facebook',
  params: CreateInsightsAtomParams
): WritableAtom<InsightsState, [InsightsStateAction], void> {
  const storageAtom =
    params.type === 'report'
      ? atomWithStorage<InsightsState>(
          `insights_report_${params.reportUuid}`,
          {
            comparisons: undefined,
            filter: convertFiltersV1ToV2(
              params.initialState?.filter.metric ?? []
            ),
            display: params.initialState?.filter.display ?? [],
            sort: params.initialState?.filter?.sort ?? [],
            group: params.initialState?.group ?? null,
            columnSizing: {},
            graphMetrics:
              params.initialState?.filter?.graphMetrics ??
              params.initialState?.filter?.sort?.map((x) => x.id as string) ??
              [],
            selected: 'default',
            layout: params.initialState?.filter?.layout ?? 'table',
            tableColorScheme:
              params.initialState?.tableColorScheme ?? 'positive-neutral',
          },
          createInsightsReportStorage(platform, {
            comparisons: undefined,
            filter: convertFiltersV1ToV2(
              params.initialState?.filter.metric ?? []
            ),
            display: params.initialState?.filter.display ?? [],
            sort: params.initialState?.filter?.sort ?? [],
            group: params.initialState?.group ?? null,
            columnSizing: {},
            graphMetrics:
              params.initialState?.filter?.graphMetrics ??
              params.initialState?.filter?.sort?.map((x) => x.id) ??
              [],
            selected: 'default',
            layout: params.initialState?.filter?.layout ?? 'table',
            tableColorScheme:
              params.initialState?.tableColorScheme ?? 'positive-neutral',
          }),
          { getOnInit: true }
        )
      : params.type === 'comparison-report'
      ? atomWithStorage<InsightsState>(
          `insights_comparison_report_${params.reportUuid}`,
          {
            filter: [],
            comparisons: params.initialState.comparisons,
            display: params.initialState?.visibleFields ?? [],
            sort: params.initialState?.sort ?? [],
            group:
              (params.initialState?.group as InsightsFacebookGroup) ?? null,
            columnSizing: {},
            graphMetrics:
              params.initialState?.graphMetrics ??
              params.initialState?.sort?.map((x) => x.id) ??
              [],
            selected: 'default',
            layout:
              (params.initialState?.layout as 'table' | 'grid') ?? 'table',
            tableColorScheme:
              (params.initialState
                ?.tableColorScheme as InsightsTableColorSchema) ??
              'positive-neutral',
          },
          createInsightsReportStorage(platform, {
            filter: [],
            comparisons: params.initialState.comparisons,
            display: params.initialState.visibleFields ?? [],
            sort: params.initialState?.sort ?? [],
            group:
              (params.initialState?.group as InsightsFacebookGroup) ?? null,
            columnSizing: {},
            graphMetrics:
              params.initialState?.graphMetrics ??
              params.initialState?.sort?.map((x) => x.id) ??
              [],
            selected: 'default',
            layout:
              (params.initialState?.layout as 'table' | 'grid') ?? 'table',
            tableColorScheme:
              (params.initialState
                ?.tableColorScheme as InsightsTableColorSchema) ??
              'positive-neutral',
          }),
          { getOnInit: true }
        )
      : atomWithStorage<InsightsState>(
          `insights_account_${params.accountUuid}_${params.view}`,
          createInsightsDefaultPersistentState(platform, params.view),
          createInsightsStandardStorage(platform),
          { getOnInit: true }
        );

  const storageAtomWithReducer = atom<
    InsightsState,
    [InsightsStateAction],
    void
  >(
    (get) => get(storageAtom),
    (get, set, action) => {
      set(storageAtom, reducer(get(storageAtom), action));
    }
  );

  return storageAtomWithReducer;
}

export function useInsightsFilter() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.filter);
  return value;
}

export function useInsightsSort() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.sort);
  return value;
}

export function useInsightsDisplay() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.display);
  return value;
}

export function useInsightsGroup() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.group);
  return value;
}

export function useInsightsColumnSize() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.columnSizing);
  return value;
}

export function useInsightsGraphMetrics() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.graphMetrics);
  return value;
}

export function useInsightsSelectedItems() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.selected);
  return value;
}

export function useInsightsLayout() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.layout);
  return value;
}

export function useInsightsTableColorScheme() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.tableColorScheme);
  return value;
}

export function useInsightsComparisons() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.comparisons);
  return value;
}

export function useInsightsStoreDispatch() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const dispatch = useSetAtom(ctx.all);
  return dispatch;
}
