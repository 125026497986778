import React, { FC, MouseEvent, useState } from 'react';
import { ArrayElement, InsightsFacebookCreativeType } from '@magicbrief/common';
import { InsightsAdFacebookAssetsList } from '@magicbrief/server/src/insights/types';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import { Button, Text } from 'react-aria-components';
import checkerboard from 'src/assets/imgs/checker.jpeg';
import ChevronLeft from 'src/assets/svgicons/duocolor/chevron-left.svg';
import ChevronRight from 'src/assets/svgicons/duocolor/chevron-right.svg';
import PlayCircle from 'src/assets/svgicons/solid/play-circle.svg';
import { Icon } from 'src/components/Icon';
import Image from 'src/components/Image/Image';
import { cn } from 'src/lib/cn';
import {
  hasInsightsAdPreviewAtom,
  insightsAdPreviewAtom,
} from '../../Insights.atoms';
import { FacebookVideoIframe } from './components/FacebookVideoIframe';
import InsightsMediaVideo from './components/InsightsMediaVideo';

const NoPreviewAvailable: FC<{ showText?: boolean }> = ({
  showText = true,
}) => {
  return (
    <div className="flex aspect-square items-center justify-center rounded-md bg-purple-50 p-4">
      {showText && (
        <Text className="text-center text-sm font-semibold text-purple-800">
          No preview available
        </Text>
      )}
    </div>
  );
};

const AssetMedia: FC<{
  asset: ArrayElement<InsightsAdFacebookAssetsList>;
  display: 'full' | 'grid' | 'table';
  mediaId: string;
  className?: string;
  showControls?: boolean;
  openAdPreview: () => void;
  isPreview?: boolean;
}> = ({
  asset,
  display,
  mediaId,
  className,
  showControls,
  openAdPreview,
  isPreview = false,
}) => {
  const url = asset.cachedUrl || asset.url;

  const thumbnailUrl =
    asset.cachedThumbnailUrl ||
    asset.thumbnailUrl ||
    asset.cachedUrl ||
    asset.url;

  const isThumbnailAvailable = thumbnailUrl != null;

  const isImage = asset.mediaType === 'image' && url != null;

  const isCachedVideo = asset.mediaType === 'video' && url != null;

  const isIframeVideo =
    asset.mediaType === 'video' && url == null && asset.embedHtml != null;

  if (!isImage && !isCachedVideo && !isIframeVideo && !isThumbnailAvailable) {
    return <NoPreviewAvailable showText={showControls} />;
  }

  return (
    <div
      className={classNames(
        className,
        'flex-centered relative flex overflow-hidden rounded-md',
        isPreview ? 'aspect-square' : ''
      )}
    >
      {isPreview ? (
        <Button
          className="group/thumbnail relative bg-gray-50 hover:cursor-zoom-in focus:outline-none"
          onPress={openAdPreview}
        >
          {(isCachedVideo || isIframeVideo) && display === 'grid' && (
            <Icon
              className={cn(
                'absolute left-1/2 top-1/2 z-[1] size-14 -translate-x-1/2 -translate-y-1/2 rounded-full text-white drop-shadow-lg transition-all duration-300',
                'group-hover/thumbnail:bg-white group-hover/thumbnail:text-primary'
              )}
            >
              <PlayCircle className="size-14" />
            </Icon>
          )}

          <Image
            className="w-full"
            src={
              asset.cachedThumbnailUrl ??
              asset.thumbnailUrl ??
              (asset.mediaType === 'image'
                ? asset.cachedUrl ?? asset.url
                : null) ??
              ''
            }
            fallbackSrc={asset.thumbnailUrl ?? ''}
            alt="thumbnail"
          />
        </Button>
      ) : (
        <>
          {isImage && isThumbnailAvailable ? (
            <Image
              id={mediaId}
              className="w-full"
              src={thumbnailUrl}
              fallbackSrc={url}
              alt=""
            />
          ) : isCachedVideo && isThumbnailAvailable ? (
            <InsightsMediaVideo
              id={mediaId}
              poster={thumbnailUrl}
              sources={[
                {
                  type: 'video/mp4',
                  src: url,
                },
              ]}
              shouldShowControls={showControls}
            />
          ) : isIframeVideo ? (
            showControls &&
            asset.embedHtml &&
            ['full', 'grid'].includes(display) ? (
              <FacebookVideoIframe
                id={mediaId}
                className="z-2 relative"
                html={asset.embedHtml}
                height={null}
                width={null}
              />
            ) : isThumbnailAvailable ? (
              <Image
                id={mediaId}
                className="w-full"
                src={thumbnailUrl}
                fallbackSrc={url ?? undefined}
                alt=""
              />
            ) : null
          ) : null}
        </>
      )}
    </div>
  );
};

const AssetMediaCarousel: FC<{
  assets: InsightsAdFacebookAssetsList;
  display: 'full' | 'grid' | 'table';
  mediaId: string;
  className?: string;
  showControls?: boolean;
  openAdPreview: () => void;
  isPreview?: boolean;
}> = ({
  assets,
  display,
  mediaId,
  className,
  showControls,
  openAdPreview,
  isPreview = false,
}) => {
  const [index, setIndex] = useState(0);

  const sortedAssets = assets.sort((a, b) => {
    return (a.carouselOrder ?? 0) - (b.carouselOrder ?? 0);
  });

  const next = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    if (index === sortedAssets.length - 1) {
      return;
    }
    setIndex((current) => current + 1);
  };

  const prev = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    if (index === 0) {
      return;
    }
    setIndex((current) => current - 1);
  };

  const asset = sortedAssets[index];

  if (display === 'table') {
    return (
      <AssetMedia
        asset={asset}
        className={className}
        display={display}
        showControls={showControls}
        mediaId={mediaId}
        openAdPreview={openAdPreview}
        isPreview={isPreview}
      />
    );
  }

  return (
    <div className="relative">
      <div className="flex items-center">
        {index < sortedAssets.length - 1 && (
          <button
            className="absolute right-1 z-10 flex size-6 cursor-pointer select-none appearance-none items-center justify-center rounded-md bg-white shadow"
            onClick={next}
          >
            <Icon className="text-primary">
              <ChevronRight />
            </Icon>
          </button>
        )}
        <AssetMedia
          asset={asset}
          className={className}
          display={display}
          showControls={showControls}
          mediaId={mediaId}
          openAdPreview={openAdPreview}
          isPreview={isPreview}
        />
        {index !== 0 && (
          <button
            className="absolute left-1 z-10 flex size-6 cursor-pointer select-none appearance-none items-center justify-center rounded-md bg-white shadow"
            onClick={prev}
          >
            <Icon className="text-primary">
              <ChevronLeft />
            </Icon>
          </button>
        )}
      </div>
    </div>
  );
};

type Props = {
  display: 'full' | 'grid' | 'table';
  mediaId: string;
  adUuid?: string | null;
  creativeType?: InsightsFacebookCreativeType;
  assets?: InsightsAdFacebookAssetsList;
  className?: string;
  showControls?: boolean;
  isPreview?: boolean;
};

export const InsightsMedia: FC<Props> = ({
  display,
  mediaId,
  adUuid,
  creativeType,
  assets = [],
  className,
  showControls = true,
  isPreview = false,
}) => {
  const setInsightsAdPreview = useSetAtom(insightsAdPreviewAtom);
  const hasInsightsAdPreview = useAtomValue(hasInsightsAdPreviewAtom);

  const handleOpenAdPreview = async () => {
    if (hasInsightsAdPreview) {
      /**
       * If the user is already previewing another ad, this ensures the newly selected ad
       * is rendered as the video element remains mounted in memory even if the underlying ad is changed.
       *
       * The recommendation from the HTML Living Standard is to release the resource by
       * resetting the media element's src attribute.
       *
       * @link https://html.spec.whatwg.org/multipage/media.html#best-practices-for-authors-using-media-elements
       */
      const videoPreviewEl = document.getElementById(
        'insights-video-preview'
      ) as HTMLVideoElement;

      if (videoPreviewEl != null) {
        videoPreviewEl?.pause();
        videoPreviewEl?.removeAttribute('src');
        videoPreviewEl?.load();
      }

      setInsightsAdPreview({ assets, uuid: adUuid ?? '', creativeType });
    } else {
      setInsightsAdPreview({ assets, uuid: adUuid ?? '', creativeType });
    }
  };

  if (!assets[0]) {
    return (
      <MediaPlaceholder
        className={cn(
          display === 'table' ? 'min-h-8 min-w-8' : '',
          display === 'grid' ? 'aspect-square h-auto w-full' : '',
          display === 'full' ? 'size-[528px]' : ''
        )}
      />
    );
  }

  if (creativeType === 'carousel') {
    return (
      <AssetMediaCarousel
        assets={assets}
        className={className}
        display={display}
        showControls={showControls}
        mediaId={mediaId}
        openAdPreview={handleOpenAdPreview}
        isPreview={isPreview}
      />
    );
  }

  return (
    <AssetMedia
      asset={assets[0]}
      className={className}
      display={display}
      showControls={showControls}
      mediaId={mediaId}
      openAdPreview={handleOpenAdPreview}
      isPreview={isPreview}
    />
  );
};

function MediaPlaceholder({
  className,
  ...props
}: Omit<React.HTMLAttributes<HTMLDivElement>, 'style'>) {
  return (
    <div
      className={cn('rounded-md', className)}
      style={{ backgroundImage: `url(${checkerboard})` }}
      {...props}
    />
  );
}
