import React, {
  forwardRef,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { ArrayElement } from '@magicbrief/common';
import {
  ReportHierarchyItemFolder,
  ReportHierarchyItemReport,
} from '@magicbrief/server/src/insights/types';
import {
  Button,
  Header,
  Heading,
  Menu,
  MenuTrigger,
  Section,
  Text,
} from 'react-aria-components';
import { createPortal } from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Facebook from 'src/assets/svgicons/brands/Facebook.svg';
import TikTok from 'src/assets/svgicons/brands/TikTok.svg';
import FolderReport from 'src/assets/svgicons/custom/FolderReport.svg';
import FolderReportCompare from 'src/assets/svgicons/custom/FolderReportCompare.svg';
import FolderPlus from 'src/assets/svgicons/duocolor/folder-plus.svg';
import Plus from 'src/assets/svgicons/duocolor/plus.svg';
import Target05 from 'src/assets/svgicons/duocolor/target-05.svg';
import Trash01 from 'src/assets/svgicons/duocolor/trash-01.svg';
import Alert from 'src/components/AlertV2/Alert';
import { FacebookAdAccountSelectModal } from 'src/components/FacebookConnect/FacebookAdAccountSelectModal';
import { Icon } from 'src/components/Icon';
import Input from 'src/components/Input';
import Avatar from 'src/components/Misc/Avatar';
import { UpgradeModalV2 } from 'src/components/Modals/UpgradeModalV2/UpgradeModalV2';
import { Popover, PopoverDialog } from 'src/components/Popover/AriaPopover';
import Sidebar from 'src/components/Sidebar/Sidebar';
import { SquareLoaders } from 'src/components/SquareLoaders';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import { trpc } from 'src/lib/trpc';
import { showNewMessage } from 'src/utils/intercom';
import { useEntitlementQuotas } from 'src/utils/useEntitlementQuotas';
import { useFacebook } from 'src/utils/useFacebook';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import Home03 from 'src/assets/svgicons/line/home-03.svg';
import BarChart10 from 'src/assets/svgicons/duotone/bar-chart-10.svg';
import { SlackGradient } from '../../../assets/svgicons/brands';
import CompareArrows from '../../../assets/svgicons/custom/CompareArrows.svg';
import Copy01 from '../../../assets/svgicons/duocolor/copy-01.svg';
import DotsHorizontal from '../../../assets/svgicons/duocolor/dots-horizontal.svg';
import Edit01 from '../../../assets/svgicons/duocolor/edit-01.svg';
import Settings01 from '../../../assets/svgicons/duocolor/settings-01.svg';
import AlertCircle from '../../../assets/svgicons/duotone/alert-circle.svg';
import ChevronRight from '../../../assets/svgicons/line/chevron-right.svg';
import ChevronSelectorVertical from '../../../assets/svgicons/line/chevron-selector-vertical.svg';
import { AriaMenu } from '../../../components/AriaMenu/AriaMenu';
import { AriaButton } from '../../../components/Button/Button';
import {
  GetManyAdAccountsResponse,
  GetManyInsightsReportsAndComparisonReportsResponse,
} from '../../../types/insights';
import useFeatureFlag from '../../../utils/useFeatureFlag';
import { useUserAndOrganisation } from '../../../utils/useUserAndOrganisation';
import { computeUrlParamsForReport } from '../util/computeUrlParamsForReport';
import { useInsightsAdAccount } from '../util/useInsightsQueries';
import DeleteReportModal from './DeleteReportModal';
import DuplicateReportModal from './DuplicateReportModal';
import ReconnectRequiredModal from './ReconnectRequiredModal';
import RenameReportModal from './RenameReportModal';

type ReportModalState = {
  report: Pick<
    ArrayElement<GetManyInsightsReportsAndComparisonReportsResponse>,
    'uuid' | 'name' | 'type'
  > | null;
  modal: 'rename' | 'duplicate' | 'delete';
};

const InsightsSidebar: React.FC = () => {
  const { accountUuid } = useParams();
  const {
    authenticate,
    authState: facebookAuthState,
    setAuthState: setFacebookAuthState,
  } = useFacebook();
  const { LL } = useI18nContext();
  const trpcUtils = trpc.useUtils();
  const user = useUserAndOrganisation();
  const slackEnabled = useFeatureFlag('SLACK_CONNECT_ENABLED');
  const copilotEnabled = useFeatureFlag('INSIGHTS_COPILOT_ENABLED');
  const compareEnabled = useFeatureFlag('INSIGHTS_COMPARE_GROUPS_ENABLED');
  const dashboardEnabled = useFeatureFlag('INSIGHTS_DASHBOARD_ENABLED');

  const [reportModal, setReportModal] = useState<ReportModalState | null>(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showReconnectRequiredModal, setShowReconnectRequiredModal] =
    useState<boolean>(false);
  const hasReconnectModalDisplay = useRef(false);

  const { adAccount } = useInsightsAdAccount({
    accountUuid,
  });

  const adAccounts = trpc.insightsAccounts.getManyAdAccounts.useQuery();

  const currentAdAccount = adAccounts?.data?.find(
    (adAccount) => adAccount.uuid === accountUuid
  );

  const getPermissionsForEntityQuery =
    trpc.permissions.getPermissionsForEntity.useQuery(
      {
        entityType: 'Organisation',
        entityUuid: user.data?.organisation.uuid ?? '',
      },
      {
        enabled:
          !!adAccount.data &&
          adAccount.data.config?.status === 'enabled' &&
          !!adAccount.data.userUuidWhoConnectedAccount,
      }
    );

  const permissionForUserWhoConnectedAccount =
    getPermissionsForEntityQuery.data?.direct?.find(
      (permission) =>
        permission.metadata != null &&
        permission.ownerType === 'User' &&
        adAccount.data != null &&
        permission.ownerUuid === adAccount.data.userUuidWhoConnectedAccount
    );
  const userUuid = permissionForUserWhoConnectedAccount?.ownerUuid ?? undefined;
  const userName =
    permissionForUserWhoConnectedAccount?.ownerType === 'User'
      ? permissionForUserWhoConnectedAccount?.metadata.name ?? undefined
      : undefined;
  const userEmail =
    permissionForUserWhoConnectedAccount?.ownerType === 'User'
      ? permissionForUserWhoConnectedAccount.metadata.email ?? undefined
      : undefined;
  const userProfilePictureUrl =
    permissionForUserWhoConnectedAccount?.ownerType === 'User'
      ? permissionForUserWhoConnectedAccount.metadata.profilePicURL ?? undefined
      : undefined;

  useEffect(() => {
    setShowReconnectRequiredModal(false);
    if (
      adAccount.data != null &&
      adAccount.data.config?.status === 'disabled' &&
      hasReconnectModalDisplay.current === false
    ) {
      setShowReconnectRequiredModal(true);
      // we only want to show this once even throughout multiple refreshes
      hasReconnectModalDisplay.current = true;
    }
  }, [adAccount]);

  const onReconnectRequiredModalClose = (
    event?: 'contact-support' | 'connect-accounts'
  ) => {
    setShowReconnectRequiredModal(false);
    if (event === 'contact-support') {
      showNewMessage({
        message: `Insights Ad Account Disabled Request\nEmail: <INSERT HERE>\nFeedback: <INSERT HERE>\n`,
      });
    } else if (event === 'connect-accounts') {
      void authenticate();
    }
  };

  const adAccountsExist =
    !adAccounts.isLoading && adAccounts.data && adAccounts.data?.length > 0;

  return (
    <>
      <Sidebar
        side="left"
        className="flex h-full w-full flex-col justify-between gap-6 overflow-y-auto bg-white p-5"
      >
        <Sidebar.Content className="min-h-0">
          <Sidebar.Section
            title={`${LL.insights.sidebar.facebook.title()} (${
              adAccounts.data?.length ?? 0
            })`}
          >
            {adAccount.isFetching || adAccounts.isFetching ? (
              <SquareLoaders amount={5} className="h-11" />
            ) : (
              <>
                {!adAccountsExist && (
                  <div className="text-xs text-purple-800/40">
                    {LL.insights.sidebar.facebook.noAccounts()}
                  </div>
                )}

                {adAccountsExist && currentAdAccount && (
                  <>
                    <div className="pb-2">
                      <InsightsSidebarAccountMenu
                        setShowUpgradeModal={setShowUpgradeModal}
                        currentAdAccount={currentAdAccount}
                        adAccounts={adAccounts.data}
                        isLoading={
                          adAccount.isFetching || adAccounts.isFetching
                        }
                      />
                    </div>

                    {dashboardEnabled && (
                      <Sidebar.NavLink
                        end
                        to={`/insights/accounts/${currentAdAccount.uuid}`}
                      >
                        <Icon>
                          <Home03 />
                        </Icon>
                        Home
                      </Sidebar.NavLink>
                    )}

                    <Sidebar.NavLink
                      end
                      to={
                        dashboardEnabled
                          ? `/insights/accounts/${currentAdAccount.uuid}/overview`
                          : `/insights/accounts/${currentAdAccount.uuid}`
                      }
                    >
                      <Icon>
                        <BarChart10 />
                      </Icon>
                      {LL.insights.sidebar.facebook.overview()}
                    </Sidebar.NavLink>

                    {compareEnabled &&
                      currentAdAccount?.platform === 'facebook' && (
                        <Sidebar.NavLink
                          to={`/insights/accounts/${currentAdAccount.uuid}/compare`}
                        >
                          <Icon>
                            <CompareArrows />
                          </Icon>
                          {LL.insights.sidebar.facebook.compare()}
                        </Sidebar.NavLink>
                      )}

                    {copilotEnabled &&
                      currentAdAccount?.platform === 'facebook' && (
                        <Sidebar.NavLink
                          to={`/insights/accounts/${currentAdAccount.uuid}/analysis`}
                        >
                          <Icon>
                            <Target05 />
                          </Icon>
                          {LL.insights.sidebar.facebook.analysis()}
                        </Sidebar.NavLink>
                      )}
                  </>
                )}
              </>
            )}
          </Sidebar.Section>

          {accountUuid && currentAdAccount?.platform === 'facebook' && (
            <InsightsSidebarReports setReportModal={setReportModal} />
          )}

          {slackEnabled &&
            currentAdAccount &&
            currentAdAccount?.platform === 'facebook' && (
              <Sidebar.Section title="Manage">
                <Sidebar.NavLink
                  data-intercom-target="insight_slack"
                  to={`/insights/accounts/${currentAdAccount.uuid}/schedules`}
                >
                  <div className="flex w-full flex-row items-center justify-between gap-2">
                    <span className="flex flex-row items-center gap-2 text-sm font-semibold text-primary">
                      <Icon className="h-5 w-5">
                        <SlackGradient />
                      </Icon>
                      <span>
                        {LL.insights.summarySchedules.sidebar.navLinkPrimaryText()}
                      </span>
                    </span>
                  </div>
                </Sidebar.NavLink>

                <Sidebar.NavLink
                  to={`/insights/accounts/${currentAdAccount.uuid}/settings`}
                >
                  <div className="flex w-full flex-row items-center justify-between gap-2">
                    <span className="flex flex-row items-center gap-2 text-sm font-semibold text-primary">
                      <Icon className="h-5 w-5">
                        <Settings01 />
                      </Icon>
                      <span>Account & Data Settings</span>
                    </span>
                  </div>
                </Sidebar.NavLink>
              </Sidebar.Section>
            )}
        </Sidebar.Content>
      </Sidebar>

      {reportModal?.report && reportModal.report.type !== 'folder' && (
        <RenameReportModal
          reportUuid={reportModal.report.uuid}
          reportName={reportModal.report.name}
          reportType={reportModal.report.type}
          show={reportModal.modal === 'rename'}
          onClose={() => setReportModal(null)}
        />
      )}
      {reportModal?.report && reportModal.report.type !== 'folder' && (
        <DuplicateReportModal
          reportUuid={reportModal.report.uuid}
          reportName={reportModal.report.name}
          reportType={reportModal.report.type}
          show={reportModal.modal === 'duplicate'}
          onClose={() => setReportModal(null)}
        />
      )}
      {reportModal?.report && reportModal.report.type !== 'folder' && (
        <DeleteReportModal
          reportUuid={reportModal.report.uuid}
          reportName={reportModal.report.name}
          reportType={reportModal.report.type}
          show={reportModal.modal === 'delete'}
          onClose={() => setReportModal(null)}
        />
      )}
      {showReconnectRequiredModal && (
        <ReconnectRequiredModal
          show={showReconnectRequiredModal}
          onClose={onReconnectRequiredModalClose}
          userUuid={userUuid}
          userName={userName}
          userEmail={userEmail}
          userProfilePictureUrl={userProfilePictureUrl}
        />
      )}
      {user.data &&
        adAccount.data != null &&
        adAccount.data.config?.status === 'disabled' &&
        facebookAuthState?.status !== 'init' && (
          <FacebookAdAccountSelectModal
            facebookAuthState={facebookAuthState}
            onClose={() => {
              setFacebookAuthState({ status: 'init' });
              void trpcUtils.user.getUserAndOrganisation.invalidate();
              setTimeout(() => {
                void adAccounts.refetch();
              }, 3000);
            }}
          />
        )}
      <UpgradeModalV2
        isOpen={showUpgradeModal}
        close={() => setShowUpgradeModal(false)}
        prompt="to unlock insights"
      />
    </>
  );
};

export default InsightsSidebar;

interface InsightsSidebarReportsProps {
  setReportModal: React.Dispatch<React.SetStateAction<ReportModalState | null>>;
}

const REPORT_SORT_ORDER = [
  'az',
  'za',
  'createdFirst',
  'createdLast',
  'modifiedFirst',
] as const;

type ReportSortOrder = ArrayElement<typeof REPORT_SORT_ORDER>;

const InsightsSidebarReports = ({
  setReportModal,
}: InsightsSidebarReportsProps) => {
  const { accountUuid, reportUuid } = useParams();
  const user = useUserAndOrganisation();
  const trpcUtils = trpc.useUtils();
  const reports =
    trpc.insightsReports.getManyInsightsReportsAndComparisonReports.useQuery(
      { insightsAdAccountFacebookUuid: accountUuid },
      { enabled: !!user.data && !!accountUuid }
    );

  const moveReportToFolder =
    trpc.insightsReports.moveReportToFolder.useMutation({
      onSuccess() {
        void trpcUtils.insightsReports.getManyInsightsReportsAndComparisonReports.invalidate();
      },
    });

  const [sort, setSort] = useState<ReportSortOrder>('createdLast');

  const sortedReports = reports.data?.sort((a, b) => {
    switch (sort) {
      case 'createdFirst':
        return b.createdAt.getTime() - a.createdAt.getTime();
      case 'createdLast':
        return a.createdAt.getTime() - b.createdAt.getTime();
      case 'modifiedFirst':
        return b.updatedAt.getTime() - a.updatedAt.getTime();
      case 'az':
        return a.name.localeCompare(b.name);
      case 'za':
        return b.name.localeCompare(a.name);
    }
  });

  const hasNoReports =
    !reports.isLoading && (reports.data == null || reports.data?.length === 0);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {})
  );

  const [draggingReport, setDraggingReport] =
    useState<ReportHierarchyItemReport | null>(null);

  const onChangeSort = (value: ReportSortOrder) => setSort(value);

  if (reports.isLoading) {
    return (
      <InsightsSidebarReportsSection sort={sort} onChangeSort={onChangeSort}>
        <SquareLoaders amount={5} className="h-11" />
      </InsightsSidebarReportsSection>
    );
  }

  if (hasNoReports) {
    return (
      <InsightsSidebarReportsSection sort={sort} onChangeSort={onChangeSort}>
        <div className="text-xs text-purple-800/40">No reports</div>
      </InsightsSidebarReportsSection>
    );
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={(event) => {
        if (event.active.data.current) {
          setDraggingReport(
            event.active.data.current as ReportHierarchyItemReport
          );
        }
      }}
      onDragEnd={(event) => {
        if (event.over && event.over.id && event.active.data.current) {
          moveReportToFolder.mutate({
            reportType: event.active.data.current.type,
            reportUuid: event.active.data.current.uuid,
            destinationFolderUuid:
              event.over.id === '--none--' ? null : (event.over.id as string),
          });
        }
        setDraggingReport(null);
      }}
    >
      <InsightsSidebarReportsSection sort={sort} onChangeSort={onChangeSort}>
        <div className="flex flex-col gap-1 overflow-y-auto">
          {sortedReports?.map((report) => {
            if (report.type === 'folder') {
              return (
                <InsightsSidebarReportsItemFolder
                  setReportModal={setReportModal}
                  key={`${report.type}-${report.uuid}`}
                  folder={report}
                  activeReportUuid={reportUuid}
                  accountUuid={accountUuid ?? ''}
                />
              );
            }

            const active = reportUuid === report.uuid;
            return (
              <DraggableInsightsSidebarReportsItemReport
                setReportModal={setReportModal}
                report={report}
                active={active}
                key={`${report.type}-${report.uuid}`}
                accountUuid={accountUuid ?? ''}
              />
            );
          })}
        </div>
      </InsightsSidebarReportsSection>
      {createPortal(
        <DragOverlay zIndex={1}>
          {!!draggingReport && (
            <Sidebar.Button>
              <InsightsSidebarReportsItemReportContents
                report={draggingReport}
              />
            </Sidebar.Button>
          )}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};

type InsightsSidebarReportsItemReportProps = {
  accountUuid: string;
  report: ReportHierarchyItemReport;
  active: boolean;
  setReportModal: React.Dispatch<React.SetStateAction<ReportModalState | null>>;
};

const DraggableInsightsSidebarReportsItemReport: React.FC<
  InsightsSidebarReportsItemReportProps
> = (props) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: `${props.report.type}-${props.report.uuid}`,
      data: props.report,
    });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: 1,
        opacity: 0.5,
      }
    : undefined;

  if (isDragging) {
    return (
      <Sidebar.Button
        {...props}
        {...attributes}
        {...listeners}
        ref={setNodeRef}
        style={style}
        className="min-w-0 touch-manipulation select-none webkit-touch-callout-none"
      >
        <InsightsSidebarReportsItemReportContents report={props.report} />
      </Sidebar.Button>
    );
  }

  return (
    <InsightsSidebarReportsItemReport
      {...props}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
      className="min-w-0 touch-manipulation select-none webkit-touch-callout-none"
    />
  );
};

const InsightsSidebarReportsItemReport = forwardRef<
  HTMLElement,
  InsightsSidebarReportsItemReportProps & HTMLAttributes<HTMLDivElement>
>(({ report, active, accountUuid, setReportModal, ...rest }, ref) => {
  const { LL } = useI18nContext();

  const params =
    report.type === 'report'
      ? computeUrlParamsForReport(
          report.config?.filter.forTimePeriod,
          report.config?.attributionWindow
        )
      : computeUrlParamsForReport(
          report.timePeriod,
          report.attributionWindow as 'default' | 'custom' | null
        );

  return (
    <Sidebar.InteractiveNavItem
      {...rest}
      ref={ref}
      isActive={active}
      to={{
        pathname: `/insights/accounts/${accountUuid}/${
          report.type === 'report' ? 'reports' : 'comparison-reports'
        }/${report.uuid}`,
        search: params.size > 0 ? `?${params.toString()}` : undefined,
      }}
      actions={
        <div className="invisible group-hover:visible group-focus:visible">
          <AriaMenu>
            <Button className="group/report-options flex items-center justify-center rounded outline-none">
              <span className="flex h-5 w-5 items-center justify-center rounded bg-none text-text-secondary group-hover/report-options:bg-purple-200">
                <Icon className="h-4 w-4">
                  <DotsHorizontal />
                </Icon>
              </span>
            </Button>
            <AriaMenu.List
              onAction={(key) => {
                if (key === 'rename') {
                  setReportModal({ report, modal: 'rename' });
                } else if (key === 'duplicate') {
                  setReportModal({ report, modal: 'duplicate' });
                } else if (key === 'delete') {
                  setReportModal({ report, modal: 'delete' });
                }
              }}
            >
              <AriaMenu.Item
                id="rename"
                icon={
                  <Icon>
                    <Edit01 />
                  </Icon>
                }
              >
                {LL.rename()}
              </AriaMenu.Item>
              <AriaMenu.Item
                id="duplicate"
                icon={
                  <Icon>
                    <Copy01 />
                  </Icon>
                }
              >
                {LL.duplicate()}
              </AriaMenu.Item>
              <AriaMenu.Item
                id="delete"
                icon={
                  <Icon>
                    <Trash01 />
                  </Icon>
                }
                danger={true}
              >
                {LL.delete()}
              </AriaMenu.Item>
            </AriaMenu.List>
          </AriaMenu>
        </div>
      }
    >
      <InsightsSidebarReportsItemReportContents report={report} />
    </Sidebar.InteractiveNavItem>
  );
});

const InsightsSidebarReportsItemReportContents: React.FC<
  Pick<InsightsSidebarReportsItemReportProps, 'report'>
> = ({ report }) => {
  return (
    <div className="flex w-full flex-row items-center justify-between gap-2.5">
      <div className="flex h-full w-full flex-row items-center gap-2 overflow-hidden">
        <Icon className="flex-centered flex h-6 w-6 shrink-0 rounded-lg bg-purple-800/10 group-disabled:stroke-purple-200">
          {report.type === 'report' ? (
            <FolderReport />
          ) : (
            <FolderReportCompare />
          )}
        </Icon>
        <span className="truncate">{report.name}</span>
      </div>
    </div>
  );
};

InsightsSidebarReportsItemReport.displayName =
  'InsightsSidebarReportsItemReport';

const InsightsSidebarReportsItemFolder: React.FC<{
  folder: ReportHierarchyItemFolder;
  accountUuid: string;
  activeReportUuid: string | undefined;
  setReportModal: React.Dispatch<React.SetStateAction<ReportModalState | null>>;
}> = ({ folder, accountUuid, activeReportUuid, setReportModal }) => {
  const { LL } = useI18nContext();
  const [isOpen, setIsOpen] = useState(false);

  const { isOver, setNodeRef } = useDroppable({
    id: folder.uuid,
  });

  const actionButtonRef = useRef<HTMLButtonElement | null>(null);

  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const [showRenamePopover, setShowRenamePopover] = useState(false);

  const trpcUtils = trpc.useUtils();
  const deleteFolder = trpc.insightsReports.deleteReportFolder.useMutation({
    onSuccess() {
      setShowDeletePopover(false);
      void trpcUtils.insightsReports.getManyInsightsReportsAndComparisonReports.invalidate();
    },
  });
  const updateFolder = trpc.insightsReports.updateReportFolder.useMutation({
    onSuccess() {
      setShowRenamePopover(false);
      void trpcUtils.insightsReports.getManyInsightsReportsAndComparisonReports.invalidate();
    },
  });

  const renameInputRef = useRef<HTMLInputElement | null>(null);

  const [error, setError] = useState<string | null>(null);

  return (
    <div
      ref={setNodeRef}
      className={cn(
        'flex shrink-0 flex-col gap-1',
        isOver &&
          'z-0 rounded-lg bg-purple-300 outline outline-2 -outline-offset-2 outline-purple-500'
      )}
    >
      <Sidebar.ButtonWithActions
        onClick={() => setIsOpen((s) => !s)}
        actions={
          <>
            <div className="invisible group-hover:visible group-focus:visible">
              <AriaMenu>
                <Button
                  className="group/report-options flex items-center justify-center rounded outline-none"
                  ref={actionButtonRef}
                >
                  <span className="flex h-5 w-5 items-center justify-center rounded bg-none text-text-secondary group-hover/report-options:bg-purple-200">
                    <Icon className="h-4 w-4">
                      <DotsHorizontal />
                    </Icon>
                  </span>
                </Button>
                <AriaMenu.List
                  onAction={(key) => {
                    if (key === 'rename') {
                      setShowRenamePopover(true);
                    } else if (key === 'delete') {
                      setShowDeletePopover(true);
                    }
                  }}
                >
                  <AriaMenu.Item
                    id="rename"
                    icon={
                      <Icon>
                        <Edit01 />
                      </Icon>
                    }
                  >
                    {LL.rename()}
                  </AriaMenu.Item>
                  <AriaMenu.Item
                    id="delete"
                    icon={
                      <Icon>
                        <Trash01 />
                      </Icon>
                    }
                    danger={true}
                  >
                    {LL.delete()}
                  </AriaMenu.Item>
                </AriaMenu.List>
              </AriaMenu>
            </div>
            <Popover
              triggerRef={actionButtonRef}
              isOpen={showDeletePopover}
              onOpenChange={(open) => {
                if (!open) {
                  setShowDeletePopover(false);
                }
              }}
            >
              <PopoverDialog className="flex w-64 flex-col gap-3 p-2">
                <div className="flex flex-col gap-2">
                  <Heading className="text-sm font-semibold text-primary">
                    {LL.deleteWithName({ name: folder.name })}
                  </Heading>

                  <p className="text-xs text-primary/50">
                    {LL.insights.sidebar.reports.deleteFolderWarning()}
                  </p>

                  {!!error && <p className="text-xs text-danger">{error}</p>}
                </div>
                <div className="flex flex-1 flex-row justify-end gap-2">
                  <AriaButton
                    size="smallest"
                    variant="secondary"
                    onPress={() => setShowDeletePopover(false)}
                  >
                    {LL.cancel()}
                  </AriaButton>
                  <AriaButton
                    loading={deleteFolder.isLoading}
                    onPress={() => deleteFolder.mutate({ uuid: folder.uuid })}
                    size="smallest"
                    variant="secondary"
                    colour="danger"
                  >
                    {LL.delete()}
                  </AriaButton>
                </div>
              </PopoverDialog>
            </Popover>

            <Popover
              triggerRef={actionButtonRef}
              isOpen={showRenamePopover}
              onOpenChange={(open) => {
                if (!open) {
                  setShowRenamePopover(false);
                }
              }}
            >
              <PopoverDialog className="flex w-64 flex-col p-2">
                <form
                  className="flex flex-col gap-3"
                  onSubmit={(ev) => {
                    ev.preventDefault();
                    if (renameInputRef.current) {
                      if (!renameInputRef.current.value) {
                        setError(LL.errors.fieldRequired());
                      } else {
                        updateFolder.mutate({
                          uuid: folder.uuid,
                          name: renameInputRef.current.value,
                        });
                      }
                    }
                  }}
                >
                  <div className="flex flex-col gap-2">
                    <Heading className="text-sm font-semibold text-primary">
                      {LL.renameWithName({ name: folder.name })}
                    </Heading>

                    <Input
                      ref={renameInputRef}
                      name={`${folder.uuid}-folder-name`}
                      defaultValue={folder.name}
                    />

                    {!!error && <p className="text-xs text-danger">{error}</p>}
                  </div>
                  <div className="flex flex-1 flex-row justify-end gap-2">
                    <AriaButton
                      size="smallest"
                      variant="secondary"
                      onPress={() => setShowRenamePopover(false)}
                    >
                      {LL.cancel()}
                    </AriaButton>
                    <AriaButton
                      loading={updateFolder.isLoading}
                      htmlType="submit"
                      size="smallest"
                      variant="primary"
                      colour="primary"
                    >
                      {LL.save()}
                    </AriaButton>
                  </div>
                </form>
              </PopoverDialog>
            </Popover>
          </>
        }
      >
        <Icon
          className={cn(
            isOpen ? 'rotate-90' : 'rotate-0',
            'shrink-0 transition-transform'
          )}
        >
          <ChevronRight />
        </Icon>
        <span className="truncate">{folder.name}</span>
      </Sidebar.ButtonWithActions>
      {isOpen && (
        <div className="flex flex-col gap-1 pl-3">
          {folder.contents?.map((x) => (
            <DraggableInsightsSidebarReportsItemReport
              report={x}
              active={activeReportUuid === x.uuid}
              key={`${x.type}-${x.uuid}`}
              accountUuid={accountUuid ?? ''}
              setReportModal={setReportModal}
            />
          ))}
        </div>
      )}
    </div>
  );
};

type InsightsSidebarReportsSectionProps = React.PropsWithChildren<{
  onChangeSort: (sort: ReportSortOrder) => void;
  sort: ReportSortOrder;
}>;

const InsightsSidebarReportsSection: React.FC<
  InsightsSidebarReportsSectionProps
> = ({ children, onChangeSort, sort }) => {
  const { LL } = useI18nContext();
  const { accountUuid } = useParams();

  const { isOver, setNodeRef } = useDroppable({
    id: '--none--',
  });

  const [showCreateFolderPopover, setShowCreateFolderPopover] = useState(false);
  const actionButtonRef = useRef<HTMLButtonElement | null>(null);
  const nameReportRef = useRef<HTMLInputElement | null>(null);

  const trpcUtils = trpc.useUtils();
  const createFolder = trpc.insightsReports.createReportFolder.useMutation({
    onSuccess() {
      void trpcUtils.insightsReports.getManyInsightsReportsAndComparisonReports.invalidate();
      setShowCreateFolderPopover(false);
      toast.success('Folder created', { className: 'toast-success' });
    },
  });

  const [error, setError] = useState<string | null>(null);

  return (
    <>
      <Sidebar.Section
        ref={setNodeRef}
        title={
          <span className="flex flex-row justify-between">
            <span>{LL.insights.sidebar.reports.title()}</span>
            <div className="flex flex-row gap-1">
              <AriaMenu>
                <Button className="group/report-options flex items-center justify-center rounded outline-none">
                  <span className="flex h-5 w-5 items-center justify-center rounded bg-none text-text-secondary group-hover/report-options:bg-purple-200">
                    <Icon className="h-4 w-4">
                      <DotsHorizontal />
                    </Icon>
                  </span>
                </Button>
                <AriaMenu.List
                  selectedKeys={[sort]}
                  selectionMode="single"
                  onAction={(key) => {
                    onChangeSort(key as ReportSortOrder);
                  }}
                >
                  <Section className={cn('flex flex-1 flex-col')}>
                    <Header className="flex flex-row items-center gap-2 px-3 py-1.5 text-xs font-semibold text-purple-800/50">
                      <span>{LL.sortBy()}</span>
                    </Header>
                    {REPORT_SORT_ORDER.map((x) => (
                      <AriaMenu.Item key={x} id={x}>
                        {LL.insights.sidebar.reports.sort[x]()}
                      </AriaMenu.Item>
                    ))}
                  </Section>
                </AriaMenu.List>
              </AriaMenu>
              <AriaMenu>
                <Button
                  ref={actionButtonRef}
                  className="group/report-options flex items-center justify-center rounded outline-none"
                >
                  <span className="flex h-5 w-5 items-center justify-center rounded bg-none text-text-secondary group-hover/report-options:bg-purple-200">
                    <Icon className="h-4 w-4">
                      <Plus />
                    </Icon>
                  </span>
                </Button>
                <AriaMenu.List
                  onAction={(key) => {
                    if (key === 'create') {
                      setShowCreateFolderPopover(true);
                    }
                  }}
                >
                  <AriaMenu.Item
                    id="create"
                    icon={
                      <Icon>
                        <FolderPlus />
                      </Icon>
                    }
                  >
                    {LL.insights.sidebar.reports.createFolder()}
                  </AriaMenu.Item>
                </AriaMenu.List>
              </AriaMenu>
            </div>
          </span>
        }
        className="grow overflow-hidden"
        contentClassName={cn(
          'overflow-y-auto',
          isOver &&
            'z-0 rounded-lg bg-purple-300 outline outline-2 -outline-offset-2 outline-purple-500'
        )}
      >
        {children}
      </Sidebar.Section>
      <Popover
        triggerRef={actionButtonRef}
        isOpen={showCreateFolderPopover}
        onOpenChange={(open) => {
          if (!open) {
            setShowCreateFolderPopover(false);
          }
        }}
      >
        <PopoverDialog className="flex w-64 flex-col p-2">
          <form
            className="flex flex-col gap-3"
            onSubmit={(ev) => {
              ev.preventDefault();
              if (nameReportRef.current && accountUuid) {
                if (!nameReportRef.current.value) {
                  setError(LL.errors.fieldRequired());
                } else {
                  createFolder.mutate({
                    insightsAdAccountFacebookUuid: accountUuid,
                    name: nameReportRef.current.value,
                  });
                }
              }
            }}
          >
            <div className="flex flex-col gap-2">
              <Heading className="text-sm font-semibold text-primary">
                {LL.insights.sidebar.reports.createFolder()}
              </Heading>

              <Input
                ref={nameReportRef}
                name="create-insights-report-folder-name"
              />

              {!!error && <p className="text-xs text-danger">{error}</p>}
            </div>
            <div className="flex flex-1 flex-row justify-end gap-2">
              <AriaButton
                size="smallest"
                variant="secondary"
                onPress={() => setShowCreateFolderPopover(false)}
              >
                {LL.cancel()}
              </AriaButton>
              <AriaButton
                loading={createFolder.isLoading}
                htmlType="submit"
                size="smallest"
                variant="primary"
                colour="primary"
              >
                {LL.save()}
              </AriaButton>
            </div>
          </form>
        </PopoverDialog>
      </Popover>
    </>
  );
};

const InsightsSidebarAccountMenu = ({
  setShowUpgradeModal,
  currentAdAccount,
  adAccounts,
  isLoading,
}: {
  setShowUpgradeModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentAdAccount: ArrayElement<GetManyAdAccountsResponse>;
  adAccounts: GetManyAdAccountsResponse;
  isLoading: boolean;
}) => {
  const {
    authenticate,
    authState: facebookAuthState,
    setAuthState: setFacebookAuthState,
  } = useFacebook();
  const { LL } = useI18nContext();
  const { quota } = useEntitlementQuotas('insights_ad_account');
  const navigate = useNavigate();
  const trpcUtils = trpc.useUtils();
  const user = useUserAndOrganisation();
  const { recordEvent } = useNewAnalyticsEvent();

  const hasHitAdAccountLimit = quota != null && adAccounts.length >= quota;
  const isTikTokEnabled = useFeatureFlag('INSIGHTS_TIK_TOK_ENABLED');

  const [searchQuery, setSearchQuery] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const sortedAdAccounts = adAccounts.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const allowedAdAccounts = sortedAdAccounts
    .slice(0, quota)
    .map((account) => account.uuid);

  const filteredAdAccounts =
    sortedAdAccounts.filter((account) => {
      return account.name.toLowerCase().includes(searchQuery.toLowerCase());
    }) ?? [];

  const { facebookAdAccounts, tikTokAdAccounts } = adAccounts.reduce<{
    facebookAdAccounts: GetManyAdAccountsResponse;
    tikTokAdAccounts: GetManyAdAccountsResponse;
  }>(
    (acc, adAccount) => {
      if (adAccount.name.toLowerCase().includes(searchQuery.toLowerCase())) {
        if (adAccount.config?.configType === 'facebook') {
          acc.facebookAdAccounts.push(adAccount);
        } else if (adAccount.config?.configType === 'tiktok') {
          acc.tikTokAdAccounts.push(adAccount);
        }
      }
      return acc;
    },
    { facebookAdAccounts: [], tikTokAdAccounts: [] }
  );

  return (
    <>
      <MenuTrigger
        onOpenChange={(open) => {
          if (open) {
            void recordEvent({
              action: 'Sidebar Menu - Account List Opened',
              target: 'Insights Account',
            });
            // Set a timeout as the ref is null when the popover is opened
            setTimeout(() => searchRef.current?.focus(), 50);
          } else {
            // Set a timeout to prevent layout shift during popover exit animation
            setTimeout(() => setSearchQuery(''), 200);
          }
          setIsOpen(open);
        }}
        isOpen={isOpen}
      >
        <AriaButton
          className="grow-1 flex w-full justify-between px-1.5 font-semibold"
          variant="secondary"
        >
          <div className="flex w-full items-center gap-2 overflow-hidden">
            <Avatar
              initial={currentAdAccount.name.charAt(0)}
              src={
                currentAdAccount.config &&
                'profilePicURL' in currentAdAccount.config
                  ? currentAdAccount.config?.profilePicURL
                  : undefined
              }
              className="h-6 w-6 shrink-0"
            />
            <span className="grow truncate text-left">
              {currentAdAccount.name}
            </span>
            <span>
              {currentAdAccount.config?.configType === 'facebook' && (
                <Icon>
                  <Facebook />
                </Icon>
              )}
              {currentAdAccount.config?.configType === 'tiktok' && (
                <Icon>
                  <TikTok />
                </Icon>
              )}
            </span>
          </div>
          <Icon className="h-5 w-5 shrink-0">
            <ChevronSelectorVertical />
          </Icon>
        </AriaButton>

        <Popover>
          <div className="px-2 pt-2">
            <Input
              ref={searchRef}
              name="insight-ad-account-search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for ad accounts..."
            />
          </div>

          {filteredAdAccounts.length === 0 && (
            <div className="relative flex max-h-64 w-72 flex-col gap-2 overflow-y-auto p-2 pb-0 focus:outline-none">
              <Alert
                variant="info"
                title="No accounts match"
                className="min-h-10 items-center rounded-md px-2 py-1.5"
              />
            </div>
          )}

          <Menu
            className="relative flex max-h-64 w-72 flex-col gap-2 overflow-y-auto p-2 focus:outline-none"
            selectionMode="single"
            selectedKeys={[currentAdAccount.uuid]}
          >
            {facebookAdAccounts.length > 0 && (
              <>
                <AriaMenu.Item
                  isDisabled
                  className="shrink-0 grow"
                  contentClassName="space-between flex w-full flex-row items-center gap-2"
                >
                  <span className="grow font-bold">Facebook</span>
                  <Icon>
                    <Facebook />
                  </Icon>
                </AriaMenu.Item>
                {facebookAdAccounts
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((adAccount) => {
                    const isLocked = !allowedAdAccounts?.includes(
                      adAccount.uuid
                    );

                    return (
                      <AriaMenu.Item
                        key={adAccount.uuid}
                        id={adAccount.uuid}
                        showCheckbox={true}
                        className={cn('min-h-10', isLocked ? 'blur-sm' : '')}
                        contentClassName="flex items-center gap-2 truncate w-full space-between"
                        onAction={() => {
                          if (isLocked) {
                            setShowUpgradeModal(true);
                          } else {
                            navigate(`/insights/accounts/${adAccount.uuid}`);
                            void recordEvent({
                              action: 'Sidebar Menu - Account Changed',
                              target: 'Insights Account',
                              metadata: {
                                account: `${adAccount.uuid}|${adAccount.name}`,
                              },
                            });
                          }
                          setIsOpen(false);
                        }}
                      >
                        <Avatar
                          initial={adAccount.name.charAt(0)}
                          src={
                            adAccount.config &&
                            'profilePicURL' in adAccount.config
                              ? adAccount.config.profilePicURL
                              : undefined
                          }
                          className="h-6 w-6 shrink-0"
                        />
                        <Text slot="label" className="grow truncate">
                          {adAccount.name}
                        </Text>
                      </AriaMenu.Item>
                    );
                  })}
              </>
            )}

            {isTikTokEnabled && tikTokAdAccounts.length > 0 && (
              <>
                <AriaMenu.Item
                  isDisabled
                  className="shrink-0 grow"
                  contentClassName="space-between flex w-full flex-row items-center gap-2"
                >
                  <span className="grow font-bold">TikTok</span>
                  <Icon>
                    <TikTok />
                  </Icon>
                </AriaMenu.Item>
                {tikTokAdAccounts
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((adAccount) => {
                    const isLocked = !allowedAdAccounts?.includes(
                      adAccount.uuid
                    );

                    return (
                      <AriaMenu.Item
                        key={adAccount.uuid}
                        id={adAccount.uuid}
                        showCheckbox={true}
                        className={cn('min-h-10', isLocked ? 'blur-sm' : '')}
                        contentClassName="flex items-center gap-2 truncate w-full space-between"
                        onAction={() => {
                          if (isLocked) {
                            setShowUpgradeModal(true);
                          } else {
                            navigate(`/insights/accounts/${adAccount.uuid}`);
                            void recordEvent({
                              action: 'Sidebar Menu - Account Changed',
                              target: 'Insights Account',
                              metadata: {
                                account: `${adAccount.uuid}|${adAccount.name}`,
                              },
                            });
                          }
                          setIsOpen(false);
                        }}
                      >
                        <Avatar
                          initial={adAccount.name.charAt(0)}
                          src={
                            adAccount.config &&
                            'profilePicURL' in adAccount.config
                              ? adAccount.config.profilePicURL
                              : undefined
                          }
                          className="h-6 w-6 shrink-0"
                        />
                        <Text slot="label" className="grow truncate">
                          {adAccount.name}
                        </Text>
                      </AriaMenu.Item>
                    );
                  })}
              </>
            )}
          </Menu>

          <div className="m-4 focus:outline-none">
            {hasHitAdAccountLimit ? (
              <AriaButton
                className="flex items-center gap-2 rounded-md border border-solid border-red-50 bg-red-50 p-3 hover:border-red-500 focus:outline-none"
                onPress={() => {
                  setShowUpgradeModal(true);
                  setIsOpen(false);
                }}
              >
                <Icon className="size-5 text-red-800">
                  <AlertCircle />
                </Icon>
                <span className="cursor-default text-xs font-medium text-red-800">
                  {LL.insights.sidebar.facebook.quotaAlert({
                    quota,
                  })}{' '}
                  <Button className="underline underline-offset-4 focus:outline-none">
                    {LL.upgrade.upgradeNow()}
                  </Button>
                </span>
              </AriaButton>
            ) : (
              <AriaButton
                className="w-full"
                variant="secondary"
                onPress={() => {
                  void authenticate();
                  void recordEvent({
                    action: 'Sidebar Menu - Connect Accounts clicked',
                    target: 'Insights Account',
                  });
                  setIsOpen(false);
                }}
                loading={isLoading}
              >
                <span className="text-xs font-semibold">
                  {LL.insights.sidebar.facebook.cta()}
                </span>
              </AriaButton>
            )}
          </div>
        </Popover>
      </MenuTrigger>

      {user.data && facebookAuthState?.status !== 'init' && (
        <FacebookAdAccountSelectModal
          facebookAuthState={facebookAuthState}
          onClose={() => {
            setFacebookAuthState({ status: 'init' });
            void trpcUtils.user.getUserAndOrganisation.invalidate();
          }}
        />
      )}
    </>
  );
};
