import React from 'react';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { Icon } from 'src/components/Icon';
import Link01 from 'src/assets/svgicons/duocolor/link-01.svg';
import { Button } from 'src/components/Button';
import Input from 'src/components/Input';
import { useI18nContext } from 'src/i18n/i18n-react';

function generateRadioOptionStyle(
  idx: number,
  total: number,
  checked: boolean,
  disabled: boolean
) {
  return classNames(
    idx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
    idx === total - 1 ? 'rounded-bl-md rounded-br-md' : '',
    checked ? 'z-10 border-purple-300 bg-purple-100' : 'border-gray-300',
    disabled ? 'cursor-not-allowed opacity-50' : '',
    'relative flex cursor-pointer text-wrap border border-solid p-4 focus:outline-none'
  );
}

type Props = React.PropsWithChildren<{
  screenReaderLabel: string;
  isPublic: boolean;
  link: string | null;
  onUpdate: (isPublic: boolean) => void;
  onCopyToClipboard: () => void;
  publicDescription: string;
  privateDescription: string;
  isLoading: boolean;
}>;

const ShareSettings: React.FunctionComponent<Props> = ({
  link,
  onUpdate,
  onCopyToClipboard,
  isPublic,
  screenReaderLabel,
  publicDescription,
  privateDescription,
  children,
  isLoading,
}): JSX.Element => {
  const { LL } = useI18nContext();

  return (
    <div className="flex flex-col gap-3">
      <RadioGroup disabled={isLoading} value={isPublic} onChange={onUpdate}>
        <RadioGroup.Label className="sr-only">
          {screenReaderLabel}
        </RadioGroup.Label>
        <div className="-space-y-px">
          {[false, true].map((x, i) => (
            <RadioGroup.Option
              disabled={isLoading}
              key={x.toString()}
              value={x}
              className={({ checked, disabled }) =>
                generateRadioOptionStyle(i, 2, checked, disabled)
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(
                      checked
                        ? 'border-purple-800 bg-purple-800'
                        : 'border-gray-300 bg-white',
                      active ? 'ring-2 ring-indigo-600 ring-offset-2' : '',
                      'last mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border border-solid'
                    )}
                    aria-hidden="true"
                  >
                    <span className="h-1.5 w-1.5 rounded-full bg-white" />
                  </span>
                  <span className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        'block text-sm font-semibold text-purple-800'
                      )}
                    >
                      {x ? LL.public() : LL.private()}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        'block text-sm font-medium text-text-secondary'
                      )}
                    >
                      {x ? publicDescription : privateDescription}
                    </RadioGroup.Description>
                  </span>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      {link && (
        <Input label="" name="" readOnly value={link}>
          <Icon>
            <Link01 />
          </Icon>
        </Input>
      )}
      <div className="flex flex-auto flex-row items-center gap-2">
        <Button
          className="flex-auto"
          onClick={onCopyToClipboard}
          disabled={isLoading}
        >
          {LL.copyLink()}
        </Button>
        {children}
      </div>
    </div>
  );
};

export default ShareSettings;
