import { FC } from 'react';
import classNames from 'classnames';
import { Button } from 'react-aria-components';
import { uuidv4 } from '@magicbrief/common';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import Hash01 from 'src/assets/svgicons/line/hash-01.svg';
import AlertTriangle from 'src/assets/svgicons/line/alert-triangle.svg';
import { Icon } from 'src/components/Icon';
import { trpc } from 'src/lib/trpc';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import { DialogPopover } from '../../../../components/DialogPopover/DialogPopover';
import {
  useInsightsDisplay,
  useInsightsFilter,
  useInsightsStoreDispatch,
} from '../../util/useInsightsPersistentState';
import {
  getMetricFilterValuesAsText,
  useParseMetric,
} from '../../util/useParseMetric';
import { InsightsMetricLabelTooltip } from '../InsightsMetricLabelTooltip/InsightsMetricLabelTooltip';
import { InsightsFilterForm } from './components/InsightsFilterMetricItemEdit';

type Props = {
  className?: string;
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
};

const InsightsFilterMetricListTableView: FC<Props> = ({
  customEvents,
  customConversions,
  className,
}) => {
  const { LL } = useI18nContext();
  const display = useInsightsDisplay();
  const metrics = useInsightsFilter();
  const dispatch = useInsightsStoreDispatch();
  const { recordEvent } = useNewAnalyticsEvent();

  const { getMetricLabelAsText } = useParseMetric();

  const tags = trpc.insights.getInsightsTags.useQuery();

  if (metrics.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames(
        'flex flex-row items-center gap-2.5 px-4 py-2',
        className
      )}
    >
      <p className="-mb-3 shrink-0 pb-3 text-xs font-semibold text-primary">
        Filters
      </p>
      {metrics.length > 0 && (
        <div className="-mb-3 flex flex-row gap-2.5 overflow-auto pb-3">
          {metrics.map((filter, index) => {
            if (!('field' in filter)) {
              return null;
            }
            const key = uuidv4();

            if (filter.field === 'tag') {
              if (filter.operation === 'in') {
                return (
                  <>
                    {filter.values.map((x, i) => {
                      const tag = tags.data?.find((y) => y.uuid === x);

                      return (
                        <InsightsFilterMetricListTableViewItem
                          style={
                            !tag
                              ? {
                                  filter:
                                    'grayscale(100%) brightness(100%) sepia(100%) hue-rotate(290deg) saturate(300%)',
                                }
                              : undefined
                          }
                          key={x}
                          onDelete={() => {
                            const newTagValues = [
                              ...filter.values.slice(0, i),
                              ...filter.values.slice(i + 1),
                            ];

                            if (newTagValues.length > 0) {
                              const newFilters: typeof metrics = [
                                ...metrics.slice(0, index),
                                {
                                  field: 'tag',
                                  operation: 'in',
                                  values: newTagValues,
                                },
                                ...metrics.slice(index + 1),
                              ];
                              dispatch({
                                type: 'setFilter',
                                value: newFilters,
                              });
                            } else {
                              dispatch({
                                type: 'setFilter',
                                value: [
                                  ...metrics.slice(0, index),
                                  ...metrics.slice(index + 1),
                                ],
                              });
                            }
                          }}
                        >
                          <span className="flex flex-row items-center gap-1 overflow-hidden">
                            <Icon className="h-4 w-4 text-purple-600">
                              {tag ? <Hash01 /> : <AlertTriangle />}
                            </Icon>
                            <span className="overflow-hidden text-ellipsis text-nowrap">
                              {tag?.label ?? 'Unknown/Deleted Tag'}
                            </span>
                          </span>
                        </InsightsFilterMetricListTableViewItem>
                      );
                    })}
                  </>
                );
              }
            }

            const label = getMetricLabelAsText(
              filter.field,
              customEvents,
              customConversions
            );

            if (label == null) {
              return null;
            }

            return (
              <InsightsFilterMetricListTableViewItem
                key={key}
                onDelete={() => {
                  dispatch({
                    type: 'removeFilter',
                    value: filter,
                  });

                  void recordEvent({
                    action: `Metric Removed`,
                    target: 'Insights Metric',
                    metadata: {
                      metric: filter.field,
                    },
                  });
                }}
              >
                <DialogPopover className="max-h-72" placement="bottom end">
                  <Button className="flex flex-row items-center gap-2">
                    <span className="flex flex-col justify-start">
                      <span className="flex flex-row items-center gap-1">
                        <span>
                          <InsightsMetricLabelTooltip
                            metric={filter.field}
                            customConversions={customConversions}
                            customEvents={customEvents}
                          />
                        </span>
                        <span>
                          {LL.insights.metrics.operationSymbols[
                            filter.operation
                          ]()}
                        </span>
                        <span>{getMetricFilterValuesAsText(filter)}</span>
                      </span>
                    </span>
                  </Button>
                  {({ close }) => (
                    <div className="p-2">
                      <InsightsFilterForm
                        customEvents={customEvents}
                        customConversions={customConversions}
                        onSaveComplete={(newFilters) => {
                          const newFilter = newFilters[0];
                          const idx = metrics.findIndex(
                            (x) =>
                              'value' in x &&
                              'value' in newFilter &&
                              x.field === newFilter.field
                          );
                          if (idx === -1) {
                            return;
                          }
                          const filters = [
                            ...metrics.slice(0, idx),
                            newFilter,
                            ...metrics.slice(idx + 1),
                          ];
                          const newDisplay =
                            'field' in newFilter
                              ? [...new Set([...display, newFilter.field])]
                              : display;
                          dispatch({ type: 'setFilter', value: filters });
                          dispatch({ type: 'setDisplay', value: newDisplay });
                          close();

                          void recordEvent({
                            action: `Metric Edit Opened`,
                            target: 'Insights Metric',
                            metadata: filter,
                          });
                        }}
                        defaultValues={[filter]}
                      />
                    </div>
                  )}
                </DialogPopover>
              </InsightsFilterMetricListTableViewItem>
            );
          })}
        </div>
      )}
    </div>
  );
};

const InsightsFilterMetricListTableViewItem: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { onDelete: () => void }
> = ({ children, onDelete, className, ...divProps }) => {
  return (
    <div
      {...divProps}
      className={cn(
        'flex w-max flex-row items-center gap-1 rounded border border-solid border-purple-200 bg-purple-100/60 px-2 py-1 text-xs font-semibold text-purple-800 outline-none',
        className
      )}
    >
      {children}
      <Button
        className="h-4 w-4 text-purple-900/60 outline-none"
        onPress={onDelete}
      >
        <Icon className="h-4 w-4">
          <XClose />
        </Icon>
      </Button>
    </div>
  );
};

export default InsightsFilterMetricListTableView;
