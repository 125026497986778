import { useState, useEffect } from 'react';
import NumberFlow from '@number-flow/react';
import { useLocation } from 'react-router-dom';
import Clock from 'src/assets/svgicons/line/clock.svg';
import { useI18nContext } from 'src/i18n/i18n-react';
import BarChartSquare01 from 'src/assets/svgicons/duotone/bar-chart-square-01.svg';
import SearchRefraction from 'src/assets/svgicons/duotone/search-refraction.svg';
import Signal03 from 'src/assets/svgicons/duotone/signal-03.svg';
import MessageNotificationCircle from 'src/assets/svgicons/duotone/message-notification-circle.svg';
import Trophy02 from 'src/assets/svgicons/duotone/trophy-02.svg';
import Activity from 'src/assets/svgicons/duotone/activity.svg';
import Settings01 from 'src/assets/svgicons/duotone/settings-01.svg';
import Lightning01 from 'src/assets/svgicons/duotone/lightning-01.svg';
import { cn } from 'src/lib/cn';
import { trpc } from 'src/lib/trpc';
import { useUserAndOrganisation } from 'src/utils/useUserAndOrganisation';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { Icon } from '../Icon';
import { AriaButton, ButtonLink } from '../Button';
import { BaseModal } from '../AriaModal';

const LimitedTimeOffer: React.FC = () => {
  const [showFeaturesModal, setShowFeaturesModal] = useState(false);
  const location = useLocation();
  const { client } = trpc.useUtils();
  const { recordEvent } = useNewAnalyticsEvent();
  const userAndOrg = useUserAndOrganisation();
  const { LL } = useI18nContext();
  const createCheckoutUrl = trpc.stripe.getNewPortalCheckoutLink.useMutation();
  const { data: offerExpiryDate } = trpc.user.getOfferExpiryDate.useQuery(
    undefined,
    {
      enabled:
        userAndOrg.data?.organisation.billingState === 'free' ||
        userAndOrg.data?.organisation.billingState === 'trialExpired',
    }
  );

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (!offerExpiryDate) return;
    function calculateTimeLeft() {
      if (!offerExpiryDate) return;
      const now = new Date().getTime();
      const difference = offerExpiryDate.getTime() - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        });
      } else {
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      }
    }

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [offerExpiryDate]);

  async function handleUpgrade() {
    void recordEvent({
      action: 'Clicked Upgrade Now On Limited Time Offer',
      target: 'Product Offer',
      metadata: {
        offerExpired,
      },
    });
    const plans = await client.stripe.getNewPlansForOrg.query();
    const sortedPlans = plans.sort((a, b) => a.paywallOrder - b.paywallOrder);
    const firstPlan = sortedPlans[0];
    if (firstPlan.stripeProductID) {
      createCheckoutUrl.mutate(
        {
          productID: firstPlan.stripeProductID,
          priceID: firstPlan.stripePrice,
        },
        {
          onSuccess: (response) => {
            window.location.href = response;
          },
        }
      );
    }
  }

  const offerExpired =
    (offerExpiryDate ? offerExpiryDate.getTime() < Date.now() : false) ||
    userAndOrg.data?.organisation.billingState === 'trialExpired';

  useEffect(() => {
    if (offerExpired && !showFeaturesModal) {
      setShowFeaturesModal(true);
    }
  }, [offerExpired, showFeaturesModal]);

  if (
    !offerExpiryDate ||
    !(
      userAndOrg.data?.organisation.billingState === 'free' ||
      userAndOrg.data?.organisation.billingState === 'trialExpired'
    ) ||
    location.pathname.startsWith('/settings')
  )
    return null;

  if (userAndOrg.data?.organisation.billingState === 'trialExpired') {
    return (
      <div className="flex flex-col gap-2 rounded-lg border border-solid border-purple-300 bg-purple-50 px-3 py-4 text-primary">
        {showFeaturesModal && (
          <FeaturesModal
            title={
              offerExpired
                ? LL.upgrade.trialExpired()
                : timeLeft.days > 0
                ? LL.upgrade.offerExpiresInNDays({ n: timeLeft.days })
                : timeLeft.hours > 0
                ? LL.upgrade.offerExpiresInNHours({ n: timeLeft.hours })
                : LL.upgrade.offerExpiresSoon()
            }
            onClose={() => setShowFeaturesModal(false)}
            handleUpgrade={handleUpgrade}
            dismissable={!offerExpired}
          />
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 rounded-lg border border-solid border-purple-300 bg-purple-50 px-3 py-4 text-primary">
      <div className="flex items-center gap-1">
        <Icon className="size-5">
          <Clock />
        </Icon>
        <p className="text-nowrap text-sm font-bold">
          {LL.upgrade.trialRemaining()}
        </p>
      </div>
      <p className="text-xs opacity-50">{LL.upgrade.limitedTimeOffer()}</p>
      <div className="flex items-center justify-center gap-1 text-center text-sm font-bold tabular-nums">
        <div className="grid h-10 flex-1 place-items-center rounded-md border border-solid border-secondary bg-white px-2 py-1">
          <NumberFlow
            value={timeLeft.days}
            className="data-[kind=digit]:text-red-500"
          />
        </div>
        <span className="opacity-50">:</span>
        <div className="grid h-10 flex-1 place-items-center rounded-md border border-solid border-secondary bg-white px-2 py-1">
          <NumberFlow
            value={timeLeft.hours}
            format={{ minimumIntegerDigits: 2 }}
          />
        </div>
        <span className="opacity-50">:</span>
        <div className="grid h-10 flex-1 place-items-center rounded-md border border-solid border-secondary bg-white px-2 py-1">
          <NumberFlow
            value={timeLeft.minutes}
            format={{ minimumIntegerDigits: 2 }}
          />
        </div>
        <span className="opacity-50">:</span>
        <div className="grid h-10 flex-1 place-items-center rounded-md border border-solid border-secondary bg-white px-2 py-1">
          <NumberFlow
            value={timeLeft.seconds}
            format={{ minimumIntegerDigits: 2 }}
          />
        </div>
      </div>
      <AriaButton
        onPress={() => setShowFeaturesModal(true)}
        className="hidden w-full md:block"
        variant="gradient"
      >
        {LL.upgrade.upgradeNow()}
      </AriaButton>
      {/* Send them directly to Stripe if they're on mobile */}
      <AriaButton
        onPress={handleUpgrade}
        className="w-full md:hidden"
        variant="gradient"
      >
        {LL.upgrade.upgradeNow()}
      </AriaButton>

      {showFeaturesModal && (
        <FeaturesModal
          title={
            offerExpired
              ? LL.upgrade.trialExpired()
              : timeLeft.days > 0
              ? LL.upgrade.offerExpiresInNDays({ n: timeLeft.days })
              : timeLeft.hours > 0
              ? LL.upgrade.offerExpiresInNHours({ n: timeLeft.hours })
              : LL.upgrade.offerExpiresSoon()
          }
          onClose={() => setShowFeaturesModal(false)}
          handleUpgrade={handleUpgrade}
          dismissable={!offerExpired}
        />
      )}
    </div>
  );
};

const FeaturesModal: React.FC<{
  title: string;
  onClose: () => void;
  handleUpgrade: () => Promise<void>;
  dismissable?: boolean;
}> = ({ title, onClose, handleUpgrade, dismissable = true }) => {
  const [selectedFeature, setSelectedFeature] = useState<
    (typeof FEATURES)[number]
  >(FEATURES[0]);
  const { recordEvent } = useNewAnalyticsEvent();
  const { LL } = useI18nContext();
  const createCheckoutUrl = trpc.stripe.getNewPortalCheckoutLink.useMutation();

  useEffect(() => {
    void recordEvent({
      action: 'Limited Time Offer Modal Shown',
      target: 'Product Offer',
    });
  }, [recordEvent]);

  return (
    <BaseModal modalClassName="bg-white rounded-xl" onClose={onClose} show>
      <div className="divide-y divide-solid divide-purple-300 text-primary *:px-6 *:py-4">
        <h2 className="text-xl font-semibold">{title}</h2>
        <div className="flex gap-6">
          <div className="space-y-2.5">
            {FEATURES.map((feature) => (
              <AriaButton
                key={feature.key}
                variant="custom"
                className={cn(
                  'h-fit max-w-[348px] rounded-lg hover:bg-purple-100',
                  selectedFeature.key === feature.key && 'bg-purple-100'
                )}
                onPress={() => setSelectedFeature(feature)}
              >
                <div className="flex items-start gap-2.5">
                  <Icon className="-mt-2 size-11">{feature.icon}</Icon>
                  <div className="space-y-1.5 text-left">
                    <p className="text-base font-semibold">
                      {LL.upgrade.features[feature.key].title()}
                    </p>
                    <p className="text-sm font-medium opacity-50">
                      {LL.upgrade.features[feature.key].description()}
                    </p>
                  </div>
                </div>
              </AriaButton>
            ))}
          </div>
          <div className="h-[589px] w-[530px]">
            <img
              src={`/images/features/${selectedFeature.imageName}.webp`}
              alt={LL.upgrade.features[selectedFeature.key].title()}
              className="h-full w-full rounded-2xl object-cover transition-all"
            />
          </div>
        </div>
        <div className="flex justify-end gap-3">
          <ButtonLink
            type="external"
            href="https://magicbrief.com/pricing"
            className="mr-auto h-fit hover:underline"
            variant="custom"
          >
            {LL.workspaceSettings.viewAllPlans()}
          </ButtonLink>
          {dismissable && (
            <AriaButton onPress={onClose} variant="text">
              {LL.cancel()}
            </AriaButton>
          )}
          {!dismissable && (
            <ButtonLink
              type="internal"
              to="/settings/workspace"
              variant="white"
              icon={<Settings01 />}
              className="capitalize"
            >
              {LL.workspaceSettings.workspaceSettings()}
            </ButtonLink>
          )}
          <AriaButton
            loading={createCheckoutUrl.isLoading}
            onPress={handleUpgrade}
            icon={<Lightning01 />}
          >
            {LL.upgrade.upgradePlan()}
          </AriaButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default LimitedTimeOffer;

const FEATURES = [
  {
    key: 'advancedCreativeAnalytics',
    icon: <BarChartSquare01 />,
    imageName: 'feat-creative-analytics',
  },
  {
    key: 'adResearchEngine',
    icon: <SearchRefraction />,
    imageName: 'feat-research-engine',
  },
  {
    key: 'trackAnyBrandRunningAds',
    icon: <Signal03 />,
    imageName: 'feat-track-brands',
  },
  {
    key: 'automatedSlackReporting',
    icon: <MessageNotificationCircle />,
    imageName: 'feat-slack-reporting',
  },
  {
    key: 'seeWinnersWithRecommendations',
    icon: <Trophy02 />,
    imageName: 'feat-recommendations',
  },
  {
    key: 'checkAdAccountHealthInSeconds',
    icon: <Activity />,
    imageName: 'feat-ad-account-health',
  },
] as const;
