import { FC, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import {
  InsightsFilterV2,
  InsightsMetricOperationV2,
  InsightsFilter,
  isInsightsFacebookFilterNumeric,
  isInsightsFacebookFilterText,
  isInsightsFacebookFilterEnumerable,
  multiMetricsOptions,
  isInsightsFacebookFilterDate,
  isInsightsFacebookFilterTag,
} from '@magicbrief/common';
import {
  useFilterFormFieldV2,
  useFilterFormV2,
} from 'src/pages/Insights/util/useFilterFormV2';
import Input from 'src/components/Input';
import {
  ComboboxInput,
  ComboboxOption,
  ComboboxOptionLabel,
  ComboboxOptions,
  ComboboxTransition,
} from 'src/components/Select/ComboboxContent';
import { useI18nContext } from 'src/i18n/i18n-react';
import { AriaButton } from 'src/components/Button/Button';
import { trpc } from 'src/lib/trpc';
import { InsightsFormFilterMetricOperationSelect } from './InsightsFilterMetricOperationSelect';
import { InsightsFormFilterMetricFieldSelect } from './InsightsFilterMetricFieldSelect';

type Props = {
  onSaveComplete: (filters: Array<InsightsFilterV2>) => void;
  defaultValues?: Array<InsightsFilterV2>;
  customEvents: string[] | null;
  customConversions: Array<{ facebookId: string; name: string }> | null;
};

export const InsightsFilterForm: FC<Props> = ({
  onSaveComplete,
  defaultValues,
  customEvents,
  customConversions,
}) => {
  const { handleSubmit, ...formMethods } = useFilterFormV2({
    defaultValues: { filters: { values: defaultValues ?? [] } },
  });

  const onSubmit = handleSubmit((formValues) => {
    onSaveComplete(formValues['filters'].values);

    // void recordEvent({
    //   action: `Metric ${filter ? 'Added' : 'Edited'}`,
    //   target: 'Insights Metric',
    //   metadata: {
    //     metric: first.metric,
    //     operation: first.operation,
    //     value1:
    //       'value1' in first
    //         ? Array.isArray(first.value1)
    //           ? first.value1.join(', ')
    //           : first.value1
    //         : undefined,
    //     value2:
    //       'value2' in first
    //         ? Array.isArray(first.value2)
    //           ? first.value2.join(', ')
    //           : first.value2
    //         : undefined,
    //   },
    // });
  });

  return (
    <FormProvider handleSubmit={handleSubmit} {...formMethods}>
      <form onSubmit={onSubmit}>
        <InsightsFilterFormFields
          prefix="filters."
          customEvents={customEvents}
          customConversions={customConversions}
        />
      </form>
    </FormProvider>
  );
};

type InsightsFilterFormFieldProps = {
  prefix: string;
  customEvents: string[] | null;
  customConversions: Array<{ facebookId: string; name: string }> | null;
};

export const InsightsFilterFormFields: FC<InsightsFilterFormFieldProps> = ({
  prefix,
  customEvents,
  customConversions,
}) => {
  const { fields } = useFilterFormFieldV2(prefix);

  return (
    <div>
      {fields.map((renderField, i) => {
        return (
          <div
            key={renderField.id}
            className="flex flex-auto flex-col items-center gap-2 lg:flex-row"
          >
            <span className="text-sm text-primary/60">Where</span>

            <div className="grid max-w-[600px] auto-cols-fr grid-flow-col grid-rows-1 gap-2">
              <InsightsFilterFormFieldsRow
                index={i}
                prefix={prefix}
                customConversions={customConversions}
                customEvents={customEvents}
              />
            </div>
            <AriaButton htmlType="submit">Save</AriaButton>
          </div>
        );
      })}
    </div>
  );
};

export const InsightsFilterFormFieldsRow: FC<
  InsightsFilterFormFieldProps & { index: number }
> = ({ prefix, customConversions, customEvents, index }) => {
  const { onMetricChange, onOperationChange } = useFilterFormFieldV2(prefix);
  return (
    <>
      <InsightsFormFilterMetricFieldSelect
        prefix={`${prefix}values.${index}`}
        customConversions={customConversions}
        customEvents={customEvents}
        onChange={(m) => onMetricChange(m, index)}
      />

      <InsightsFormFilterMetricOperationSelect
        prefix={`${prefix}values.${index}`}
        onChange={(e: string) =>
          onOperationChange(e as InsightsMetricOperationV2, index)
        }
      />

      <InsightsFilterFormFieldValueInputs prefix={`${prefix}values.${index}`} />
    </>
  );
};

type InsightsFilterFormFieldValueInputsProps = {
  prefix: string;
};

export const InsightsFilterFormFieldValueInputs: FC<
  InsightsFilterFormFieldValueInputsProps
> = ({ prefix }) => {
  const { control, register, watch } = useFormContext();
  const filter: InsightsFilter = watch(prefix);

  if (isInsightsFacebookFilterNumeric(filter)) {
    if (filter.operation === 'between' || filter.operation === 'notBetween') {
      return (
        <>
          <Input
            {...register(`${prefix}.min`, { valueAsNumber: true })}
            placeholder={'Min'}
            type="number"
            step={0.01}
            label=""
            className="h-10 flex-auto bg-white"
          />

          <Input
            {...register(`${prefix}.max`, { valueAsNumber: true })}
            placeholder={'Max'}
            type="number"
            step={0.01}
            label=""
            className="h-10 flex-auto bg-white"
          />
        </>
      );
    }
    return (
      <Controller
        name={`${prefix}.value`}
        control={control}
        render={({ field }) => (
          <Input
            name={field.name}
            value={field.value}
            onChange={(ev) => field.onChange(parseFloat(ev.target.value))}
            placeholder={'Value'}
            type="number"
            step={0.01}
            label=""
            className="h-10 flex-auto bg-white"
          />
        )}
      />
    );
  }
  if (isInsightsFacebookFilterText(filter)) {
    return (
      <Controller
        name={`${prefix}.value`}
        control={control}
        render={({ field }) => (
          <Input
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            placeholder={'Value'}
            type="text"
            label=""
            className="h-10 flex-auto bg-white"
          />
        )}
      />
    );
  }
  if (isInsightsFacebookFilterEnumerable(filter)) {
    return (
      <InsightsFilterFormFieldValueInputsCombobox
        values={Object.entries(multiMetricsOptions[filter.field]).map(
          ([value, label]) => ({ value, label: label as string })
        )}
        prefix={prefix}
      />
    );
  }
  if (isInsightsFacebookFilterTag(filter)) {
    return <InsightsFilterFormFieldValueInputsTagCombobox prefix={prefix} />;
  }
  if (isInsightsFacebookFilterDate(filter)) {
    return null;
  }
};

type InsightsFilterFormFieldValueInputsCombobox = {
  values: Array<{ value: string; label: string }>;
  prefix: string;
};

const InsightsFilterFormFieldValueInputsCombobox: React.FC<
  InsightsFilterFormFieldValueInputsCombobox
> = ({ values, prefix }) => {
  const { LL } = useI18nContext();
  const [value1SearchInput, setValue1SearchInput] = useState('');

  const { control } = useFormContext();

  return (
    <Controller
      name={`${prefix}.values`}
      control={control}
      render={({ field }) => (
        <Combobox<string[] | null>
          value={field.value as string[]}
          multiple={true}
          onChange={(value) => {
            if (value == null) return;
            field.onChange(value);
          }}
        >
          <ComboboxTransition isPortal zIndex={999999999}>
            <div className="w-full">
              <ComboboxInput
                displayValue={(value?: string | string[]) => {
                  if (value == null) {
                    return '';
                  }
                  if (typeof value === 'string') {
                    const match = values.find((x) => x.value === value);
                    return match?.label ?? value;
                  }

                  const matches = value.reduce<string[]>((acc, curr) => {
                    const match = values.find((x) => x.value === curr);
                    if (match) {
                      acc.push(match.label);
                    }
                    return acc;
                  }, []);
                  return matches.join(', ');
                }}
                placeholder={LL.insights.metrics.multiSelectHint()}
                handleQueryChange={(ev) => {
                  setValue1SearchInput(ev.target.value);
                }}
              />
            </div>
            <div
              className="absolute w-full"
              id="metric-edit-multi-option-select-options"
              style={{ zIndex: 9999999 }}
            >
              <ComboboxOptions>
                {values
                  .filter(
                    ({ value, label }) =>
                      value
                        .toLowerCase()
                        .includes(value1SearchInput.toLowerCase()) ||
                      label
                        .toLowerCase()
                        .includes(value1SearchInput.toLowerCase())
                  )
                  ?.map(({ value, label }) => {
                    return (
                      <ComboboxOption key={value} value={value}>
                        {({ selected }) => (
                          <ComboboxOptionLabel
                            selected={selected}
                            disabled={false}
                          >
                            {label}
                          </ComboboxOptionLabel>
                        )}
                      </ComboboxOption>
                    );
                  })}
              </ComboboxOptions>
            </div>
          </ComboboxTransition>
        </Combobox>
      )}
    />
  );
};

const InsightsFilterFormFieldValueInputsTagCombobox: React.FC<{
  prefix: string;
}> = ({ prefix }) => {
  const tags = trpc.insights.getInsightsTags.useQuery();
  return (
    <InsightsFilterFormFieldValueInputsCombobox
      prefix={prefix}
      values={tags.data?.map((x) => ({ value: x.uuid, label: x.label })) ?? []}
    />
  );
};
