import { getFeatureFlagValue } from 'src/utils/getFeatureFlagValue';

const appConfigs = {
  env: import.meta.env.VITE_ENV || 'production',
  debug: import.meta.env.DEBUG === 'true' || false,
  host: `${getFeatureFlagValue('MB_API_URL')}/api/v1`,
  trpcHost: `${getFeatureFlagValue('MB_API_URL')}/trpc`,
  shortURL: import.meta.env.VITE_SHORT_URL || 'app.magicbrief.com',
  embedURL: import.meta.env.VITE_EMBED_HOST || 'https://share.magicbrief.com',
  defaultAdsLimitFetchLimit: window.innerWidth > 1200 ? 12 : 8,
  stripe: {
    pk: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  },
  isFirebaseEmulator: import.meta.env.VITE_FIREBASE_EMULATION === 'true',
  firebaseEmulatorHost:
    import.meta.env.VITE_FIREBASE_EMULATOR_HOST ?? 'localhost',
  firebaseEmulatorAuthPort:
    import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_PORT ?? 9099,
  firestoreEmulatorPort: import.meta.env.VITE_FIRESTORE_EMULATOR_PORT
    ? parseInt(import.meta.env.VITE_FIRESTORE_EMULATOR_PORT)
    : 8080,
  firebaseFunctionsEmulatorPort: import.meta.env
    .VITE_FIREBASE_FUNCTIONS_EMULATOR_PORT
    ? parseInt(import.meta.env.VITE_FIREBASE_FUNCTIONS_EMULATOR_PORT)
    : 5001,
  release: import.meta.env.VITE_RELEASE,
  deployment: import.meta.env.VITE_DEPLOYMENT,
  hocuspocusHost: import.meta.env.VITE_HOCUSPOCUS_HOST,
  shareURL: getFeatureFlagValue('MB_SHARE_URL'),
};

export default appConfigs;
