import { InsightsFilterV2, InsightsFilterNumeric } from '@magicbrief/common';
import { ForTimePeriodInput } from '@magicbrief/server/src/insights/types';
import { TranslationFunctions } from 'src/i18n/i18n-types';
import { parseValueForMetric } from '../../util/useParseMetric';
import { getTimePeriodLabel } from '../../util/getTimePeriodLabel';

export function insightsAnalysisFilterToShortDescription(
  // We need to allow strings here due to loose typing
  // eslint-disable-next-line @typescript-eslint/ban-types
  id: 'new' | 'scaling' | 'winners' | 'losers' | (string & {}),
  metrics: Array<InsightsFilterV2>,
  currency: string,
  forTimePeriod: ForTimePeriodInput,
  LL: TranslationFunctions
) {
  switch (id) {
    case 'new': {
      const timePeriodLabel = getTimePeriodLabel(forTimePeriod, LL);
      return timePeriodLabel;
    }
    case 'scaling': {
      const knownMetric = metrics.find(
        (x): x is InsightsFilterNumeric => 'field' in x && x.field === 'spend'
      );

      return knownMetric?.field === 'spend' && 'value' in knownMetric
        ? `Spend ${LL.insights.metrics.operationSymbols[
            knownMetric.operation
          ]()} ${parseValueForMetric(
            knownMetric.field,
            knownMetric.value,
            currency
          )}`
        : 'Custom';
    }
    case 'winners': {
      const knownSpendMetric = metrics.find(
        (x): x is InsightsFilterNumeric => 'field' in x && x.field === 'spend'
      );
      const knownRoasMetric = metrics.find(
        (x): x is InsightsFilterNumeric => 'field' in x && x.field === 'roas'
      );

      const spendPortion =
        knownSpendMetric?.field === 'spend' && 'value' in knownSpendMetric
          ? `Spend ${LL.insights.metrics.operationSymbols[
              knownSpendMetric.operation
            ]()} ${parseValueForMetric(
              knownSpendMetric.field,
              knownSpendMetric.value,
              currency
            )}`
          : null;

      const roasPortion =
        knownRoasMetric?.field === 'roas' && 'value' in knownRoasMetric
          ? `ROAS ${LL.insights.metrics.operationSymbols[
              knownRoasMetric.operation
            ]()} ${parseValueForMetric(
              knownRoasMetric.field,
              knownRoasMetric.value,
              currency
            )}`
          : null;

      if (!spendPortion && !roasPortion) {
        return 'Custom';
      }

      const additionalPortion = !spendPortion || !roasPortion ? 'Custom' : null;

      return ['Scaling', roasPortion, additionalPortion]
        .filter((x) => !!x)
        .join(' and ');
    }
    case 'losers': {
      const knownMetric = metrics.find(
        (x): x is InsightsFilterNumeric => 'field' in x && x.field === 'roas'
      );

      if (knownMetric?.field === 'roas' && 'value' in knownMetric) {
        return `ROAS ${LL.insights.metrics.operationSymbols[
          knownMetric.operation
        ]()} ${parseValueForMetric(
          knownMetric.field,
          knownMetric.value,
          currency
        )}`;
      }
      return 'Custom';
    }
  }
}
