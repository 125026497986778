import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { SimpleModal } from 'src/components/AriaModal';
import { PermissionManager } from 'src/components/PermissionManager';
import ShareSettings from 'src/components/ShareSettings';
import { SquareLoaders } from 'src/components/SquareLoaders';
import { appConfigs } from 'src/configs';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import { useCopyToClipboard } from 'src/utils/useCopyToClipboard';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { useInsightsAdAccount } from '../util/useInsightsQueries';

type Props = {
  adUuid: string;
  adName: string;
  show: boolean;
  onClose: () => void;
};

const ShareInsightsAdModal: FC<Props> = ({ adUuid, adName, show, onClose }) => {
  const { LL } = useI18nContext();
  const trpcUtils = trpc.useUtils();
  const copy = useCopyToClipboard();
  const { accountUuid } = useParams();
  const { adAccount } = useInsightsAdAccount({ accountUuid });
  const { recordEvent } = useNewAnalyticsEvent();

  const insightsOrganisationAdAccountFacebookUuid =
    adAccount.data?.insightsOrganisationAdAccountUuid;

  const insightsOrganisationAdFacebook =
    trpc.insightsAds.getInsightsOrganisationAdFacebook.useQuery(
      {
        insightsAdFacebookUuid: adUuid,
        insightsOrganisationAdAccountFacebookUuid:
          insightsOrganisationAdAccountFacebookUuid ?? '',
      },
      {
        enabled: !!insightsOrganisationAdAccountFacebookUuid,
      }
    );

  const updateInsightsOrganisationAdFacebook =
    trpc.insightsAds.updateInsightsOrganisationAdFacebook.useMutation({
      onSuccess(data) {
        trpcUtils.insightsAds.getInsightsOrganisationAdFacebook.setData(
          {
            insightsAdFacebookUuid: adUuid,
            insightsOrganisationAdAccountFacebookUuid:
              insightsOrganisationAdAccountFacebookUuid ?? '',
          },
          (old) =>
            !old
              ? old
              : {
                  ...old,
                  ...data,
                }
        );
      },
    });

  const link = insightsOrganisationAdFacebook.data
    ? `${appConfigs.shareURL}/insights/ads/${
        insightsOrganisationAdFacebook.data.uuid
      }${window.location.search !== '' ? `${window.location.search}` : ''}`
    : null;

  return (
    <SimpleModal
      title={LL.insights.details.shareModal.title({ name: adName })}
      show={show}
      onClose={onClose}
      className="min-w-md max-w-xl flex-col gap-2"
    >
      {insightsOrganisationAdFacebook.isLoading ? (
        <SquareLoaders className="m-4 h-10 w-52" amount={6} />
      ) : (
        <div className="flex flex-col gap-4">
          <ShareSettings
            link={
              insightsOrganisationAdFacebook.isLoading
                ? LL.loading()
                : updateInsightsOrganisationAdFacebook.isLoading
                ? LL.saving()
                : link
            }
            isLoading={insightsOrganisationAdFacebook.isLoading}
            isPublic={insightsOrganisationAdFacebook.data?.realm === 'public'}
            onUpdate={(isPublic) => {
              if (insightsOrganisationAdFacebook.data == null) {
                return;
              }
              updateInsightsOrganisationAdFacebook.mutate({
                uuid: insightsOrganisationAdFacebook.data.uuid,
                realm: isPublic ? 'public' : 'private',
              });
            }}
            onCopyToClipboard={() => {
              void recordEvent({
                action: `Shared ${insightsOrganisationAdFacebook.data?.realm} ad`,
                target: 'Insights Ad',
                metadata: {
                  adUuid,
                },
              });

              if (link) {
                copy(link);
              }
            }}
            screenReaderLabel={LL.insights.details.shareModal.screenReaderLabel(
              {
                name: adName,
              }
            )}
            publicDescription={LL.insights.details.shareModal.publicDescription()}
            privateDescription={LL.insights.details.shareModal.privateDescription()}
          />
          {insightsOrganisationAdFacebook.data && (
            <PermissionManager
              entityType="InsightsOrganisationAdFacebook"
              entityUuid={insightsOrganisationAdFacebook.data.uuid}
              availableRoles={['viewer']}
              inviteViaEmailDefaultRole="viewer"
            />
          )}
        </div>
      )}
    </SimpleModal>
  );
};

export default ShareInsightsAdModal;
