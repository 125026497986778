import React from 'react';
import { Heading } from 'react-aria-components';
import { inferProcedureOutput } from '@trpc/server';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import {
  Controller,
  FieldError,
  FieldErrors,
  FormProvider,
  useFormContext,
} from 'react-hook-form';
import {
  convertFiltersV1ToV2,
  InsightsFilterNumeric,
  InsightsFilterV2,
  isInsightsFacebookFilterNumeric,
} from '@magicbrief/common';
import Input from 'src/components/Input';
import {
  useFilterFormFieldV2,
  useFilterFormV2,
} from 'src/pages/Insights/util/useFilterFormV2';
import { InsightsFilterFormFieldsRow } from 'src/pages/Insights/components/InsightsFilter/components/InsightsFilterMetricItemEdit';
import { parseValueForMetric } from '../../../../util/useParseMetric';

type Props = React.PropsWithChildren<{
  sprints: inferProcedureOutput<
    AppRouter['insights']['getSprintSettings']
  >['sprints'];
  currency: string;
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
  onSubmit: (
    values: Record<
      'new' | 'winners' | 'losers' | 'scaling',
      Array<InsightsFilterV2>
    >
  ) => void;
}>;

function getErrorForField(
  errors: FieldErrors<Record<string, { values: InsightsFilterV2[] }>>,
  section: 'new' | 'winners' | 'losers' | 'scaling',
  index: number,
  property: string
): string | undefined {
  const sectionErrors = errors[section];
  const filterErrors = sectionErrors?.values?.[index];
  if (filterErrors && property in filterErrors) {
    const propertyError = filterErrors[property as keyof typeof filterErrors];
    return (propertyError as FieldError)?.message as string | undefined;
  }
}

export const InsightsAnalysisSprintSettings: React.FC<Props> = ({
  sprints,
  currency,
  children,
  customEvents,
  customConversions,
  onSubmit,
}) => {
  const defaultValues = sprints.reduce<
    Record<string, { values: Array<InsightsFilterV2> }>
  >((acc, curr) => {
    acc[curr.id] = { values: convertFiltersV1ToV2(curr.filter) };
    return acc;
  }, {});

  const { handleSubmit, ...formMethods } = useFilterFormV2({
    defaultValues,
  });

  const submit = handleSubmit((values) => {
    // Synchronise spend metrics between scaling and winners
    const spend = values.scaling.values.find(
      (x): x is InsightsFilterNumeric => 'field' in x && x.field === 'spend'
    );

    if (!spend || !('value' in spend)) {
      throw new Error('Scaling needs to have a spend filter');
    }

    const clone: Record<
      'new' | 'winners' | 'losers' | 'scaling',
      Array<InsightsFilterV2>
    > = {
      new: values.new.values,
      losers: values.losers.values,
      scaling: values.scaling.values,
      winners: values.winners.values.map((x) =>
        isInsightsFacebookFilterNumeric(x) && x.field === 'spend'
          ? { ...x, value: spend.value }
          : x
      ),
    };

    onSubmit(clone);
  });

  return (
    <FormProvider handleSubmit={handleSubmit} {...formMethods}>
      <form className="flex min-w-0 flex-auto flex-col gap-3" onSubmit={submit}>
        {sprints.map((sprint) =>
          sprint.id === 'new' ? null : (
            <div
              key={sprint.id}
              className="flex flex-col gap-3 rounded-md border border-solid border-purple-300 bg-purple-50 px-5 py-4 text-purple-800"
            >
              <Heading className="truncate text-sm font-semibold">
                <span>{sprint.emoji} </span>
                <span>{sprint.name}</span>
              </Heading>

              <div className="grid grid-flow-row grid-cols-8 items-center gap-3">
                {sprint.id === 'scaling' && (
                  <ScalingSprintSettings currency={currency} />
                )}

                {sprint.id === 'winners' && (
                  <WinnersSprintSettings
                    sprints={sprints}
                    currency={currency}
                    customEvents={customEvents}
                    customConversions={customConversions}
                  />
                )}

                {sprint.id === 'losers' && (
                  <LosersSprintSettings
                    customEvents={customEvents}
                    customConversions={customConversions}
                  />
                )}
              </div>
            </div>
          )
        )}
        {children}
      </form>
    </FormProvider>
  );
};

const ScalingSprintSettings: React.FC<Pick<Props, 'currency'>> = ({
  currency,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const scalingError = getErrorForField(errors, 'scaling', 0, 'value');

  return (
    <>
      <p className="col-span-8 text-xs lg:col-span-4">
        Spend greater than or equal to
      </p>
      <div className="col-span-8 lg:col-span-4">
        <Input
          {...register('scaling.values.0.value', {
            valueAsNumber: true,
            required: true,
            min: 0,
          })}
          label=""
          className="h-10"
          suffixIcon={
            <div className="pointer-events-none border-l border-solid border-purple-300 pl-3 text-sm font-semibold">
              {currency}
            </div>
          }
        />
        {!!scalingError && (
          <p className="mt-2 text-xs text-danger sm:text-sm">Spend required</p>
        )}
      </div>
    </>
  );
};

const WinnersSprintSettings: React.FC<
  Pick<Props, 'currency' | 'sprints' | 'customEvents' | 'customConversions'>
> = ({ currency, sprints, customEvents, customConversions }) => {
  const { control } = useFormContext();
  const winnersFieldArray = useFilterFormFieldV2('winners.');
  return (
    <>
      {winnersFieldArray.fields.map((renderField, i) => {
        if ('field' in renderField && renderField.field === 'spend') {
          const scalingDetail = sprints.find((x) => x.id === 'scaling');
          return (
            <div
              key={renderField.id}
              className="col-span-8 flex flex-col rounded-md border border-solid border-purple-300 bg-white p-2 shadow-sm lg:h-10 lg:flex-row lg:items-center"
            >
              <p className="truncate border-b border-solid border-purple-300 pb-2 text-xs lg:border-b-0 lg:border-r lg:pb-0 lg:pr-2">
                {!!scalingDetail?.emoji && <span>{scalingDetail.emoji} </span>}
                <span>{scalingDetail?.name ?? 'Scaling'}</span>
              </p>
              {scalingDetail && (
                <Controller
                  control={control}
                  name="scaling.values.0.value"
                  render={({ field: { value }, fieldState: { error } }) => (
                    <div className="truncate pt-2 text-xs lg:pl-2 lg:pt-0">
                      {value != null && !error && (
                        <p className="text-text-secondary">
                          {`Spend greater than or equal to ${parseValueForMetric(
                            'spend',
                            value as string | number | Date,
                            currency
                          )}`}
                        </p>
                      )}
                      {!!error && (
                        <p className="text-danger">
                          Check your scaling setting for errors
                        </p>
                      )}
                    </div>
                  )}
                />
              )}
            </div>
          );
        }

        return (
          <div
            className="col-span-8 grid grid-flow-row auto-rows-fr grid-cols-1 gap-3 lg:auto-cols-fr lg:grid-flow-col"
            key={renderField.id}
          >
            <InsightsFilterFormFieldsRow
              prefix="winners."
              index={i}
              customEvents={customEvents}
              customConversions={customConversions}
            />
          </div>
        );
      })}
    </>
  );
};

const LosersSprintSettings: React.FC<
  Pick<Props, 'customEvents' | 'customConversions'>
> = ({ customEvents, customConversions }) => {
  const losersFieldArray = useFilterFormFieldV2('losers.');

  return (
    <>
      {losersFieldArray.fields.map((renderField, i) => (
        <div
          className="col-span-8 grid grid-flow-row auto-rows-fr grid-cols-1 gap-3 lg:auto-cols-fr lg:grid-flow-col"
          key={renderField.id}
        >
          <InsightsFilterFormFieldsRow
            prefix="losers."
            index={i}
            customEvents={customEvents}
            customConversions={customConversions}
          />
        </div>
      ))}
    </>
  );
};
