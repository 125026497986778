import { FC, PropsWithChildren, useId } from 'react';
import { Button } from 'react-aria-components';
import BaseCard from 'src/components/BaseCard/BaseCard';
import BaseCardHeader from 'src/components/BaseCard/BaseCardHeader';
import { InsightsMedia } from 'src/pages/Insights/components/InsightsMedia/InsightsMedia';
import { GetFacebookAdsStatisticsResponse } from 'src/types/insights';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import Copy01 from 'src/assets/svgicons/duocolor/copy-01.svg';
import { useCopyToClipboard } from 'src/utils/useCopyToClipboard';
import { Icon } from 'src/components/Icon';
import { useInsightsGroup } from '../../util/useInsightsPersistentState';
import { InsightsAdBreakdownPanel } from '../InsightsAdBreakdownPanel/InsightsAdBreakdownPanel';
import { InsightsCardMetrics } from './components/InsightsCardInsights/InsightsCardMetrics';
import type { InsightsAdGroup } from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';

type Props = {
  group: InsightsAdGroup;
  statistics: GetFacebookAdsStatisticsResponse | null;
  currency: string;
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
} & PropsWithChildren;

export const InsightsGroupCard: FC<Props> = ({
  group,
  statistics,
  currency,
  customConversions,
  customEvents,
}) => {
  const mediaId = useId();
  const selectedGroup = useInsightsGroup();

  // Inverse boolean variable used here as it won't require adding further checks if new groups are added in the future
  const showHeaderAsMedia =
    selectedGroup === 'copy' || selectedGroup === 'headline';

  return (
    <BaseCard>
      {/* For Copy and Headline, we display the text content in place of the media asset and do not render it as the card header */}
      {showHeaderAsMedia ? (
        <>
          <InsightsTextMedia description={group.group} />
          <GroupAdCountAndBreakdown group={group} />
        </>
      ) : (
        <InsightsMedia
          display="grid"
          assets={group.assets ?? []}
          adUuid={group.ads[0]?.uuid}
          mediaId={mediaId}
          creativeType="unknown"
          isPreview
        />
      )}

      {!showHeaderAsMedia && (
        <BaseCardHeader
          title={
            <div>
              <div
                className={cn(
                  'truncate',
                  !group.name && 'italic text-gray-700'
                )}
              >
                {group.name ?? 'None'}
              </div>
              <GroupAdCountAndBreakdown group={group} />
            </div>
          }
        />
      )}

      <InsightsCardMetrics
        currency={currency}
        statistics={statistics}
        metrics={group.metrics}
        customEvents={customEvents}
        customConversions={customConversions}
      />
    </BaseCard>
  );
};

function InsightsTextMedia({ description }: { description: string | null }) {
  const copy = useCopyToClipboard();

  return (
    <div className="group/media relative flex aspect-square w-full items-center justify-center rounded-md bg-secondary px-4 py-6">
      {description ? (
        <Button
          className="invisible absolute right-2 top-2 rounded-md p-1.5 hover:bg-purple-100 group-hover/media:visible focus:outline-none"
          onPress={() => copy(description)}
        >
          <Icon className="size-4 text-[#1F1444]">
            <Copy01 />
          </Icon>
        </Button>
      ) : null}

      <span className="text-md line-clamp-[8] text-center font-medium text-[#1F1444]">
        {description ?? 'None'}
      </span>
    </div>
  );
}

function GroupAdCountAndBreakdown({ group }: { group: InsightsAdGroup }) {
  const { LL } = useI18nContext();

  return (
    <div className="flex items-center gap-1">
      <div className="truncate text-xs font-normal text-gray-600">
        {LL.library.numAds({ count: group.count })}
      </div>
      <InsightsAdBreakdownPanel
        className="group-hover:visible"
        adGroup={group}
      />
    </div>
  );
}
