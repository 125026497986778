import { useLocation, useParams } from 'react-router-dom';
import { trpc } from 'src/lib/trpc';
import { generateSecondaryColorFromHex } from './colour';

const WHITE_LABELLED_PATHS = {
  insightsReport: '/insights/reports/share',
  insightsComparisonReport: '/insights/comparison-reports/share',
  board: '/board/share',
} as const;

export function useWhiteLabel() {
  const { pathname } = useLocation();
  const { uuid: boardUuid, reportUuid } = useParams();

  const insightsReport = trpc.insightsReports.getInsightsReport.useQuery(
    { uuid: reportUuid ?? '' },
    {
      enabled:
        pathname.startsWith(WHITE_LABELLED_PATHS.insightsReport) &&
        !!reportUuid,
    }
  );
  const insightsComparisonReport =
    trpc.insightsComparisonReports.getOne.useQuery(
      { uuid: reportUuid ?? '' },
      {
        enabled:
          pathname.startsWith(WHITE_LABELLED_PATHS.insightsComparisonReport) &&
          !!reportUuid,
      }
    );
  const board = trpc.directories.getDirectoryNodeDetail.useQuery(
    { uuid: boardUuid ?? '' },
    {
      enabled: pathname.startsWith(WHITE_LABELLED_PATHS.board) && !!boardUuid,
    }
  );

  const assetOwnerOrgUuid =
    insightsReport.data?.organisationUuid ||
    board.data?.Organisation?.uuid ||
    insightsComparisonReport.data?.Organisation.uuid;

  const orgWhiteLabel = trpc.organisation.getWhiteLabelData.useQuery(
    { organisationUuid: assetOwnerOrgUuid ?? '' },
    { enabled: !!assetOwnerOrgUuid }
  );

  const isWhiteLabelledPath = Object.values(WHITE_LABELLED_PATHS).some((path) =>
    pathname.startsWith(path)
  );

  if (!isWhiteLabelledPath || !orgWhiteLabel.data?.whiteLabelEnabled) {
    return null;
  }

  const whiteLabelData = {
    domain: orgWhiteLabel.data.organisationDomain
      ? orgWhiteLabel.data.organisationDomain.startsWith('http')
        ? orgWhiteLabel.data.organisationDomain
        : `https://${orgWhiteLabel.data.organisationDomain}`
      : undefined,
    logoURL: orgWhiteLabel.data.whiteLabelLogoURL ?? undefined,
    themeColor: orgWhiteLabel.data.themeColor ?? undefined,
    secondaryColor: orgWhiteLabel.data.themeColor
      ? generateSecondaryColorFromHex(orgWhiteLabel.data.themeColor)
      : undefined,
  };

  return whiteLabelData;
}
