import { type UserIndustry } from '@magicbrief/common';
import LightBulb03 from 'src/assets/svgicons/line/lightbulb-03.svg';
import BarChart12 from 'src/assets/svgicons/line/bar-chart-12.svg';
import Target04 from 'src/assets/svgicons/line/target-04.svg';
import File04 from 'src/assets/svgicons/line/file-04.svg';
import Building01 from 'src/assets/svgicons/line/building-01.svg';
import Container from 'src/assets/svgicons/line/container.svg';
import Stars01 from 'src/assets/svgicons/line/stars-01.svg';
import Apparel from 'src/assets/svgicons/line/apparel.svg';
import ShoppingBag01 from 'src/assets/svgicons/line/shopping-bag-01.svg';
import Building08 from 'src/assets/svgicons/line/building-08.svg';
import CPUChip01 from 'src/assets/svgicons/line/cpu-chip-01.svg';
import PlaySquare from 'src/assets/svgicons/line/play-square.svg';
import PiggyBank02 from 'src/assets/svgicons/line/piggy-bank-02.svg';
import ActivityHeart from 'src/assets/svgicons/line/activity-heart.svg';
import Food01 from 'src/assets/svgicons/line/food-01.svg';
import GamingPad01 from 'src/assets/svgicons/line/gaming-pad-01.svg';
import MedicalCross from 'src/assets/svgicons/line/medical-cross.svg';
import Home03 from 'src/assets/svgicons/line/home-03.svg';
import Category from 'src/assets/svgicons/line/category.svg';
import ImageIndentLeft from 'src/assets/svgicons/line/image-indent-left.svg';
import DotsHorizontal from 'src/assets/svgicons/line/dots-horizontal.svg';
import Pets02 from 'src/assets/svgicons/line/pets-02.svg';
import Flag01 from 'src/assets/svgicons/line/flag-01.svg';
import Home02 from 'src/assets/svgicons/line/home-02.svg';
import Settings01 from 'src/assets/svgicons/line/settings-01.svg';
import Trophy02 from 'src/assets/svgicons/line/trophy-02.svg';
import Train from 'src/assets/svgicons/line/train.svg';
import Plane from 'src/assets/svgicons/line/plane.svg';
import Briefcase02 from 'src/assets/svgicons/line/briefcase-02.svg';

export const interestsDefaultValues = {
  adInspiration: false,
  creativeAnalytics: false,
  trackCompetitors: false,
  createAndShareBriefs: false,
} as const;
export type InterestKey = keyof typeof interestsDefaultValues;

export const interestsIcons: Record<InterestKey, JSX.Element> = {
  adInspiration: <LightBulb03 />,
  creativeAnalytics: <BarChart12 />,
  trackCompetitors: <Target04 />,
  createAndShareBriefs: <File04 />,
} as const;

export const firstStepFields = [
  'displayName',
  'workspaceName',
  'interests',
] as const;

export const userIndustriesIcons: Record<UserIndustry, JSX.Element> = {
  agency: <Building01 />,
  app: <Container />,
  beauty: <Stars01 />,
  clothing: <Apparel />,
  consumerGoods: <ShoppingBag01 />,
  education: <Building08 />,
  electronics: <CPUChip01 />,
  entertainment: <PlaySquare />,
  finance: <PiggyBank02 />,
  fitness: <ActivityHeart />,
  foodBeverage: <Food01 />,
  games: <GamingPad01 />,
  healthcare: <MedicalCross />,
  home: <Home03 />,
  kids: <Category />,
  news: <ImageIndentLeft />,
  other: <DotsHorizontal />,
  pets: <Pets02 />,
  politics: <Flag01 />,
  realEstate: <Home02 />,
  services: <Settings01 />,
  sportsOutdoor: <Trophy02 />,
  transport: <Train />,
  travel: <Plane />,
  travelAccessories: <Briefcase02 />,
} as const;

export const defaultUserIndustries = [
  'agency',
  'app',
  'beauty',
  'clothing',
  'education',
  'foodBeverage',
  'fitness',
  'travel',
  'pets',
] as const satisfies UserIndustry[];
