import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate, useRouteError } from 'react-router-dom';
import MagicBriefLogoHalfColoured from 'src/assets/svgicons/magicbrief/MagicBriefLogoHalfColoured.svg';
import { AriaBaseButton } from 'src/components/Button/BaseButton';
import Navigation from 'src/components/Navigation/Navigation';
import DefaultFallback from './components/DefaultFallback/DefaultFallback';

const MagicBriefRouterErrorBoundary: React.FunctionComponent =
  (): JSX.Element => {
    const error = useRouteError() as Error;
    const navigate = useNavigate();
    useEffect(() => {
      Sentry.captureException(error, (scope) => {
        scope.setLevel('fatal');
        scope.setExtra('componentStack', error.stack);
        return scope;
      });
    }, [error]);

    return (
      <div>
        <Navigation.Base mobileMenu={null}>
          <AriaBaseButton
            onPress={() => {
              navigate('/');
            }}
          >
            <MagicBriefLogoHalfColoured className="h-7 max-w-[143px]" />
          </AriaBaseButton>
        </Navigation.Base>
        <DefaultFallback error={error} />
      </div>
    );
  };

export default MagicBriefRouterErrorBoundary;
