import type { PermissionRole } from '@magicbrief/prisma/generated/client2';

export const allRoles: Array<PermissionRole> = [
  'wizard',
  'superuser',
  'owner',
  'admin',
  'editor',
  'viewer',
  'unset',
];

export const companyTypes = ['agency', 'brand'] as const;
export type CompanyType = (typeof companyTypes)[number];

export const companyRoles = [
  'ownerOrLeadership',
  'mediaBuyerOrCreativeStrategist',
  'videoEditorOrMotionDesigner',
  'designerCreative',
  'brandManagerOrProducer',
  'socialMediaManager',
  'other',
] as const;
export type CompanyRole = (typeof companyRoles)[number];

export const companySizes = [
  '1',
  '1-5',
  '5-25',
  '25-100',
  '100-250',
  '250-1000',
  '1000+',
] as const;

export const userRoles = [
  'IndividualContributor',
  'Contractor',
  'Manager',
  'Director',
  'FounderExecutive',
] as const;

export type UserRole = (typeof userRoles)[number];

export const userSources = {
  FriendOrCoworker: 'FriendOrCoworker',
  Search: 'Search',
  Newsletter: 'Newsletter',
  SharedSwipefile: 'SharedSwipefile',
  SocialAd: 'SocialAd',
  Twitter: 'Twitter',
  Linkedin: 'Linkedin',
  Reddit: 'Reddit',
  Other: 'Other',
};

export type UserSource = (typeof userSources)[keyof typeof userSources];
