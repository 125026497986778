import { LoaderFunctionArgs } from 'react-router-dom';
import { getQueryKey } from '@trpc/react-query';
import { trpc, trpcProxyClient } from 'src/lib/trpc';
import { GetAdAccountResponse } from 'src/types/insights';
import queryClient from 'src/lib/queryClient';
import { auth } from 'src/lib/firebase';
import { getInsightsSearchParams } from '../../util/useInsightsSearchParams';

export async function insightsAnalysisRouteLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const accountUuid = params.accountUuid ?? '';

  await auth.authStateReady();

  const account = await queryClient.ensureQueryData<GetAdAccountResponse>(
    getQueryKey(
      trpc.insightsAccounts.getAdAccount,
      { uuid: accountUuid },
      'query'
    ),
    async () => {
      const data = await trpcProxyClient.insightsAccounts.getAdAccount.query({
        uuid: accountUuid,
      });

      return data;
    }
  );

  const queryParams = getInsightsSearchParams(
    new URL(request.url).searchParams
  );

  const forTimePeriod =
    queryParams.timePeriod === 'custom'
      ? {
          datePreset: 'custom' as const,
          since: queryParams.since ?? '',
          until: queryParams.until ?? '',
        }
      : {
          datePreset: queryParams.timePeriod,
        };

  await auth.authStateReady();

  void queryClient.prefetchQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getQueryKey(
      trpc.insights.getSprintCounts,
      {
        uuid: accountUuid,
        forTimePeriod,
        attributionWindow: queryParams.attributionWindow,
      },
      'query'
    ),
    queryFn: async () =>
      trpcProxyClient.insights.getSprintCounts.query(
        {
          uuid: accountUuid,
          forTimePeriod,
          attributionWindow: queryParams.attributionWindow,
        },
        { context: { skipBatch: true } }
      ),
  });

  void queryClient.prefetchQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getQueryKey(
      trpc.insights.getSprintSettings,
      { uuid: accountUuid },
      'query'
    ),
    queryFn: async () =>
      trpcProxyClient.insights.getSprintSettings.query(
        { uuid: accountUuid },
        { context: { skipBatch: true } }
      ),
  });

  return account;
}
