import {
  RadioGroup,
  Radio,
  Label,
  RadioGroupProps,
  RadioProps,
} from 'react-aria-components';
import classNames from 'classnames';

type Props = React.PropsWithChildren<{
  groupAriaLabel: string;
  sizing?: 'evenly' | 'min' | 'max';
}> &
  Omit<RadioGroupProps, 'orientation' | 'children'>;

export const HorizontalTabbedRadioGroup: React.FC<Props> = ({
  groupAriaLabel,
  children,
  sizing = 'min',
  ...props
}) => {
  return (
    <RadioGroup orientation="horizontal" {...props}>
      <Label className="sr-only">{groupAriaLabel}</Label>

      <div
        className={classNames(
          'group grid grid-flow-col overflow-hidden rounded-lg bg-[#EEE9FF] shadow-sm outline outline-1 -outline-offset-1 outline-purple-200',
          sizing === 'evenly'
            ? 'auto-cols-fr'
            : sizing === 'min'
            ? 'auto-cols-min'
            : 'auto-cols-max'
        )}
      >
        {children}
      </div>
    </RadioGroup>
  );
};

export const HorizontalTabbedRadio: React.FC<RadioProps> = ({
  className,
  children,
  ...props
}) => (
  <Radio
    {...props}
    className={(params) =>
      classNames(
        typeof className === 'function' ? className(params) : className,
        'group flex h-10 min-w-[2.5rem] cursor-pointer items-center justify-center whitespace-nowrap border border-solid border-transparent p-2 text-sm font-semibold text-primary first:rounded-l-lg last:rounded-r-lg selected:rounded-lg selected:border-purple-200 selected:bg-white selected:shadow-sm disabled:cursor-not-allowed disabled:opacity-50'
      )
    }
  >
    {children}
  </Radio>
);
