import { isEqual } from 'lodash';
import { useParams } from 'react-router-dom';
import { useI18nContext } from 'src/i18n/i18n-react';
import dayjs from 'src/lib/dayjs';
import { trpc } from 'src/lib/trpc';
import { useInsightsSearchParams } from './useInsightsSearchParams';

export function useInsightsJob() {
  const { accountUuid } = useParams();
  const { getParsedValues } = useInsightsSearchParams();
  const { forTimePeriod } = getParsedValues();

  const syncQuery = trpc.insightsAccounts.getAdAccountSyncStatus.useQuery(
    {
      adAccountUuid: accountUuid ?? '',
    },
    {
      enabled: !!accountUuid,
      refetchInterval(data) {
        if (!Array.isArray(data)) return false;
        const matchingJob = data?.find((x) =>
          isEqual(x.forTimePeriod, forTimePeriod)
        );
        return matchingJob?.status === 'processing'
          ? 5 /* 5 sec */ * 1000
          : false;
      },
    }
  );

  const matchingJob = syncQuery.data?.find((x) =>
    isEqual(x.forTimePeriod, forTimePeriod)
  );
  return matchingJob;
}

export function useInsightsLastSyncedTime() {
  const { LL } = useI18nContext();
  const job = useInsightsJob();

  if (!job) {
    return null;
  }

  switch (job.status) {
    case 'completed':
    case 'failed':
      return LL.insights.list.refresh.subtitle.complete({
        time: dayjs(job.asOfTime).from(dayjs(), true),
      });
    case 'processing':
      return LL.insights.list.refresh.subtitle.pending({
        time: dayjs(job.asOfTime).from(dayjs(), true),
      });
  }
}
