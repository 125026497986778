import { createContext, useContext, useMemo } from 'react';
import { useI18nContext } from 'src/i18n/i18n-react';
import useFeatureFlag from 'src/utils/useFeatureFlag';

type NavigationLink = {
  to: string;
  label: string;
};

export function useDefaultNavigationLinks(): NavigationLink[] {
  const { LL } = useI18nContext();
  const isAssetsUiEnabled = useFeatureFlag('ASSETS_UI');

  const links = useMemo((): NavigationLink[] => {
    return [
      {
        to: '/library',
        label: LL.navigation.adLibrary(),
      },
      {
        to: '/insights',
        label: LL.navigation.insights(),
      },
      { to: '/briefs', label: LL.navigation.briefs() },
      ...(isAssetsUiEnabled
        ? [
            {
              to: '/assets',
              label: LL.navigation.assets(),
            },
          ]
        : []),
    ];
  }, [LL.navigation, isAssetsUiEnabled]);
  return links;
}

type NavigationContextState = {
  isMobileMenuOpen: boolean;
};

export const NavigationContext = createContext<boolean>(false);

export function useNavigationContext(): NavigationContextState {
  const context = useContext(NavigationContext);
  const state = useMemo(
    (): NavigationContextState => ({
      isMobileMenuOpen: context,
    }),
    [context]
  );
  return state;
}
