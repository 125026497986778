import {
  ProgressBar as AriaProgressBar,
  Label,
  type ProgressBarProps as AriaProgressBarProps,
} from 'react-aria-components';
import { HTMLMotionProps, motion } from 'framer-motion';

export type ProgressBarProps = AriaProgressBarProps & {
  label?: string;
  showValueText?: boolean;
  motionProps?: HTMLMotionProps<'div'>;
};

const ProgressBar = ({
  label,
  showValueText = false,
  motionProps,
  value,
  ...props
}: ProgressBarProps) => {
  const normalizedValue = Math.min(Math.max(Number(value) || 0, 0), 100);

  return (
    <AriaProgressBar
      className="flex grow items-center gap-3 text-sm"
      aria-label="Progress bar"
      value={normalizedValue}
      {...props}
    >
      {({ percentage, valueText }) => (
        <>
          {label && <Label>{label}</Label>}
          <div className="h-2 w-full rounded-full bg-purple-100">
            <motion.div
              className="h-full rounded-full bg-purple-700"
              initial={{ width: 0 }}
              animate={{ width: percentage + '%' }}
              {...motionProps}
            />
          </div>
          {showValueText && <span>{valueText}</span>}
        </>
      )}
    </AriaProgressBar>
  );
};

export default ProgressBar;
