import { ComponentProps, FC } from 'react';
import { SimpleModal } from 'src/components/AriaModal';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import Spinner from 'src/components/Loaders/Spinner';
import { Alert } from 'src/components/Alert';
import ShareBriefSettings from '../../routes/Brief/components/ShareBriefSettings/ShareBriefSettings';

type Props = Omit<ComponentProps<typeof SimpleModal>, 'title'> & {
  briefUuid: string;
  briefName: string;
};

const ShareBriefModal: FC<Props> = ({
  briefName,
  briefUuid,
  ...rest
}): JSX.Element => {
  const { LL } = useI18nContext();
  const brief = trpc.briefs.getBriefByUuid.useQuery({ uuid: briefUuid });

  return (
    <SimpleModal
      className="min-w-md w-full max-w-xl rounded-xl bg-white pb-8"
      title={LL.shareItem({
        name: briefName,
      })}
      {...rest}
    >
      <div className="text-primary">
        {brief.error && <Alert type="error">{brief.error.message}</Alert>}
        {brief.isLoading && <Spinner />}
        {brief.data && <ShareBriefSettings brief={brief.data} />}
      </div>
    </SimpleModal>
  );
};

export default ShareBriefModal;
