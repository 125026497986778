import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useRedirect } from '../utils/useRedirect';

export const Redirector: FC = () => {
  const redirect = useRedirect();

  const homePath = `/library/discover`;

  return (
    <Navigate to={redirect.shouldRedirect ? redirect.redirectPath : homePath} />
  );
};
