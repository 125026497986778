import {
  Button,
  Dialog,
  Heading,
  Modal,
  ModalOverlay,
} from 'react-aria-components';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import { AriaButton } from 'src/components/Button/Button';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { Alert } from 'src/components/Alert';
import AlertOctagon from 'src/assets/svgicons/duocolor/alert-octagon.svg';
import { useEditDirectory } from 'src/utils/useDirectories';
import Input from 'src/components/Input';

type Props = {
  onOpenChange: (isOpen: boolean) => void;
  entity: {
    directoryUuid: string;
    name: string;
  } | null;
};

const validator = z.object({
  name: z.string().min(1),
});

export const EditDirectoryModal: React.FC<Props> = ({
  onOpenChange,
  entity,
  ...props
}): JSX.Element => {
  return (
    <ModalOverlay
      isOpen={!!entity}
      onOpenChange={onOpenChange}
      className="fixed inset-0 z-500 flex min-h-full items-center justify-center overflow-y-auto bg-gray-900/60 p-4 text-center"
    >
      <Modal className="flex max-h-[90%] w-full max-w-md rounded-xl bg-white p-6 text-left align-middle shadow-xl">
        <Dialog className="flex min-h-0 min-w-0 flex-auto outline-none">
          {({ close }) => (
            <>
              {entity && (
                <EditDirectoryModalBody
                  close={close}
                  entity={entity}
                  {...props}
                />
              )}
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

type EditDirectoryModalBodyProps = {
  close: () => void;
  entity: Exclude<Props['entity'], null>;
};

const EditDirectoryModalBody: React.FC<EditDirectoryModalBodyProps> = ({
  close,
  entity,
}) => {
  const { LL } = useI18nContext();

  const mutate = useEditDirectory();

  type FormData = z.infer<typeof validator>;

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<FormData>({
    defaultValues: {
      name: entity.name,
    },
    resolver: zodResolver(validator),
  });

  const submit = handleSubmit(async ({ name }) => {
    clearErrors();
    mutate.mutate(
      {
        uuid: entity.directoryUuid,
        name,
      },
      {
        async onSuccess() {
          close();
          toast('Board updated', {
            className: 'toast-success',
          });
        },
      }
    );
  });

  return (
    <form className="flex min-w-0 flex-auto flex-col gap-4" onSubmit={submit}>
      <div className="flex flex-row items-center justify-between gap-4 text-purple-800">
        <Heading className="truncate text-xl font-bold">
          {`Edit ${entity.name}`}
        </Heading>
        <Button onPress={close}>
          <Icon>
            <XClose />
          </Icon>
        </Button>
      </div>

      {mutate.error && (
        <Alert
          icon={
            <Icon>
              <AlertOctagon />
            </Icon>
          }
          className="w-full"
          type="error"
        >
          {mutate.error && (
            <p className="text-xs font-normal">
              <p>
                The following error occurred while trying to edit the board:
              </p>
              <ul className="list-disc pl-2 pt-2">{mutate.error.message}</ul>
            </p>
          )}
        </Alert>
      )}

      <Input
        {...register('name')}
        label={LL.labels.name()}
        disabled={mutate.isLoading}
        error={errors.name?.message}
      />

      <AriaButton htmlType="submit" loading={mutate.isLoading}>
        {LL.submit()}
      </AriaButton>
    </form>
  );
};
