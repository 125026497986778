import { FC } from 'react';
import { ArrayElement } from '@magicbrief/common';
import { GetInAppNotificationsResponse } from '@magicbrief/server/src/notifications/notifications.trpc';
import { captureException } from '@sentry/react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import FolderReport from 'src/assets/svgicons/custom/FolderReport.svg';
import FolderReportCompare from 'src/assets/svgicons/custom/FolderReportCompare.svg';
import Skeleton02 from 'src/assets/svgicons/solid/skeleton02.svg';
import Skeleton03 from 'src/assets/svgicons/solid/skeleton03.svg';
import File02 from 'src/assets/svgicons/duocolor/file-02.svg';
import Folder from 'src/assets/svgicons/duocolor/folder.svg';
import Mail01 from 'src/assets/svgicons/duocolor/mail-01.svg';
import MessageSquare02 from 'src/assets/svgicons/line/message-square-02.svg';
import MagicBriefBolt from 'src/assets/svgicons/magicbrief/MagicBriefBolt.svg';
import { Icon } from 'src/components/Icon';
import Avatar from 'src/components/Misc/Avatar';
import { useI18nContext } from 'src/i18n/i18n-react';
import dayjs from 'src/lib/dayjs';
import { trpc } from 'src/lib/trpc';
import { useUserAndOrganisation } from '../../utils/useUserAndOrganisation';

type Props = {
  notification: ArrayElement<GetInAppNotificationsResponse>;
  onClicked?: () => void;
  className?: string;
};

const NotificationItem: FC<Props> = ({
  notification,
  onClicked,
  className,
}: Props) => {
  const { LL } = useI18nContext();
  const navigate = useNavigate();
  const user = useUserAndOrganisation();

  const acknowledgeNotification =
    trpc.notifications.acknowledgeNotification.useMutation({
      onSuccess: () => {
        onClicked?.();
      },
    });

  const sentAtTimeAgo = dayjs(notification.createdAt).from(dayjs(), true);

  const getIconForNotification = () => {
    let icon;

    if (notification.icon === 'MagicBriefBolt') {
      icon = <MagicBriefBolt />;
    } else if (notification.icon === 'File02') {
      icon = <File02 />;
    } else if (notification.icon === 'MessageSquare02') {
      icon = <MessageSquare02 />;
    } else if (notification.icon === 'FolderReport') {
      icon = <FolderReport />;
    } else if (notification.icon === 'FolderReportCompare') {
      icon = <FolderReportCompare />;
    } else if (notification.icon === 'Skeleton02') {
      icon = <Skeleton02 />;
    } else if (notification.icon === 'Skeleton03') {
      icon = <Skeleton03 />;
    } else if (notification.icon === 'Folder') {
      icon = <Folder />;
    } else {
      icon = <Mail01 />;
    }

    return <Icon>{icon}</Icon>;
  };

  const handleInviteEvent = () => {
    const { metadata } = notification.action;
    const searchParams = new URLSearchParams(window.location.toString());

    if (metadata.entityType === 'Organisation') {
      if (user.data?.organisation.uuid !== metadata.entityUuid) {
        navigate(
          `/organisations/switch/${metadata.entityUuid}${
            searchParams.size ? `?${searchParams.toString()}` : ''
          }`
        );
      }
    } else if (metadata.entityType === 'Brief') {
      navigate(
        `/briefs/${metadata.entityUuid}${
          searchParams.size
            ? `${searchParams.size ? `?${searchParams.toString()}` : ''}`
            : ''
        }`
      );
    } else if (metadata.entityType === 'InsightsReport') {
      navigate(
        `/insights/reports/share/${notification.action.metadata.entityUuid}?${
          searchParams.size ? `?${searchParams.toString()}` : ''
        }`
      );
    } else if (metadata.entityType === 'InsightsComparisonReport') {
      navigate(
        `/insights/comparison-reports/share/${metadata.entityUuid}${
          searchParams.size ? `?${searchParams.toString()}` : ''
        }`
      );
    } else if (metadata.entityType === 'InsightsOrganisationAdFacebook') {
      navigate(
        `/insights/ads/share/${metadata.entityUuid}${
          searchParams.size ? `?${searchParams.toString()}` : ''
        }`
      );
    } else if (metadata.entityType === 'DirectoryNode') {
      navigate(
        `/library/d/${metadata.entityUuid}${
          searchParams.size ? `?${searchParams.toString()}` : ''
        }`
      );
    } else {
      console.debug("Entity type not yet supported for 'invite' action");
      captureException(
        new Error("Entity type not yet supported for 'invite' action"),
        (scope) => {
          scope.setTransactionName('NotificationItem.handleInviteEvent');
          return scope;
        }
      );
    }
  };

  const handleMentionEvent = () => {
    const { metadata } = notification.action;
    const searchParams = new URLSearchParams(window.location.toString());
    searchParams.append('show-comments', 'true');

    if (metadata.entityType === 'OrganisationAd') {
      navigate(
        `/ad/${metadata.entityUuid}${
          searchParams.size ? `?${searchParams.toString()}` : ''
        }`
      );
    } else if (metadata.entityType === 'Storyboard') {
      navigate(
        `/briefs/concepts/${metadata.entityUuid}${
          searchParams.size ? `?${searchParams.toString()}` : ''
        }`
      );
    } else if (metadata.entityType === 'Brief') {
      navigate(
        `/briefs/${metadata.entityUuid}${
          searchParams.size ? `?${searchParams.toString()}` : ''
        }`
      );
    } else if (metadata.entityType === 'Asset') {
      searchParams.append('asset-uuid', metadata.entityUuid);
      navigate(
        `/assets/${searchParams.size ? `?${searchParams.toString()}` : ''}`
      );
    } else if (metadata.entityType === 'InsightsOrganisationAdFacebook') {
      navigate(
        `/insights/ads/share/${metadata.entityUuid}${
          searchParams.size ? `?${searchParams.toString()}` : ''
        }`
      );
    } else {
      console.debug("Entity type not yet supported for 'mention' action");
      captureException(
        new Error("Entity type not yet supported for 'mention' action"),
        (scope) => {
          scope.setTransactionName('NotificationItem.handleMentionEvent');
          return scope;
        }
      );
    }
  };

  const handleStatusChangeEvent = () => {
    const { metadata } = notification.action;
    const searchParams = new URLSearchParams(window.location.toString());

    if (metadata.entityType === 'Brief') {
      navigate(
        `/briefs/${metadata.entityUuid}${
          searchParams.size ? `?${searchParams.toString()}` : ''
        }`
      );
    } else {
      console.debug("Entity type not yet supported for 'statusChange' action");
      captureException(
        new Error("Entity type not yet supported for 'statusChange' action"),
        (scope) => {
          scope.setTransactionName('NotificationItem.handleStatusChangeEvent');
          return scope;
        }
      );
    }
  };

  const handleStageChangeEvent = () => {
    const { metadata } = notification.action;
    const searchParams = new URLSearchParams(window.location.toString());

    if (metadata.entityType === 'Brief') {
      navigate(
        `/briefs/${metadata.entityUuid}${
          searchParams.size ? `?${searchParams.toString()}` : ''
        }`
      );
    } else {
      console.debug("Entity type not yet supported for 'stageChange' action");
      captureException(
        new Error("Entity type not yet supported for 'stageChange' action"),
        (scope) => {
          scope.setTransactionName('NotificationItem.handleStageChangeEvent');
          return scope;
        }
      );
    }
  };

  const handleDueDateChangeEvent = () => {
    const { metadata } = notification.action;
    const searchParams = new URLSearchParams(window.location.toString());

    if (metadata.entityType === 'Brief') {
      navigate(
        `/briefs/${metadata.entityUuid}${
          searchParams.size ? `?${searchParams.toString()}` : ''
        }`
      );
    } else {
      console.debug("Entity type not yet supported for 'dueDate' action");
      captureException(
        new Error("Entity type not yet supported for 'dueDate' action"),
        (scope) => {
          scope.setTransactionName('NotificationItem.handleDueDateChangeEvent');
          return scope;
        }
      );
    }
  };

  const handleAssignedChangeEvent = () => {
    const { metadata } = notification.action;
    const searchParams = new URLSearchParams(window.location.toString());

    if (metadata.entityType === 'Brief') {
      navigate(
        `/briefs/${metadata.entityUuid}${
          searchParams.size ? `?${searchParams.toString()}` : ''
        }`
      );
    } else {
      console.debug("Entity type not yet supported for 'assigned' action");
      captureException(
        new Error("Entity type not yet supported for 'assigned' action"),
        (scope) => {
          scope.setTransactionName(
            'NotificationItem.handleAssignedChangeEvent'
          );
          return scope;
        }
      );
    }
  };

  const handleOnClick = async () => {
    if (notification.readAt == null) {
      await acknowledgeNotification.mutateAsync({
        uuid: notification.uuid,
        mediumUuid: notification.mediumUuid,
        action: 'click',
      });
    }

    if (notification.action.type === 'invite') {
      handleInviteEvent();
    } else if (notification.action.type === 'mention') {
      handleMentionEvent();
    } else if (
      /** @todo clean this up when we move to new 'type' model */
      notification.action.type === 'statusChange' ||
      notification.action.type === 'brief_status-change'
    ) {
      handleStatusChangeEvent();
    } else if (notification.action.type === 'stageChange') {
      handleStageChangeEvent();
    } else if (notification.action.type === 'dueDateChange') {
      handleDueDateChangeEvent();
    } else if (notification.action.type === 'assignedChange') {
      handleAssignedChangeEvent();
    } else {
      console.debug('Action type not yet supported');
      captureException(new Error('Action type not yet supported'), (scope) => {
        scope.setTransactionName('NotificationItem.handleOnClick');
        return scope;
      });
    }
  };

  const icon = getIconForNotification();

  return (
    <div
      tabIndex={0}
      role="button"
      className={classNames(
        'flex flex-row items-center gap-2 p-4',
        notification.readAt == null && 'bg-purple-100',
        className
      )}
      onClick={handleOnClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          void handleOnClick();
        }
      }}
    >
      <div>
        <Avatar
          className="h-9 w-9"
          src={notification.imgUrl}
          fallbackIcon={icon}
        />
      </div>
      <div className="overflow-hidden text-sm text-primary">
        {notification.contentAsHtml != null && (
          <p
            className="line-clamp-1 text-ellipsis"
            dangerouslySetInnerHTML={{
              __html: notification.contentAsHtml,
            }}
          />
        )}
        {notification.contentAsHtml == null &&
          notification.contentAsText != null && (
            <p className="line-clamp-1 text-ellipsis">
              {notification.contentAsText}
            </p>
          )}
        <p className="text-xs text-purple-300">
          {LL.general.timeAgo({ time: sentAtTimeAgo })}
        </p>
      </div>
    </div>
  );
};

export default NotificationItem;
