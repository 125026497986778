import { FC } from 'react';
import {
  INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE,
  InsightsFacebookWizardMetric,
  IntRange,
  isInsightsFacebookMetricNumeric,
} from '@magicbrief/common';
import { GetFacebookAdsStatisticsResponse } from 'src/types/insights';
import { useInsightsDisplay } from 'src/pages/Insights/util/useInsightsPersistentState';
import { getSentimentColor } from '../../../../util/getSentimentColor';
import {
  getMetricValue,
  getValueForMetric,
  useParseMetric,
} from '../../../../util/useParseMetric';
import { InsightsMetricLabelTooltip } from '../../../InsightsMetricLabelTooltip/InsightsMetricLabelTooltip';
import { AdMetadataCell } from './AdMetadataCell';
import type { InsightsAdMetrics } from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';

type Props = {
  metrics: InsightsAdMetrics;
  statistics: GetFacebookAdsStatisticsResponse | null;
  currency: string;
  mediaId?: string;
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
};

export const InsightsCardMetrics: FC<Props> = ({
  metrics,
  statistics,
  currency,
  customEvents,
  customConversions,
}) => {
  const display = useInsightsDisplay();
  const { getMetricLabelAsText } = useParseMetric();

  const calculateSentimentValues = (metric: string) => {
    const isSpend = metric === 'spend';
    const isNumericalMetric = isInsightsFacebookMetricNumeric(metric);

    const isCustomMetric = metric.startsWith('custom:');

    let diffFromWeightedAvg = null;
    let sentimentColor = '#F4F4F4';

    if (!isSpend && (isNumericalMetric || isCustomMetric) && statistics) {
      const rawMetricValue = getValueForMetric(metric, metrics);
      const average =
        statistics[metric as keyof typeof statistics]?.weighted_avg;
      const min = statistics[metric as keyof typeof statistics]?.min;
      const max = statistics[metric as keyof typeof statistics]?.max;

      if (
        typeof rawMetricValue === 'number' &&
        average != null &&
        min != null &&
        max != null
      ) {
        diffFromWeightedAvg = average
          ? (rawMetricValue - average) / average
          : 0;

        const lowerTail = average - min;
        const upperTail = max - average;

        const changeOutcome =
          INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE[
            metric as keyof typeof INSIGHTS_FACEBOOK_TIME_SERIES_ALL_REFERENCE
          ]?.higherIs;

        const positiveColors = [
          { color: { r: 244, g: 244, b: 244 }, position: 50 },
          { color: { r: 244, g: 244, b: 244 }, position: 55 },
          { color: { r: 104, g: 220, b: 143 }, position: 100 },
        ];
        const negativeColors = [
          { color: { r: 244, g: 244, b: 244 }, position: 50 },
          { color: { r: 244, g: 244, b: 244 }, position: 55 },
          { color: { r: 104, g: 220, b: 143 }, position: 100 },
        ];

        if (rawMetricValue > average) {
          const percentage = (rawMetricValue - average) / upperTail;
          const percentageAsWholeNumber = (percentage * 100) as IntRange<
            0,
            100
          >;

          if (changeOutcome === 'positive') {
            sentimentColor = getSentimentColor({
              percentage: percentageAsWholeNumber,
              colors: positiveColors,
              defaultColor: '#F4F4F4',
              gradient: false,
            });
          } else {
            sentimentColor = '#F4F4F4';
          }
        } else if (rawMetricValue < average) {
          const percentage = (average - rawMetricValue) / lowerTail;
          const percentageAsWholeNumber = (percentage * 100) as IntRange<
            0,
            100
          >;

          if (changeOutcome === 'positive') {
            sentimentColor = '#F4F4F4';
          } else {
            sentimentColor = getSentimentColor({
              percentage: percentageAsWholeNumber,
              colors: negativeColors,
              defaultColor: '#F4F4F4',
              gradient: false,
            });
          }
        } else {
          sentimentColor = '#F4F4F4';
        }
      }
    }
    return { sentimentColor, diffFromWeightedAvg };
  };

  return (
    <div className="flex min-w-0 flex-col gap-1">
      {display.map((metric) => {
        const label = getMetricLabelAsText(
          metric,
          customEvents,
          customConversions
        );

        // we don't check value here because we want to show 'None' on the cards
        if (label == null) {
          return null;
        }

        const { diffFromWeightedAvg, sentimentColor } =
          calculateSentimentValues(metric);

        const isWizardScore = metric.startsWith('wizard');

        const parsedValue = getMetricValue(metric, metrics, currency);

        return (
          <AdMetadataCell
            key={metric}
            title={
              <InsightsMetricLabelTooltip
                metric={metric}
                customConversions={customConversions}
                customEvents={customEvents}
              />
            }
            value={parsedValue}
            diffFromWeightedAvg={diffFromWeightedAvg}
            sentimentColor={sentimentColor}
            isWizardScore={isWizardScore}
            metric={metric as InsightsFacebookWizardMetric}
          />
        );
      })}
    </div>
  );
};
