import type { LibraryFilterContextValue } from './LibraryFilterContext';

export const InitialLibraryFilterContextValue: LibraryFilterContextValue = {
  brands: [],
  format: [],
  industries: [],
  savedBySelf: undefined,
  search: undefined,
  source: [],
  supportedPlatforms: [],
  themes: [],
  scores: [],
  runTime: [],
  languages: [],
  liveStatus: [],
  headlines: [],
  hooks: [],
  tags: [],
  landingPages: [],
  adCopy: [],
  textContent: [],
};
