import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ChevronDown from 'src/assets/svgicons/line/chevron-down.svg';
import { Icon } from 'src/components/Icon';
import { Menu } from 'src/components/Menu';

type Props = {
  title: string | React.ReactNode;
  subtitle?: string;
  link: string | null;
  icon?: React.ReactNode;
  items?: React.ComponentProps<typeof Menu<React.ElementType>>['items'];
  className?: string;
};

const defaultClassName =
  'flex appearance-none flex-row items-center gap-3 text-base font-semibold [&>svg]:shrink-0 min-w-0 text-primary py-2';

const ToolbarTitleMenu: React.FunctionComponent<Props> = ({
  icon,
  title,
  subtitle,
  items,
  link,
  className,
}): JSX.Element => {
  const titleEl = (
    <span className={`truncate ${subtitle ? 'opacity-50' : ''}`}>{title}</span>
  );
  const bodyEl = (
    <>
      {icon}
      {link ? <Link to={link}>{titleEl}</Link> : titleEl}
      {subtitle && (
        <>
          <span className="opacity-50">/</span>
          <span className="truncate">{subtitle}</span>
        </>
      )}
    </>
  );

  if (!items) {
    return (
      <span className={classNames(defaultClassName, className)}>{bodyEl}</span>
    );
  }

  return (
    <Menu
      as="button"
      className={classNames(defaultClassName, className)}
      rootClassName="min-w-0"
      items={items}
    >
      {bodyEl}
      <Icon>
        <ChevronDown />
      </Icon>
    </Menu>
  );
};

export default ToolbarTitleMenu;
