import { FC, useState } from 'react';
import { Text } from 'react-aria-components';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert } from 'src/components/Alert';
import { SimpleModal } from 'src/components/Modal';
import { useI18nContext } from 'src/i18n/i18n-react';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import ChevronSelectorVertical from '../../../assets/svgicons/line/chevron-selector-vertical.svg';
import { AriaMenu } from '../../../components/AriaMenu/AriaMenu';
import { AriaButton } from '../../../components/Button/Button';
import { Icon } from '../../../components/Icon';
import { trpc } from '../../../lib/trpc';
import { useUserAndOrganisation } from '../../../utils/useUserAndOrganisation';

type Props = {
  reportUuid: string;
  reportName: string;
  show: boolean;
  onClose: (accountUuid?: string, reportUuid?: string) => void;
  reportType: 'report' | 'comparison-report';
};

const DuplicateReportModal: FC<Props> = ({
  reportUuid,
  reportName,
  show,
  reportType,
  onClose,
}) => {
  const { LL } = useI18nContext();
  const navigate = useNavigate();
  const { accountUuid } = useParams();
  const trpcUtils = trpc.useUtils();
  const { recordEvent } = useNewAnalyticsEvent();

  const user = useUserAndOrganisation();

  const integrations = trpc.integrations.getIntegrations.useQuery(undefined, {
    enabled: !!user.data,
  });
  const facebookIntegrations =
    integrations.data?.filter(
      (integration) =>
        integration.integrationType === 'facebook' ||
        integration.integrationType === 'tiktok'
    ) || [];

  const adAccounts = trpc.insightsAccounts.getManyAdAccounts.useQuery(
    undefined,
    { enabled: !facebookIntegrations.length }
  );

  const duplicateInsightsReport =
    trpc.insightsReports.duplicateInsightsReport.useMutation({
      onSuccess: (data) => {
        void trpcUtils.insightsReports.getManyInsightsReports.invalidate();
        void trpcUtils.insightsReports.getManyInsightsReportsAndComparisonReports.invalidate();
        toast.success(LL.insights.reports.duplicateModal.success(), {
          className: 'toast-success',
        });
        onClose();
        navigate(
          `/insights/accounts/${data.insightsAdAccountFacebookUuid}/reports/${data.uuid}`
        );
      },
    });

  const duplicateInsightsComparisonReport =
    trpc.insightsComparisonReports.duplicate.useMutation({
      onSuccess: (data, vars) => {
        void trpcUtils.insightsReports.getManyInsightsReports.invalidate();
        void trpcUtils.insightsReports.getManyInsightsReportsAndComparisonReports.invalidate();
        toast.success(LL.insights.reports.duplicateModal.success(), {
          className: 'toast-success',
        });
        onClose();
        navigate(
          `/insights/accounts/${vars.facebookAdAccountUuid}/comparison-reports/${data.uuid}`
        );
      },
    });

  const isLoading =
    duplicateInsightsReport.isLoading ||
    duplicateInsightsComparisonReport.isLoading;

  const [adAccountUuid, setAdAccountUuid] = useState(accountUuid);

  const currentAdAccount = adAccounts.data?.find(
    (adAccount) => adAccount.uuid === adAccountUuid
  );

  return (
    <SimpleModal
      title={LL.insights.reports.duplicateModal.title({ name: reportName })}
      show={show}
      onClose={duplicateInsightsReport.isLoading ? null : onClose}
    >
      <div className="flex flex-col gap-3">
        {duplicateInsightsReport.error && (
          <Alert type="error">
            {LL.errors.genericWithDetail({
              detail: duplicateInsightsReport.error.message,
            })}
          </Alert>
        )}

        <div className="flex flex-col gap-2">
          <p className="text-sm text-primary">
            {LL.insights.reports.duplicateModal.detail()}
          </p>
        </div>
        <AriaMenu>
          <AriaButton
            className="grow-1 flex w-full flex-row justify-between font-semibold"
            variant="secondary"
          >
            <span className="truncate">{currentAdAccount?.name}</span>
            <Icon className="h-5 w-5 shrink-0">
              <ChevronSelectorVertical />
            </Icon>
          </AriaButton>
          <AriaMenu.List
            onAction={(key) => {
              setAdAccountUuid(key as string);
            }}
          >
            {adAccounts.data?.map((facebookAdAccount) => (
              <AriaMenu.Item
                key={facebookAdAccount.uuid}
                id={facebookAdAccount.uuid}
                showCheckbox={false}
                className="overflow-hidden"
                contentClassName="truncate"
              >
                <Text slot="label">{facebookAdAccount.name}</Text>
              </AriaMenu.Item>
            ))}
          </AriaMenu.List>
        </AriaMenu>
        <AriaButton
          variant="primary"
          colour="primary"
          loading={isLoading}
          isDisabled={!adAccountUuid}
          className="w-full"
          onPress={() => {
            if (adAccountUuid) {
              if (reportType === 'report') {
                duplicateInsightsReport.mutate({
                  uuid: reportUuid,
                  facebookAdAccountUuid: adAccountUuid,
                });
                void recordEvent({
                  action: 'Duplicated',
                  target: 'Insights Report',
                  metadata: {
                    reportName,
                    uuid: reportUuid,
                    adAccountUuid,
                  },
                });
              } else {
                duplicateInsightsComparisonReport.mutate({
                  uuid: reportUuid,
                  facebookAdAccountUuid: adAccountUuid,
                });
                void recordEvent({
                  action: 'Duplicated',
                  target: 'Insights Comparison Report',
                  metadata: {
                    reportName,
                    uuid: reportUuid,
                    adAccountUuid,
                  },
                });
              }
            }
          }}
        >
          {LL.insights.reports.duplicateModal.submitBtn()}
        </AriaButton>
      </div>
    </SimpleModal>
  );
};

export default DuplicateReportModal;
