import {
  FC,
  lazy,
  PropsWithChildren,
  Suspense,
  useCallback,
  useState,
} from 'react';
import { useMatch, useParams, useSearchParams } from 'react-router-dom';
import PresenceProvider from 'src/contexts/PresenceProvider';
import { useInsightsAdQuery } from '../util/useInsightsQueries';
import { useUserAndOrganisation } from '../../../utils/useUserAndOrganisation';
import { useInsightsSearchParams } from '../util/useInsightsSearchParams';
import {
  InsightsDetailContextParams,
  InsightsDetailContextProvider,
} from './context';

const InsightsDetailModal = lazy(
  () => import('./components/InsightsDetailModal/InsightsDetailModal')
);

type Props = PropsWithChildren;

export const InsightsDetailOutlet: FC<Props> = ({ children }) => {
  const { getParsedValues } = useInsightsSearchParams();
  const { forTimePeriod, attributionWindow } = getParsedValues();
  const { accountUuid: insightsAdAccountFacebookUuid, reportUuid } =
    useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const insightsUuid = searchParams.get('insightsUuid');

  const user = useUserAndOrganisation();

  const [state, setState] = useState<InsightsDetailContextParams | null>(null);

  const isSharedReport = useMatch('/insights/reports/share/:reportUuid');
  const isSharedComparisonReport = useMatch(
    '/insights/comparison-reports/share/:reportUuid'
  );

  const show = useCallback(
    (params: InsightsDetailContextParams) => {
      const queryString = window.location.search;
      const updatedSearchParams = new URLSearchParams(queryString);
      updatedSearchParams.set('insightsUuid', params.uuid);
      setState(params);
      setSearchParams(updatedSearchParams);
    },
    [setSearchParams]
  );

  const close = useCallback(() => {
    if (insightsUuid) {
      // Replace the current history item with just `?insightsUuid=...` so that
      // the users history will direct them to the ad with no other params set.
      const baseParams = new URLSearchParams({ insightsUuid: insightsUuid });
      history.replaceState(
        null,
        '',
        `${window.location.pathname}?${baseParams.toString()}`
      );
    }
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.delete('insightsUuid');
    updatedSearchParams.delete('carousel-asset-uuid');
    // Push the cleared query params to the history stack, important we push and not replace so that back button returns user to ad
    setSearchParams(updatedSearchParams);
    setState(null);
  }, [searchParams, setSearchParams, insightsUuid]);

  const adQuery = useInsightsAdQuery(
    reportUuid
      ? { type: 'report', reportUuid, adUuid: insightsUuid ?? '' }
      : {
          type: 'account',
          adUuid: insightsUuid ?? '',
          forTimePeriod,
          accountUuid: insightsAdAccountFacebookUuid ?? '',
          attributionWindow: attributionWindow,
        }
  );

  const ad =
    adQuery.data ??
    (state?.data?.ad && state.data.ad.uuid === insightsUuid
      ? state.data.ad
      : null);

  const nextAd = state?.data?.pagination?.getAdAtFlatIndex(
    state.data.pagination.flatIndex + 1
  );
  const nextAdUuid = nextAd?.ad?.uuid;

  const prevAd = state?.data?.pagination?.getAdAtFlatIndex(
    state.data.pagination.flatIndex - 1
  );
  const prevAdUuid = prevAd?.ad?.uuid;

  const next = nextAdUuid
    ? () => {
        const queryString = window.location.search;
        const updatedSearchParams = new URLSearchParams(queryString);
        updatedSearchParams.set('insightsUuid', nextAdUuid);
        setSearchParams(updatedSearchParams);
        setState((s) => ({
          uuid: nextAdUuid,
          data: {
            ad: nextAd.ad,
            pagination: s?.data?.pagination
              ? {
                  flatIndex: s?.data?.pagination?.flatIndex + 1,
                  getAdAtFlatIndex: s.data?.pagination?.getAdAtFlatIndex,
                }
              : null,
          },
        }));
      }
    : null;

  const prev = prevAdUuid
    ? () => {
        const queryString = window.location.search;
        const updatedSearchParams = new URLSearchParams(queryString);
        updatedSearchParams.set('insightsUuid', prevAdUuid);
        setSearchParams(updatedSearchParams);
        setState((s) => ({
          uuid: prevAdUuid,
          data: {
            ad: prevAd.ad,
            pagination: s?.data?.pagination
              ? {
                  flatIndex: s?.data?.pagination?.flatIndex - 1,
                  getAdAtFlatIndex: s.data?.pagination?.getAdAtFlatIndex,
                }
              : null,
          },
        }));
      }
    : null;

  return (
    <InsightsDetailContextProvider value={show}>
      {((!!insightsUuid && !!insightsAdAccountFacebookUuid) ||
        isSharedReport ||
        isSharedComparisonReport) &&
        !!ad && (
          <Suspense>
            {/** Note: we use organisation permissions to build presences here
           because we want to allow tagging in Comments prior to saving an ad
           this is a special case */}
            <PresenceProvider
              entityType="Organisation"
              entityUuid={user.data?.organisation.uuid}
            >
              <InsightsDetailModal
                ad={ad}
                insightsAdAccountFacebookUuid={insightsAdAccountFacebookUuid}
                next={next}
                prev={prev}
                afterClose={close}
                customEvents={[]}
                customConversions={[]}
              />
            </PresenceProvider>
          </Suspense>
        )}
      {children}
    </InsightsDetailContextProvider>
  );
};
