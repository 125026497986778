import { useMemo } from 'react';
import {
  INSIGHTS_FACEBOOK_TIME_SERIES_ALL_METRICS,
  InsightsFilter,
  InsightsFacebookTimeSeriesMetricName,
} from '@magicbrief/common';
import {
  Button,
  ComboBox,
  Group,
  Input,
  ListBox,
  ListBoxItem,
} from 'react-aria-components';
import { useFormContext } from 'react-hook-form';
import { Icon } from 'src/components/Icon';
import { Popover } from 'src/components/Popover/AriaPopover';
import { getMetricLabelAsText } from 'src/pages/Insights/util/useParseMetric';
import ChevronSelectorVertical from 'src/assets/svgicons/duocolor/chevron-selector-vertical.svg';
import { cn } from 'src/lib/cn';

type InsightsFormFilterMetricFieldSelectProps = {
  prefix: string;
  onChange: (field: string) => void;
  allowedBuiltInMetrics?: Array<InsightsFacebookTimeSeriesMetricName>;
  customEvents: string[] | null;
  customConversions: Array<{ facebookId: string; name: string }> | null;
  className?: string;
};

export const InsightsFormFilterMetricFieldSelect: React.FC<
  InsightsFormFilterMetricFieldSelectProps
> = ({
  onChange,
  customEvents,
  customConversions,
  allowedBuiltInMetrics = INSIGHTS_FACEBOOK_TIME_SERIES_ALL_METRICS,
  prefix,
  className,
}) => {
  const { watch } = useFormContext();
  const rootValue: InsightsFilter = watch(prefix);

  return (
    <InsightsFilterMetricFieldSelect
      value={rootValue.field}
      allowedBuiltInMetrics={allowedBuiltInMetrics}
      customEvents={customEvents}
      customConversions={customConversions}
      onChange={onChange}
      className={className}
    />
  );
};

type InsightsFilterMetricFieldSelectProps = {
  value?: string;
  onChange: (field: string) => void;
  allowedBuiltInMetrics?:
    | Array<InsightsFilter['field']>
    | Readonly<Array<InsightsFilter['field']>>;
  customEvents: string[] | null;
  customConversions: Array<{ facebookId: string; name: string }> | null;
  className?: string;
};

export const InsightsFilterMetricFieldSelect: React.FC<
  InsightsFilterMetricFieldSelectProps
> = ({
  value,
  onChange,
  customEvents,
  customConversions,
  allowedBuiltInMetrics = INSIGHTS_FACEBOOK_TIME_SERIES_ALL_METRICS,
  className,
}) => {
  const items = useMemo(
    () => [
      ...allowedBuiltInMetrics.map((x) => ({ id: x })),
      ...(customEvents?.flatMap((x) => [
        { id: `action:cost_per:${x}` },
        { id: `action:total:${x}` },
        { id: `action:value:${x}` },
      ]) ?? []),
      ...(customConversions?.flatMap((x) => [
        { id: `action:cost_per:offsite_conversion.custom.${x.facebookId}` },
        { id: `action:total:offsite_conversion.custom.${x.facebookId}` },
        { id: `action:value:offsite_conversion.custom.${x.facebookId}` },
      ]) ?? []),
    ],
    [allowedBuiltInMetrics, customEvents, customConversions]
  );

  return (
    <ComboBox
      defaultItems={items}
      selectedKey={value}
      className={cn('group flex w-full flex-col', className)}
      onSelectionChange={(selection) => onChange(selection as string)}
      aria-label="select metric"
    >
      <Group className="flex h-10 overflow-hidden rounded-md border border-solid border-purple-300 bg-white text-primary shadow-sm placeholder:text-primary/50 focus-within:border-purple-500 focus-within:ring-1 focus-within:ring-purple-500 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm">
        <Input className="form-input min-w-0 flex-auto rounded-sm border-0 text-sm focus:outline-none focus:ring-0 focus-visible:outline-none focus-visible:ring-0" />
        <Button className="flex h-10 w-10 shrink-0 items-center justify-center bg-none">
          <Icon className="h-5 w-5 text-primary">
            <ChevronSelectorVertical />
          </Icon>
        </Button>
      </Group>
      <Popover className="max-h-60 overflow-auto">
        <ListBox items={items} className="overflow-y-auto">
          {(item) => (
            <ListBoxItem
              id={item.id}
              key={item.id}
              className="relative cursor-default select-none gap-3 py-2 pl-9 pr-4 text-sm font-normal text-primary active:bg-purple-100 hover:bg-purple-100 focus-visible:bg-purple-100 selected:bg-purple-100 selected:font-semibold"
            >
              {getMetricLabelAsText(
                item.id,
                customEvents ?? [],
                customConversions ?? []
              ) ?? 'Unknown'}
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </ComboBox>
  );
};
